import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Plan } from '@app/_models/index';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

getPlans() {
  return this.http.get<Plan>(`${environment.apiUrl}/get-all-plan`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getPlanById(id: number) {
  return this.http.get<any>(`${environment.apiUrl}/get-plan-by-plan/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getPlansWithFeatures() {
  return this.http.get<any>(`${environment.apiUrl}/get-all-plan-with-feature`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getFeaturesWithPlanId(id: number) {
  return this.http.get<any>(`${environment.apiUrl}/get-feature-by-plan/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


getterms(){
  return this.http.get<any>(`${environment.apiUrl}/get-informations`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

insertSubs(body:any){
  return this.http.post<any>(`${environment.apiUrl}/user-subscription`, body, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}
}

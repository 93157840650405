<div class="row">
  <div class="col-md-6">
    <div class="card bg-light text-light mb-3 mt-2">
      <div class="card-header mainheadingsFont bg-dark" (click)="togle1()">
        <h5>About {{swinger?.name}} </h5>
      </div>
      <div class="card-body " *ngIf="show1">
        <p class="mainheading"><i class="fas fa-user-tag"></i> Short Tagline</p>
        <h6 class="bg_ddark testsub">{{swinger?.describe_your_self}}</h6>

        <p class="mainheading"><i class="fas fa-eye"></i> Looking For</p>
        <h6 class="bg_ddark testsub">{{swinger?.lookingfor}}</h6>

        <p class="mainheading"><i class="fas fa-eye"></i> Description</p>
        <h6 class="bg_ddark testsub">{{swinger?.describe_your_self}}</h6>

        <p class="mainheading"><i class="fas fa-info-circle"></i> Additional Information</p>
        <h6 class="bg_ddark testsub">{{swinger?.additional_info}}</h6>
      </div>
    </div>


    <div class="card bg-light text-light mb-3 mt-2">
      <div class="card-header bg-dark mainheadingsFont" (click)="togle2()">
        <h5>Preferences of {{swinger?.name}}</h5>
      </div>
      <div class="card-body " *ngIf="show2">
        <h6 style="color: #484848;">Pleasures {{swinger?.name}} is seeking</h6>
        <p class="mainheading">Watch:</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.watch == '0'">No</span>
          <span *ngIf="swinger?.watch == '1'">Definitely</span>
          <span *ngIf="swinger?.watch == '2'">Maybe</span>
          <span *ngIf="swinger?.watch == '3'">A Little</span>
          <span *ngIf="swinger?.watch == '4'">Yes</span>
        </h6>

        <p class="mainheading">Soft:</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.soft == '0'">No</span>
          <span *ngIf="swinger?.soft == '1'">Definitely</span>
          <span *ngIf="swinger?.soft == '2'">Maybe</span>
          <span *ngIf="swinger?.soft == '3'">A Little</span>
          <span *ngIf="swinger?.soft == '4'">Yes</span>
        </h6>

        <p class="mainheading">Full:</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.full == '0'">No</span>
          <span *ngIf="swinger?.full == '1'">Definitely</span>
          <span *ngIf="swinger?.full == '2'">Maybe</span>
          <span *ngIf="swinger?.full == '3'">A Little</span>
          <span *ngIf="swinger?.full == '4'">Yes</span>
        </h6>

        <h5 class="mt-4" style="color: #484848;">Little About {{swinger?.name}}</h5>
        <p class="mainheading" *ngIf="gender === 'f' || gender === 'c'"><i class="fas fa-female"></i> Female Info:</p>
        <h6 class="bg_ddark testsub" *ngIf="gender === 'f' || gender === 'c'">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><span>Age</span> <span>{{swinger?.f_age}} Yrs</span></li>
            <li *ngIf="swinger?.f_height_feet" class="list-group-item"><span>Height</span>
              <span>{{swinger?.f_height_feet}} ft {{swinger?.f_height_in}} in</span></li>
            <li class="list-group-item"><span>Weight</span> <span>{{swinger?.f_weight}}</span></li>
            <li class="list-group-item"><span>Drink</span> <span>{{swinger?.f_drink}}</span></li>
            <li class="list-group-item"><span>Smoke</span> <span>{{swinger?.f_smoke}}</span></li>
            <li class="list-group-item"><span>Orientation</span> <span>{{swinger?.f_orientation}}</span></li>
          </ul>
        </h6>

        <p class="mainheading" *ngIf="gender === 'm' || gender === 'c'"><i class="fas fa-male"></i> Male Info:</p>
        <h6 class="bg_ddark testsub" *ngIf="gender === 'm' || gender === 'c'">
          <ul class="list-group list-group-flush">
            <li *ngIf="swinger?.m_age" class="list-group-item"><span>Age</span> <span>{{swinger?.m_age}} Yrs</span></li>
            <li *ngIf="swinger?.f_height_feet" class="list-group-item"><span>Height</span>
              <span>{{swinger?.m_height_feet}} ft {{swinger?.m_height_in}} in</span></li>
            <li *ngIf="swinger?.m_weight" class="list-group-item"><span>Weight</span> <span>{{swinger?.m_weight}}
                Kg</span></li>
            <li class="list-group-item"><span>Drink</span> <span>{{swinger?.m_drink}}</span></li>
            <li class="list-group-item"><span>Smoke</span> <span>{{swinger?.m_smoke}}</span></li>
            <li class="list-group-item"><span>Orientation</span> <span>{{swinger?.m_orientation}}</span></li>
          </ul>
        </h6>


        <h6 class="mt-4" style="color: #484848;">Looking For</h6>
        <p class="mainheading"><i class="fas fa-people-arrows"></i> interested in meeting Couples:</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.watch == '0'">No</span>
          <span *ngIf="swinger?.watch == '1'">Definitely</span>
          <span *ngIf="swinger?.watch == '2'">Maybe</span>
          <span *ngIf="swinger?.watch == '3'">A Little</span>
          <span *ngIf="swinger?.watch == '4'">Yes</span>
        </h6>

        <p style="color: #484848;" class="mainheading"><i class="fas fa-female"></i> interested in meeting Single
          Ladies:</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.soft == '0'">No</span>
          <span *ngIf="swinger?.soft == '1'">Definitely</span>
          <span *ngIf="swinger?.soft == '2'">Maybe</span>
          <span *ngIf="swinger?.soft == '3'">A Little</span>
          <span *ngIf="swinger?.soft == '4'">Yes</span>
        </h6>

        <p class="mainheading"><i class="fas fa-male"></i> interested in meeting Single Men :</p>
        <h6 class="bg_ddark testsub">
          <span *ngIf="swinger?.full == '0'">No</span>
          <span *ngIf="swinger?.full == '1'">Definitely</span>
          <span *ngIf="swinger?.full == '2'">Maybe</span>
          <span *ngIf="swinger?.full == '3'">A Little</span>
          <span *ngIf="swinger?.full == '4'">Yes</span>
        </h6>

        <h6 class="mt-4 mainheading"> Ages {{swinger?.name}} interested on </h6>
        <p class="mainheading"><i class="fas fa-user-alt"></i> Youngest are Interested In:</p>
        <h6 class="bg_ddark testsub">{{swinger?.youngest}} Yrs</h6>

        <p class="mainheading"><i class="fas fa-user-alt"></i> Oldest are Interested In:</p>
        <h6 class="bg_ddark testsub">{{swinger?.oldest}} Yrs</h6>

      </div>



      <!-- <div >
          <app-swinger-side-bar [swinger]='swinger' *ngIf="!showUserMInfo"></app-swinger-side-bar>
    </div> -->
    </div>

    <div class="card bg-light text-light mb-3 mt-2">
      <div class="card-header bg-dark mainheadingsFont" (click)="togle3()">
        <h5>{{swinger?.name}} lives at this location</h5>
      </div>
      <div class="card-body " *ngIf="show3">
        <p class="mainheading"><i class="fas fa-map-marker-alt"></i> Location</p>
        <h6 class="bg_ddark testsub"><span *ngIf="swinger?.location || swinger?.zipcode">{{swinger?.location}}</span>
        </h6>
      </div>
    </div>


  </div>
  <div class="col-md-6">
    <div class="card bg-light text-light mb-3 mt-2">
      <div class="main-comment-wrapper">
        <div class="comment_wrapper">
          <div class="row">
            <div class="col-md-12">

              <div class="card bg-light text-light mb-2">
                <div class="card-header bg-dark mainheadingsFont">
                  <h5 class="text-light mainheadingsFont">Comments</h5>
                </div>
                <div class="card-body">
                  <form [formGroup]="commentForm" (ngSubmit)="addComment()">
                    <img [src]="userProfileUrl?userProfileUrl:defaultProfile"
                      onerror="this.src='../../../assets/images/avtar-image.jpg';"
                      class="img-fluid rounded-circle comment-user-profile">
                    <h6><a href="javascript:void(0);" style="color: #484848;">{{swinger?.name}}</a></h6>
                    <textarea class="_comment_wrapper form-control comment-textbox" type="text"
                      formControlName="comment_content" placeholder="Write something to {{swinger?.name}}"></textarea>
                    <p class="text-right mt-2">
                      <button class="btn btn-warning btn-sm" [disabled]="!commentForm.valid">
                        <i class="fas fa-paper-plane"></i> Submit
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let comment of totalComments; let l= index">
          <div class="col-sm-12">
            <div class="swinger-comment mart"
              [ngClass]="comment?.comment_parent_id!=0?'child-comment':'parent-comment'">
              <img (click)='reloadWindow(comment?.user_status,comment?.user_id)' src="{{comment?.profile}}"
                onerror="this.src='../../../assets/images/avtar-image.jpg';"
                class="img-fluid rounded-circle comment-user-profile">
              <h6><a href="javascript:void(0);" class="text-dark">{{comment?.user_name}}</a>
                <span style="float: right;" *ngIf="comment?.user_id == userId"><i class="fas fa-pencil-alt" style="color: green;"(click)="recommmentedit(l,i,comment?.id, comment?.comment_content)"></i>&nbsp;
                  <i class="fas fa-trash-alt" style="color: orange;" (click)="deleterepcommentsData(comment?.id)"></i></span>
                </h6>
              <p class="text-secondery comment-text text-dark">{{comment?.comment_content}}</p>
              <div class="d-flex justify-content-around">
                <a href="javascript:void(0);" (click)="commentLike(l,'-1',comment.id)" class="text-dark">Like <span
                    class="badge bg-light text-dark">{{comment?.totalLikes}}</span></a>
                <a href="javascript:void(0);" (click)="showReplyComment(l)" class="text-dark">Reply</a>
              </div>
              <form *ngIf="comment?.replyStatus" [formGroup]="commentFormReply"
                (ngSubmit)="replyComment(l,comment.user_id, comment.id)">
                <textarea class="_comment_wrapper comment-textbox mt-2" type="text" formControlName="comment_content"
                  placeholder="Write a comment..." style="height:50px"></textarea>
                <p class="text-right mt-2">
                  <button class="btn btn-warning btn-sm" [disabled]="!commentFormReply.valid">
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </p>
              </form>
            </div>
          </div>
          <div class="row w-100" *ngFor="let rComment of comment.data; let i= index">
            <div class="col-sm-12">
              <div class="swinger-comment" [ngClass]="rComment?.comment_parent_id!=0?'child-comment':'parent-comment'">
                <img (click)='reloadWindow(rComment?.comment_parent_id)' src="{{rComment.profile}}"
                  onerror="this.src='../../../assets/images/avtar-image.jpg';"
                  class="img-fluid rounded-circle comment-user-profile">
                <h6><a href="javascript:void(0);" class="text-light">{{rComment?.user_name}}</a>
                  <span style="float: right;" *ngIf="rComment?.user_id == userId"><i class="fas fa-pencil-alt" style="color: green;"(click)="recommmentedit(l,i,rComment?.id, rComment?.comment_content)"></i>&nbsp;
                    <i class="fas fa-trash-alt" style="color: orange;" (click)="deleterepcommentsData(rComment?.id)"></i></span>
                  </h6>
                <p class="text-secondery comment-text">{{rComment?.comment_content}}</p>
                <div class="d-flex justify-content-around">
                  <a href="javascript:void(0);" (click)="commentLike(l, i, comment.id);" class="text-light">Like <span
                      class="badge bg-light text-dark">{{rComment?.totalLikes}}</span></a>
                  <a href="javascript:void(0);" (click)="showReplyComment(l,i)" class="text-light">Reply</a>
                </div>
                <form *ngIf="rComment?.replyStatus" [formGroup]="commentFormReply"
                  (ngSubmit)="replyComment(l,i,comment.user_id, comment.id)">
                  <textarea class="_comment_wrapper comment-textbox mt-2" type="text" formControlName="comment_content"
                    placeholder="Write a comment..." style="height:50px"></textarea>
                  <p class="text-right mt-2">
                    <button class="btn btn-warning btn-sm" [disabled]="!commentFormReply.valid">
                      <i class="fas fa-paper-plane"></i> Submit
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




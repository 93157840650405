﻿import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, UserService } from '@app/_services';
import { Router } from '@angular/router'
import * as Bowser from "bowser";

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  public data: any;
  defaultProfile: string = environment.defaultProfile;
  currentUser: any;
  isLogin = false;
  userAgent = Bowser.parse(window.navigator.userAgent);
  browser = Bowser.getParser(window.navigator.userAgent);
  userAgentDetails = JSON.stringify(this.userAgent, null, 4);
  browserDetails = JSON.stringify(this.browser.getBrowser(), null, 4);
  ipAddress:any;
  constructor(
    private UserService: UserService,
    private authenticationService: AuthenticationService,
    public Router: Router,) {
    this.getIP();
    this.authenticationService.currentUser.subscribe(x => {

      this.currentUser = x;

      if (this.currentUser != null) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
    let profile = sessionStorage.getItem('user-profile');
    if (profile) {
      this.defaultProfile=profile;
    }
  }

  ngOnInit() { }

   getIP(){  
     this.UserService.getIPAddress().then((data:any)=>{
       let responce = data;
       let body = {
        browser_name : JSON.parse(this.userAgentDetails).browser.name,
        browser_version : JSON.parse(this.userAgentDetails).browser.version,
        os_name : JSON.parse(this.userAgentDetails).os.name,
        os_version : JSON.parse(this.userAgentDetails).os.versionName,
        device : JSON.parse(this.userAgentDetails).platform.type,
        ip : responce.ip
      }
     }).catch((err=>{
       console.log(err)
     })) 
   }

}  

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MailSubscriptionService } from '@app/_services';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { PlanService } from "../../_services/plan-service.service";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  subForms!:FormGroup;
  constructor(private mailSubscription: MailSubscriptionService ,
    private renderer: Renderer2,
    private PlanService:PlanService,
    private fb:FormBuilder,
    ) { }

  @ViewChild('mailInput', { static: true }) mailInput: ElementRef;
  

  ngOnInit(): void {
    this.subForms = this.fb.group({
      email:['']
    })
  }


  subscribeMail(emailData: string){
    const data = {
      'email': emailData
    };

    this.mailSubscription.mailSubscribe(data).subscribe(res =>{
      console.log(res);
      if(res.data == 1){
        Swal.fire(
          'Success',
          'Thank you for Subscription.','success'
        );
      }else{
        Swal.fire(
          'Info',
          `You have Already Subscribe.`,'info'
        );
      }
    }, (error) => {
      Swal.fire(
        'Error',
        `${error.message[0]}`,'error'
      );
    });
  }

  insertdata() {
    let body = this.subForms.value
    this.PlanService.insertSubs(body).subscribe(res => {
      Swal.fire('Success', res.message, 'success');
      this.subForms.reset();
    })
  }

}

import { Component, OnInit, Input, ElementRef, Renderer2, ViewChild, TemplateRef } from "@angular/core";
import { environment, Popup } from "@environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FriendsService, ProfileViewService, UserMediaService } from "@app/_services";
import { MessagesService } from "../../../_services/messages.service";
import { Friends } from "@app/_models/friends";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
@Component({
  selector: "app-list-friend",
  templateUrl: "./list-friend.component.html",
  styleUrls: ['./list-friend.component.css']
})
export class ListFriendComponent implements OnInit {
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  defaultProfile = environment.defaultProfile;
  blockloading = false;
  favouriteloading = false;
  uiHide = false;
  isFavourite = 0;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  userCurrentrName = JSON.parse(sessionStorage.getItem('currentUser')).name;
  userCurrentrProfile: any;
  constructor(
    private friendService: FriendsService,
    private renderer: Renderer2,
    private elm: ElementRef,
    private viewService: ProfileViewService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private MessagesService: MessagesService,
    public Router: Router,
    private userMedia: UserMediaService,
  ) { }
  firends: Friends;
  @Input() friend: any;
  profilePic: any;

  message: FormGroup;
  loadings = false;
  ngOnInit(): void {
    this.profilePic = this.friend.profile;

    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });

    this.userMedia.getProfile().subscribe(res => {
      this.userCurrentrProfile = (res.data.profile) ? res.data.profile : this.userCurrentrProfile;
    });
  }
  today: number = Date.now();
  toggled: boolean = false;
  messagess: string = '';

  handleSelection(event) {
    this.messagess += event.char;
    // console.log(this.messagess)
  }


  blockFriend(id: any) {
    Swal.fire({
      title: `${Popup.FriendBlocktitle}`,
      text: `${Popup.FriendBlocktext}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Block it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.blockloading = true;
        const data = {
          userId: this.userId,
          friendId: id,
        };
        this.friendService.blockFriend(data).subscribe(
          (res) => {
            this.uiHide = true;
            this.renderer.addClass(
              this.elm.nativeElement.parentElement,
              "d-none"
            );
            this.blockloading = false;
            Swal.fire(
              "Blocked!",
              `${Popup.FriendBlock}`,
              "success"
            );
          },
          (error) => {
            Swal.fire(
              "Server Error",
              `${Popup.error}`,
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", `${Popup.FriendnotBlock}`, "error");
      }
    });
  }
  unfriendloading = false;
  UnFriends(id) {
    Swal.fire({
      title: `Are you sure want to Unfriend this friend?`,
      text: `Unfriend show in to Swinger section.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Unfiernd it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.unfriendloading = true;
        const data = {
          userId: this.userId,
          friendId: id,
        };
        this.friendService.UnFriend(data).subscribe(
          (res) => {
            this.uiHide = true;
            this.renderer.addClass(
              this.elm.nativeElement.parentElement,
              "d-none"
            );
            this.unfriendloading = false;
            Swal.fire(
              "Unfriend!",
              `Your friend successfully Unfriend.`,
              "success"
            );
          },
          (error) => {
            Swal.fire(
              "Server Error",
              `${Popup.error}`,
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", `Your friend not Unfriend. :)`, "error");
      }
    });
  }

  addToFavourite(id: any) {
    this.favouriteloading = true;
    const data = {
      userId: this.userId,
      friendId: id,
    };
    this.friendService.storeFavoriteFriend(data).subscribe((res) => {
      this.favouriteloading = false;
    });
  }

  setProfileViews(id: any) {
    console.log(id);
    const data = {
      swinger_id: id,
    };

    this.viewService.setProfileViews(data).subscribe((res) => {
      console.log(res);
    });
  }

  UseName: any;
  senderIDs: any;
  MessagesRes: any;
  // convenience getter for easy access to form fields
  // get f() { return this.message.controls; }
  // onSubmit() {
  //   if (this.message.invalid) {
  //     return;
  //   }
  //   this.loadings = true;
  //   let body = {
  //     to_user_id: this.senderIDs,
  //     message: this.message.value.messagess,
  //     dateTime:this.today
  //   }
  //   this.loadings = true;
  //   this.MessagesService.sendmessage(body).subscribe(res=>{
  //     if(res != null){
  //       this.loadings = false;
  //       this.MessagesRes = res.message
  //       this.message.reset();
  //       this.closeHotdateModal();
  //       this.sentMessages(this.senderIDs)
  //     }
  //   }, error => {
  //     this.loadings = false;
  // })
  // }

  ReceiverName: any;
  ReciverPrifile: any;
  ReciverGender: any;
  ReciverID: any;
  blockStatus: any;
  onSubmit() {
    const body = {
      name: this.ReceiverName,
      imgurl: this.ReciverPrifile,
      userId: this.userId,
      sender: this.userId,
      gender: this.ReciverGender,
      receiver: this.ReciverID,
      blockStatus: this.blockStatus
    }
    const Data = {
      name: this.userCurrentrName,
      imgurl: this.userCurrentrProfile,
      userId: this.ReciverID,
      sender: this.ReciverID,
      receiver: this.userId,
      gender: this.ReciverGender,
      blockStatus: 0
    }
    this.MessagesService.inboxsender(body).subscribe((res) => {
    })
    this.MessagesService.inboxreceiver(Data).subscribe((res) => {
    })
    this.msgsent()
  }

  msgsent() {
    const body = {
      datTime: this.today,
      message: this.message.value.messagess,
      sender: this.userId,
      receiver: this.ReciverID
    }
    this.MessagesService.sendmessages(body).subscribe((res) => {
      this.message.reset();
      this.closeHotdateModal();
      this.sentMessages(this.senderIDs);
      this.Router.navigate(['/dashboard/message'], { queryParams: { mid: res.receiverIDs, uid: res.receiver } });
    })
  }
  openAddNewHotdateModal(id, name, gender, profile, user_status) {
    this.blockStatus = user_status
    this.ReceiverName = name;
    this.ReciverPrifile = profile;
    this.ReciverGender = gender;
    this.ReciverID = id;
    this.UseName = name;
    this.senderIDs = id
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }

  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }
  sentMessages(id) {
    this.Router.navigate(['/dashboard/message'])
  }
}

import { Component, OnInit, ViewContainerRef  } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { PlanService } from '@app/_services';
@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit {

  constructor(
    private Title:Title,
    private planService:PlanService
  ) {
    this.Title.setTitle('Terms & Conditions | ChatnHookUP')
   }
  heading: string = 'Terms & Conditions';
  backgroundImage: any = '/assets/images/login_background.jpg';

  ngOnInit(): void {
    this.gettermCondi();
  }

  TermsData:any;
  gettermCondi(){
    this.planService.getterms().subscribe(res=>{
      let DataTerms:any = [];
      DataTerms = res.data
      for (let index = 0; index < DataTerms.length; index++) {
        if(DataTerms[index].information_type == 'Terms & Conditions'){
          this.TermsData = DataTerms[index].content;
        }
      }
     
      
    })
  }
}

<app-header></app-header>
<section class="defualt_bg" style="padding-top: 7em;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
            <div class="card bg-dark ml-auto mr-auto" style="max-width:700px">
                <div class="card-body">
                    <!-- <h2 class="text-center text-success">{{mainHeading}}</h2> -->
                    <p class="text-center bt-3">Thanks for verifying your email account. Click here to login now</p>
                    <p class="text-center"><button class="btn btn-danger" routerLink="login" routerLinkActive="">Login Now</button></p>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>
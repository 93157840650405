<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                <li class="nav-item">
                  <a class="btn btn-light " routerLink="/event-dates/member-event-dates"  routerLinkActive="active"><i class="fas fa-calendar-day"></i> Members Events</a>
                </li>
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/event-dates/event-date-list"  routerLinkActive="active"><i class="fas fa-calendar-day"></i> My Events</a>
                  </li>

                </ul>
          </div>
      </div>
  </div>
  </div>
  
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-swinger-comments',
  templateUrl: './swinger-comments.component.html',
  styleUrls: ['./swinger-comments.component.css']
})
export class SwingerCommentsComponent implements OnInit {

  constructor() { }

  @Input('comments') comments: any;

  ngOnInit(): void {
    console.log(this.comments);
  }
 


}

import { Component, OnInit } from '@angular/core';
import { fadeIn } from "./animation";
import { trigger } from '@angular/animations';
@Component({
  selector: 'app-skoltan-loader',
  templateUrl: './skoltan-loader.component.html',
  styleUrls: ['./skoltan-loader.component.css'],
  animations : [
    trigger('fadeIn', fadeIn())
  ],
})
export class SkoltanLoaderComponent implements OnInit {
  dataLoaded = false;
  constructor() { }

  ngOnInit(): void {
  }

}

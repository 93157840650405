import { Component, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from '@app/_models/chat';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  senderIDs = '';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
        this.senderIDs = params['uid'];
    })
   }
  @Input() message: any;
  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Friends } from '@app/_models/friends';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FriendsService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  friendsUrl = 'https://jsonplaceholder.typicode.com/users';
    httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
  };

  getFriends(){
    return this.http.get<Friends>(`${environment.apiUrl}/friends`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }

    getPaginationData(url:string){
      return this.http.get<Friends>(`${url}`, this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }


    getSwingerFriends(id: any){
      return this.http.get<Friends>(`${environment.apiUrl}/swinger/friends/${id}`, this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

  sendFriendRequest(data: any) {
    return this.http.post<Friends>(`${environment.apiUrl}/friend-request`,
     {"from_user_id": data.from_user_id,"to_user_id": data.to_user_id}, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPendingFriends(){
  return this.http.get<Friends>(`${environment.apiUrl}/get-pending-friends`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
  }

  makeFriend(data){
    return this.http.post<Friends>(`${environment.apiUrl}/make-friend`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }


  getSentFriendRequests(){
    return this.http.get<Friends>(`${environment.apiUrl}/sent-friend-requested`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getOnlineFriends() {
    return this.http.get<Friends>(`${environment.apiUrl}/online-swingers`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getOnlineFriendslist():Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/online-user`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getBlockFriends() {
    return this.http.get<Friends>(`${environment.apiUrl}/block-friends`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  removeFromSentRequest(data) {
    return this.http.post<Friends>(`${environment.apiUrl}/delete-sent-request`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  cancelSentRequest(data) {
    return this.http.post<Friends>(`${environment.apiUrl}/cancel-friend-request`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  unblockFriend(data) {
    return this.http.post<Friends>(`${environment.apiUrl}/unblock-friend`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  blockFriend(data) {
    return this.http.post<Friends>(`${environment.apiUrl}/block-friend`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  UnFriend(data) {
    return this.http.post<Friends>(`${environment.apiUrl}/un-friend`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFavoriteFriends(){
    return this.http.get<Friends>(`${environment.apiUrl}/favourite-friends`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  storeFavoriteFriend(data: any){
    return this.http.post<Friends>(`${environment.apiUrl}/make-favourite`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  removeFavoriteFriend(data: any){
    return this.http.post<Friends>(`${environment.apiUrl}/remove-favourite`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



}

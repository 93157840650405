import { Component, OnInit, Input, ElementRef, Renderer2, ViewChild, AfterViewInit, AfterViewChecked, HostListener, TemplateRef} from '@angular/core';
import { UserMediaService } from '@app/_services/user-media.service';
import { MessagesService } from "@app/_services/messages.service";
import { environment } from '@environments/environment';
import { FriendsService, ProfileLikeService, ProfileViewService, CommentService } from '@app/_services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { fadeIn } from "../../Sharing/skoltan-loader/animation";
import { trigger } from '@angular/animations';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-swinger-profile-navigation',
  templateUrl: './swinger-profile-navigation.component.html',
  styleUrls: ['./swinger-profile-navigation.component.css'],
  animations : [
    trigger('fadeIn', fadeIn())
  ],
})
export class SwingerProfileNavigationComponent implements OnInit {
  dataLoaded = false;
  loading = false;
@ViewChild('userNavds') userNavds : ElementRef;
types:any;
@Input() swinger: any;
like = 0;
status = false;
swingerId: any;
profileViews = 0 ;
comments = 0;

@ViewChild('clike') elmRef: ElementRef;
@ViewChild('mobonav') moboNav: ElementRef;

  constructor(
    private CommentService:CommentService,
    private MessagesService: MessagesService,
    private route: Router,
    private ActivatedRoute:ActivatedRoute,
    private friendService: FriendsService,
    private randerer: Renderer2,
    private likeService: ProfileLikeService,
    private profileView: ProfileViewService,
    private renderer: Renderer2,
    private modal: NgbModal,
    ) {
      this.ActivatedRoute.queryParams.subscribe(params=>{
        this.types = params['t']
      }); 
     
    }

  hidenav: boolean;
  id: number;
  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  requestLoding = false;
  moboNavigation = false;
  moboNavBottom = '-20rem';
  moboNavTop = '0rem';





  ngOnInit(): void {
    const currentUrl: string = this.route.url;
    this.swingerId = currentUrl.split('/')[1];
    this.getlikes(this.swingerId);
    this.getViews(this.swingerId);
    this.getswingersProf(this.swingerId)
  }


  sendFriendRequest(firedId: any){
    this.requestLoding = true;
    let data = {
      from_user_id: this.userId,
      to_user_id: firedId
    };
    // console.log(data)
    this.friendService.sendFriendRequest(data).subscribe((res) => {
      this.swinger.friendstatus = 'Requested';
      this.requestLoding = false;
  },err=>{
      this.swinger.friendstatus = 'Not Requested';
      this.requestLoding = false;
  });

  }

  showMoboMenu(){
    this.moboNavigation = !this.moboNavigation;
    if(this.moboNavigation){
      this.randerer.addClass(this.moboNav.nativeElement,'show_navigation');
    }else{
      this.randerer.removeClass(this.moboNav.nativeElement,'show_navigation');
    }
    
  }

  getswingersProf(id:any){
    
    this.CommentService.getUserProfileCommentsBy(id).subscribe(res=>{
      this.comments = res.data.data.length;
    })
  }


  getlikes(id: any) {
    this.likeService.getProfileLikes(id).subscribe( res => {
     this.like = res;
    }, error => {
      console.log(error);
    });
  }

  getViews(id: any) {
    this.profileView.getProfileViews(id).subscribe( res => {
     this.profileViews = res;
    }, error => {
      console.log(error);
    });
  }

  setProfileLike(id: any){
    this.status = !this.status;
    const data = {
      'swinger_id': id
    };

    this.likeService.setProfileLikes(data).subscribe(res => {
      this.like = res;
    });

  }

  // gotomessag(id){
    // this.route.navigate(['/dashboard/message'], {queryParams:{uid:id}})
  // }

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    if(window.pageYOffset > 350){
     this.renderer.addClass(this.userNavds.nativeElement,'fiexd-user-nav');
    }else{
     this.renderer.removeClass(this.userNavds.nativeElement,'fiexd-user-nav');
    }
}

@ViewChild('images', { static: false }) images!: TemplateRef<any>; 
opens(){
  let ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  this.modal.open(this.images, ngbModalOptions);
}

closesignin(){
  this.modal.dismissAll();
}

openMessages(id:number){
 const body = {
  "friendID":id,
  "userID":this.userId
}
this.MessagesService.getFriends(body).subscribe(res=>{
  // console.log(res)
  if(res != null){
    this.route.navigate(['/dashboard/message'], {queryParams:{mid:res.receiverIDs, uid:res.receiver}})
  }else{
    this.route.navigate(['/dashboard/message'])
  }
})
}

}

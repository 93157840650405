import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment, Popup } from '@environments/environment';
import { AuthenticationService, PlanService, UserMediaService } from '@app/_services';
import { User } from '@app/_models';
import { NotificationsServices } from "../../_services/notifications.service";
import { SharedService } from '../../_services/shared.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NotificationsService } from "../../../app/notifications.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MessagesService } from "../../_services/messages.service";
import { io } from 'socket.io-client';
import moment from 'moment';

// const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
// const CHECK_INTERVAL = 15000 // in ms
// const STORE_KEY = 'lastAction';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
  clickEventsubscription: Subscription;
  notificationdata: any = [];
  countnotifinum: any;
  defaultProfile: any;
  siteLogoUrl = environment.siteLogolight;
  currentUser: User;
  username: any;
  isLogin: boolean;
  checkNav = 0;
  checkDiviceWidth = window.innerWidth;
  notifis: boolean = false;
  emailAddress;
  showUnverified = false;
  loading: boolean = true;
  @ViewChild('mobilenavWr') elRef: ElementRef;
  userId: any;
  iconISshow = true;

  // public getLastAction() {
  //   return parseInt(localStorage.getItem(STORE_KEY));
  // }
  // public setLastAction(lastAction: number) {
  //   localStorage.setItem(STORE_KEY, lastAction.toString());
  // }
  socket:any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private NotificationsServices: NotificationsServices,
    private sharedService: SharedService,
    private userMedia: UserMediaService,
    private windownotifiserveice: NotificationsService,
    private MessagesService: MessagesService,
    private planService: PlanService,
  ) {
    
   
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser != null) {
        this.username = this.currentUser.name;
        this.isLogin = true;
        this.openNotifi();
        this.userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
      } else {
        this.isLogin = false;
      }
      if (x.email_status != 1) {
        this.iconISshow = false;
      } else {
        this.iconISshow = true;
      }

     
      if(this.currentUser.remaining_dayes == 0){
        Swal.fire({
          text: `Your ChatnHookUP Current Plan has been expired.`,
          icon: 'warning',
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'Upgrade plan',
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/plan']);
            // this.closesignin();
          }
        });
       }

    });
    this.userMedia.getProfile().subscribe(res => {
      this.defaultProfile = (res.data.profile) ? res.data.profile : this.defaultProfile;
    });

    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.logout();
      Swal.close();
    })

    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('new-message', (data:any) => {
      if (data) {
         setTimeout(() => {
           this.getallmsg(this.userId);
           this.openNotifi();
          }, 1000);
       }
     });
  }


  validUser = false;
  ngOnInit(): void {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email_status;
    if (currentUser == 1) {
      this.validUser = true
    } else {
      this.validUser = false
    }
    this.getallmsg(this.userId)
    this.getPlan(this.currentUser.plan_id);
    this.planId = this.currentUser.plan_id;
  }
  subscribtion:any;
  PlaneName:any;
  planId:any;
  getPlan(id: number){
    this.planService.getPlanById(id).subscribe( res => {
    this.subscribtion = res.data;
    this.PlaneName = this.subscribtion.plan_name;
    if(this.currentUser.plan_status === 0){
      Swal.fire({
        text: `Please complete your payment (${this.PlaneName}) to enjoy ChatnHookUP services.`,
        icon: 'warning',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Pay Now',
      }).then((result) => {
        if (result.value) {
          this.router.navigate([`/check-out`], { queryParams: { id: this.currentUser.plan_id, uid:this.currentUser.id}});
        }
      });
     }
    },(error) => {
      console.log( error );
    });
  }



  getUserProfile() { }
  seemoreshow: boolean = false;
  notifiData: any[] = [];
  notification: any[] = [];
  openNotifi() {
    this.NotificationsServices.getnotification().subscribe((res: any) => {
      let countnotifi = res;
      this.countnotifinum = countnotifi.count;
      this.notificationdata = res.data.data;
      this.notifiData.length = 0;
      for (let index = 0; index < this.notificationdata.length; index++) {
        const subject = JSON.parse(this.notificationdata[index].data);
        const sentby = JSON.parse(this.notificationdata[index].data);
        const FriendID = JSON.parse(this.notificationdata[index].data);
        const FriendImage = JSON.parse(this.notificationdata[index].data);
        const requestType = JSON.parse(this.notificationdata[index].data);
        const SubData = JSON.parse(this.notificationdata[index].data);
        const OrderId = JSON.parse(this.notificationdata[index].data);
        let toDt = moment.utc(this.notificationdata[index].created_at).toDate();
       let updated_at =  moment(toDt).format('MMM DD, YYYY, h:mm A');
        let data = {
          id: this.notificationdata[index].id,
          img: this.notificationdata[index].profilepic,
          read: this.notificationdata[index].read_at,
          create:updated_at,
          subject: subject.data,
          sent_by: sentby.sent_by,
          friend_user_id: FriendID.id,
          requestType: requestType.requestType,
          OrderId: OrderId.other_id,
          status: this.notificationdata[index].status,
          userStatus: this.notificationdata[index].user_status
        }  
        this.seemoreshow = true;
        this.notifiData.push(data);
        if (this.notificationdata[index].read_at == null || this.notificationdata[index].read_at == "") {
          let datas: Array<any> = [];
          datas.push({
            'title': sentby.sent_by,
            'alertContent': subject.data
          });
          this.windownotifiserveice.generateNotification(datas);
        }
      }
    })
  }



  acceptrequest(friend_id, requestType, group_id, id) {
    if (requestType == "Friend Request sent") {
      let body = {
        friend_user_id: friend_id,
      }
      this.NotificationsServices.requestaccept(body).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 1) {
          let body = {
            noti_id: id,
            status: 1
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      });

    } else if (requestType == "Sent Group Invitation") {
      let body = {
        group_id: group_id,
        to_user_id: friend_id,
      }
      this.NotificationsServices.groupaccept(body).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 1) {
          let body = {
            noti_id: id,
            status: 1
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      })
    } else {
      return;
    }

  }

  rejectRequest(friend_id, requestType, group_id, id) {
    if (requestType == "Friend Request sent") {
      const data = {
        "to_user_id": friend_id,
      };
      this.NotificationsServices.requestreject(data).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 2) {
          let body = {
            noti_id: id,
            status: 2
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      });

    } else if (requestType == "Sent Group Invitation") {
      let body = {
        group_id: group_id,
        to_user_id: friend_id,
      }
      this.NotificationsServices.groupreject(body).subscribe(res => {
        let resp: any = [];
        resp = res;
        if (resp.status == 2) {
          let body = {
            noti_id: id,
            status: 2
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      })
    } else {
      return;
    }
  }
  seeAllNotifi() {
    this.router.navigate(['/dashboard/notifications'], { queryParams: { see: "see_more" } })
  }

  gotoNotification(id, requestType, friend_user_id, OrderId) {
    if (requestType == "Comment on profile") {
      this.router.navigate(['/dashboard/profile-comments'])
    } else if (requestType == "Friend Request sent") {
      this.router.navigate([`/${friend_user_id}/member/profile`], { queryParams: { t: "friend" } })

    }
    else if (requestType == "Create a hot date ") {
      this.router.navigate([`/${friend_user_id}/member/hot-dates`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create new Group") {
      this.router.navigate([`/${friend_user_id}/member/groups`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create a new album" || requestType == "insert more album images") {
      this.router.navigate([`/${friend_user_id}/member/albums`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "upload new video ") {
      this.router.navigate([`/${friend_user_id}/member/videos`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "Create new event ") {
      this.router.navigate([`/${friend_user_id}/member/events`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "upload time line photos") {
      this.router.navigate([`/${friend_user_id}/member/timeline`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Accept friend request") {
      this.router.navigate([`/${friend_user_id}/member/profile`], { queryParams: { t: "friend" } })
    }
    else if (requestType == "like album images ") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "like your timeline photo") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "like your Video") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "upload new audios ") {
      this.router.navigate([`/${friend_user_id}/member/audio`], { queryParams: { d: OrderId } })

    }

    else if (requestType == "like your Audio") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })

    }
    this.readnotifi(id);


  }
  readnotifi(id: any) {
    this.NotificationsServices.updatenotificationbyID(id).subscribe((res => {
      this.openNotifi();
    }))
  }

  today: number = Date.now();
  logout() {
    this.authenticationService.logout().subscribe(res => {
      let body = {
        ststusUser:this.today
      }
      this.MessagesService.userStatus(this.userId, body).then((res)=>{
      }).catch((err)=>{
        console.log(err)
      })
      Swal.close();
      this.router.navigate(['/']);
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(function () {
        this.router.onSameUrlNavigation = 'reload';
      }, 50);
    });
  }

  playToy() {
    this.router.navigate(["/toys"]).then(result => { window.location.href = 'https://www.playfulobsessions.com/'; });
  }


  mobileNav(event: any) {
    if (this.checkNav == 0) {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
      this.renderer.addClass(this.elRef.nativeElement, "nav-show");
      this.checkNav = 1;
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
      this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
      this.checkNav = 0;
    }
  }

  seeAllmessage() {
    this.router.navigate(['/dashboard/message'])
  }

  countmessages: any;
  getallmsg(id: any) {
    this.MessagesService.messagecount(this.userId).subscribe(res => {
      this.countmessages = res.unread;
    })
  }

  gotoMessage(user_id) {
    this.router.navigate(['/dashboard/message'], { queryParams: { uid: user_id } })
  }



  // initListener() {
  //   document.body.addEventListener('click', () => this.reset());
  //   document.body.addEventListener('mouseover', () => this.reset());
  //   document.body.addEventListener('mouseout', () => this.reset());
  //   document.body.addEventListener('keydown', () => this.reset());
  //   document.body.addEventListener('keyup', () => this.reset());
  //   document.body.addEventListener('keypress', () => this.reset());
  // }

  // reset() {
  //   this.setLastAction(Date.now());
  // }

  // initInterval() {
  //   setInterval(() => {
  //     this.check();
  //   }, CHECK_INTERVAL);
  // }

  // check() {
  //   const now = Date.now();
  //   const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
  //   const diff = timeleft - now;
  //   const isTimeout = diff < 0;

  //   if (isTimeout) {
  //     Swal.fire("Warning", `${Popup.session}`, "warning").then(ok => {
  //       if (ok) {
  //         this.logout()
  //         sessionStorage.clear();
  //         localStorage.clear();
  //       }
  //     });
  //   }
  // }

  ngOnDestroy(){

  }
}

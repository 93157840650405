
  <div class="table-responsive">
    <table class="table bg-dark table-striped" style="width: 100%;">
      <thead style="background-color: #ffc107;">
        <tr>
          <th class="text-center">Sr.No</th>
          <th>Title</th>
          <th>Tagline</th>
          <th>Details</th>
          <th>Location</th>
          <th class="text-center">From</th>
          <th class="text-center">To</th>
          <th class="text-center">Action</th>
        </tr>
      </thead>
  
      <tbody class="bg-light">
        <tr *ngFor="let eventDetail of eventDateList;let i=index">
          <td class="text-dark text-center">{{i+1}}.</td>
          <td style="max-width:250px;" class="text-dark">
            {{eventDetail?.event_name}}
          </td>
          
          <td class="text-dark text-left">
            <div class="wrapper">
              <p class="demo-1"> {{eventDetail?.sort_description}}</p>
            </div>
            
          </td>
          
          <td class="text-dark text-left">
            <div class="wrapper">
              <p class="demo-1"> {{eventDetail?.long_description}}</p>
            </div>
            
          </td>

          <td style="max-width:300px;" class="text-dark">
            {{eventDetail?.address}}
          </td>

          <td class="text-dark text-center">
            {{eventDetail?.start_date}}
          </td>
          <td class="text-dark text-center">
            {{eventDetail?.end_date}}
          </td>
          <td class="text-dark text-center">
            <i data-toggle="tooltip" data-placement="top" title="Edit" (click)="openUpdateEventDateModal(i, eventDetail?.id)" class="fas fa-edit mr-2" style="color: tomato;"></i>
            <i data-toggle="tooltip" data-placement="top" title="Delete" (click)="deleteEventDate(i,eventDetail?.id)" class="fas fa-trash-alt" style="color: darkgray;"></i>
       
          </td>
        </tr>
      </tbody>
    </table>
  </div>


    <!-- update hotdates -->
    <ng-template #updateHotDate  let-close="close">
      <div class="modal-header bg-dark" >
        <h5 class="modal-title " style="color: white;">Update Event</h5>
        <button type="button" class="close" style="color: white; opacity: 1;" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-light" >
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="addEventDateForm" (ngSubmit)="updateEventDates(eventDateUpdateIndex, eventAddress)">
              <div class="mb-3">
                <input type="text" class="form-control" formControlName="event_name" id="event_name" placeholder="Event Name">
              </div>
              <div *ngIf="addressStatus">
                <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
              </div>
                <div *ngIf="!addressStatus">
                 <label>Current Address : </label><span class="text-success ml-2" style="font-size:20px" (click)="updateAddress();"><i class="fas fa-pen-square"></i></span>
                 <h6 class="text-dark">{{eventAddress}}</h6>
              </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  formControlName="start_date"
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="Start Date"
                  [options]="options"
                />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                  class="form-control"
                  type="text"
                  formControlName="end_date"
                  mwlFlatpickr
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="End Date"
                  [options]="options"
                />
                </div>
              </div>
            </div>

            <div class="mb-3">
              <input type="text" class="form-control" formControlName="sort_description" id="sort_discription" placeholder="Sort Description">
            </div>
              
            <div class="mb-3">
              <textarea type="text" class="form-control" formControlName="long_description" id="log_discription" placeholder="Description"> </textarea>
            </div>
            
          <div class="mb-3">
          <p class="text-right"><button type="submit" class="btn btn-warning" [disabled]="!addEventDateForm.valid">
            <span *ngIf="addEventLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!addEventLoader">Event Update</span>
            <span *ngIf="addEventLoader">Please Wait</span></button></p>
            </div>
          </form>
      </div>
    </div>
      </div>
    </ng-template>
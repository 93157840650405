import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { fadeIn } from "../skoltan-loader/animation";
@Component({
  selector: 'app-audio-skoltan',
  templateUrl: './audio-skoltan.component.html',
  styleUrls: ['./audio-skoltan.component.css'],
  animations : [
    trigger('fadeIn', fadeIn())
  ],
})
export class AudioSkoltanComponent implements OnInit {
  dataLoaded = false;
  constructor() { }

  ngOnInit(): void {
  }

}

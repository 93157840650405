<div class="row">
    <div class="col-md-7">
        <div *ngIf="!freeuser" class="card bg-dark" >
            <div style="padding: 10px;">
                <h4 class="text-light">
                    Event Dates
                    <button class="btn btn-sm btn-warning float-right mb-1" (click)="openAddNewEventDateModal()">
                        + New
                    </button>
                    <div class="clearfix"></div>
                </h4>
    
                <ng-template #eventDetails let-close="close">
                    <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
                        <button type="button" class="close" style="color: white; opacity: 1; outline: none;"
                            (click)="close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="d-flex justify-content-center mb-1 z_2 p-2">
                            <h4 class="text-light"><i>{{actionEventData.event_name}}</i></h4>
                        </div>
                        <div class="d-flex justify-content-between mt-2 z_2 p-2">
                            <span class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light">
                                    {{actionEventData.start_date}}</span></span>
                            <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light">
                                    {{actionEventData.end_date}}</span></span>
                        </div>
    
                    </div>
                    <div class="modal-body text-light bg-dark">
                        <p class=" text-light mb-2"><i>Address:</i> {{actionEventData.address}}</p>
                        <p class=" text-light">{{actionEventData.long_description}}</p>
                    </div>
                </ng-template>
    
                <ng-template #addEventDateModalContent let-close="close">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title" style="color: white;">+Add Event Date</h5>
                        <button type="button" class="close" style="color: white; opacity: 1;" (click)="close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg-light">
                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="addEventdateForm" (ngSubmit)="addNewEventDate()">
    
                                    <div class="mb-3">
                                        <input type="text" class="form-control" formControlName="event_name" id="event_name"
                                            placeholder="Event Name" required>
                                    </div>
    
                                    <app-search-place [mapShow]="mapShow" (mapData)="getlatitudeLongitude($event)">
                                    </app-search-place>
    
    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                       
                                                <input class="form-control" type="text" mwlFlatpickr
                                                    formControlName="start_date" [enableTime]="false" dateFormat="Y-m-d"
                                                    placeholder="Start Date" [options]="options" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <input class="form-control" type="text" formControlName="end_date"
                                                    mwlFlatpickr [enableTime]="false" dateFormat="Y-m-d"
                                                    placeholder="End Date" [options]="options" required/>
                                                
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="mb-3">
                                        <input type="text" class="form-control" formControlName="sort_description"
                                            id="sort_discription" placeholder="Short Description" required>
                                    </div>
    
                                    <div class="mb-3">
                                        <textarea type="text" class="form-control" formControlName="long_description"
                                            id="log_discription" placeholder="Description" required> </textarea>
                                    </div>
    
                                    <div class="mb-3">
                                        <p class="text-right"><button type="submit" class="btn btn-warning"
                                                [disabled]="!addEventdateForm.valid">
                                                <span *ngIf="addEventLoader" class="spinner-grow spinner-grow-sm"
                                                    role="status" aria-hidden="true"></span>
    
                                                <span *ngIf="!addEventLoader">Add Event Date</span>
                                                <span *ngIf="addEventLoader">Please Wait</span>
                                            </button></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-template>
    
                <app-event-list [eventDateList]="eventDateList"></app-event-list>
                <div *ngIf="checkpages" class="row mt-4">
                    <div class="col-md-12 text-center">
                        <nav aria-label="..." class="text-center">
                            <ul class="pagination justify-content-center">
                                <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
                                    [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                                        [innerText]="link.label"></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
    </div>
</div>
<div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
</div>
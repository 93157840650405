import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    BaseUrl: any = "https://stagingapi.swingerflings.com/api"
    constructor(private http: HttpClient) { }

    handleError: any;
    httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    };


    // Search Member By distance
    searchswing(body): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/search-swinger`, body, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
        );

    }
}



import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-swingersdifferent',
  templateUrl: './swingersdifferent.component.html',
  styleUrls: ['./swingersdifferent.component.css']
})
export class SwingersdifferentComponent implements OnInit {

  constructor(
    private Router:Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  gotodiscoverpages(){
    this.authenticationService.currentUser.subscribe(check => {
      console.log(check)
      if(check == null){
        this.Router.navigate(['/login'])
      }else{
        this.Router.navigate(['/dashboard/members'])
      }
    });
    
  }

}

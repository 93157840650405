
<app-dashboard-header></app-dashboard-header>
<app-user-navigation></app-user-navigation>
 
<div *ngIf="emailStatus">
     <div class="defualt_bg">
         <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ngx-loading-bar [includeSpinner]="false"color="#3f75ff" height="5px"></ngx-loading-bar>
                <app-hot-date-navigation></app-hot-date-navigation>  
                 <router-outlet></router-outlet>
                </div>
            </div>
         </div>
     </div>
     
         
    
    
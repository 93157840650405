import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { PlanService } from '@app/_services';
import { DomSanitizer } from '@angular/platform-browser'
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  @ViewChild('someVar') el:ElementRef;
  constructor(
    private Title:Title,
    private planService:PlanService,
    private sanitized: DomSanitizer
  ) {
    this.Title.setTitle('Privacy & Policy | ChatnHookUP')
   }
  heading: string = 'Privacy Policy';
  backgroundImage: any = '/assets/images/login_background.jpg';
  TermsData:any;
  ngOnInit(): void {
    this.gettermCondi();
  }
  ngAfterViewInit() {
    this.planService.getterms().subscribe(res=>{
      let DataTerms:any = [];
      DataTerms = res.data
      for (let index = 0; index < DataTerms.length; index++) {
        if(DataTerms[index].information_type == 'Privacy Policy'){
        this.TermsData = DataTerms[index].content;
        }
      }
    })
  }


  gettermCondi(){
  
  }
}

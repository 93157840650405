<section id="user-banner" class="p-4" style="background-image: url({{swinger?.cover}});">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
      
        <div class="card profile_wrapper text-center mt-1 mb-1 ">
          <div class="dropdown col-md-12">
            <i class="fas fa-ellipsis-v vermenu "></i>
        
            <ul class="dropdown-menu" style="background-color: #e22b59;">
              <li>
                <a class="dropdown-item" *ngIf="swinger?.friendstatus == 'Not Requested'" (click)="sendFriendRequest(swinger?.id)">
                  <div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="visually-hidden"></span>
                  </div> <i class="fas fa-user-plus"></i> Send Friend Request
                </a>
              </li>
              <li>
                <a class="dropdown-item" *ngIf="swinger?.friendstatus != 'Not Requested'">
                  <span  *ngIf="swinger?.friendstatus == 'Friends'"><i class="fas fa-user-friends"></i> Friend</span>
                  <span *ngIf="swinger?.friendstatus == 'Requested'"><i class="fas fa-check"></i> Requested</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/{{swinger?.id}}/member/profile"><i class="fas fa-user-circle"></i> Profile info </a>
              </li>
            </ul>
          </div>
          <div class="user_profile_view mt-2 mb-2">
            <img (click)="opens()" src="{{swinger?.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" class="img-fluid profile-image_view">
            <img src="../../../assets//images/verified.png" *ngIf="swinger?.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
            title="Paid Members">
          </div>
          <div class="user_info_data">
            <h5 class="mb-0 display-5 text-capitalize sm-mr-auto sm-ml-auto" style="color: #484848;">{{swinger?.name}}  </h5>
            <div>
              <span *ngIf="swinger?.gender != null" style="font-size: 12px;text-transform: capitalize;"
              [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
              class="badge badge-pill  mb-2">
              <span *ngIf="swinger?.gender == 'm'">Male</span>
              <span *ngIf="swinger?.gender == 'f'">female</span>
              <span *ngIf="swinger?.gender == 'c'">Couple</span>
            </span>
            </div>
          
            <p style="color: #484848;" class="m_text mb-2 mt-2">
              <span class="mr-2" #clike (click)="setProfileLike(swinger?.id)">
                <i class="fas fa-thumbs-up" style="color:#e22b59;" [ngClass]="status ? 'text-primary' : 'text-like'"></i>
                 {{like}}
                </span>&nbsp;
              <span class="mr-2">
                <i class="fas fa-comment" style="color:#e22b59;" routerLink="/{{swinger?.id}}/member/profile"></i> 
                {{comments}}
              </span> &nbsp;
              <span class="mr-2">
                <i class="fas fa-eye" style="color:#e22b59;" routerLink="/{{swinger?.id}}/member/profile"></i>
                 {{profileViews}}
                </span>
              </p>
            <!-- <p class="m_text mb-2 mt-2" style="margin-bottom: 0px;color: #484848;">{{swinger?.short_tag_line}}</p> -->

          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 thumb_navigation">
        <div class="row">

          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-primary mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/albums">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-picture-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Albums</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-dark timeline-bg mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/timeline">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-stream"></i></h1>
                </div>
                <div class="card-header text-center">Timeline</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card bg-light bg-success mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/videos" class="text-dark">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-video"></i></h1>
                </div>
                <div class="card-header text-center">Videos</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-dark bg-warning mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/friends">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-user-friends"></i></h1>
                </div>
                <div class="card-header text-center">Friends</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-danger mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/hot-dates">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fab fa-hotjar"></i></h1>
                </div>
                <div class="card-header text-center">Hot Dates</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-dark mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/events">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="far fa-calendar-alt"></i></h1>
                </div>
                <div class="card-header text-center">Events</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-success mb-3">
              <a (click)="gotomessag(swinger?.id)">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-comments" aria-hidden="true"></i></h1>
                </div>

                <div class="card-header text-center">Message</div>
              </a>
            </div>
          </div>

        </div>
      </div> -->
    </div>
  </div>
  <div class="swinger moboview-sow mt-4">
    <a (click)="showMoboMenu();"><i *ngIf="!moboNavigation" class="fas fa-chevron-circle-up"></i><i
        *ngIf="moboNavigation" class="fas fa-chevron-circle-down"></i></a>
  </div>
  <div class="swinger-mobile-navigation" #mobonav>
    <div class="row">
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-4">
            <a routerLink="/{{swinger?.id}}/member/profile">
              <div class="card text-white bg-success mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
                <div class="">
                  <h1 class="text-center mtmb"><i class="fa fa-user-circle" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Profile</div>
              </div>
            </a>
          </div>

          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-dark bg-warning mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/friends">
                <div class="">
                  <h1 class="text-center mtmb"><i class="fas fa-user-friends"></i></h1>
                </div>
                <div class="card-header text-center">Friends</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-primary mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/albums">
                <div class="">
                  <h1 class="text-center mtmb"><i class="fa fa-picture-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Albums</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-dark mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/timeline" >
                <div class="">
                  <h1 class="text-center mtmb text-dark"><i class="fas fa-stream"></i></h1>
                </div>
                <div class="card-header text-center text-dark">Timeline</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card bg-light bg-success mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/videos" class="text-dark">
                <div class="">
                  <h1 class="text-center mtmb"><i class="fas fa-video"></i></h1>
                </div>
                <div class="card-header text-center">Videos</div>
              </a>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-danger mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/hot-dates">
                <div class=" bg-danger">
                  <h1 class="text-center mtmb"><i class="fab fa-hotjar"></i></h1>
                </div>
                <div class="card-header text-center">Hot Dates</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-dark mb-3" style=" border-radius: 0px !important;min-height: 0px !important;">
              <a routerLink="/{{swinger?.id}}/member/events">
                <div class="">
                  <h1 class="text-center mtmb"><i class="far fa-calendar-alt"></i></h1>
                </div>
                <div class="card-header text-center">Events</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</section>
<div class="user-navbar" #userNavds>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/friends" routerLinkActive="active">Friends</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/albums" routerLinkActive="active">Photo</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/timeline" routerLinkActive="active">Timeline</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/videos" routerLinkActive="active">Videos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/audio" routerLinkActive="active">Audio</a>
          </li>
 

          <li class="nav-item">
            <a class="nav-link"  routerLink="/{{swinger?.id}}/member/hot-dates" routerLinkActive="active">Hot dates</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/{{swinger?.id}}/member/events" routerLinkActive="active">Events</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="openMessages(swinger?.id)" routerLinkActive="active">Message</a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>



<ng-template #images let-close="close" style="border-radius: 20px;" >
  <div >
    <button type="button" style="outline: none; color: white; position: absolute;
    right: 14px; 
    top: 7px;
    opacity: 1;" class="close" (click)="closesignin()">
      <span aria-hidden="true">&times;</span>
  </button>
    <img src="{{swinger?.profile}}" class="img-fluid" style="width: 100%;height: auto;object-fit: cover;border-radius: 20px;border:solid #ffc107 5px;">
  </div>
</ng-template>
 
  <section style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
      <div class="row " >
          <div class="col-lg-7 col-md-6 col-sm-12">
           </div>
           <div class="col-lg-5 col-md-6 col-sm-12" >
            <div class="text-light" style="background-color: transparent; box-shadow: none;border: none;">
                <div class="">
                  <div class="logo" routerLink="['/']">
                    <img src="../../assets/images/logo.png">
                </div>
                  <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let plan of plans; let i=index">
                     <div [ngClass]="(checkplan==plan?.id)?'selected_plan_bg':'not_selected'">
                      <div class="card text-light bg-light set_outline" [ngClass]="(planId==plan?.id)?'selected_plan_bg':'not_selected'">
                        <div class="ribbon" *ngIf="planId == plan?.id"><span>Active Plan</span></div>
                        <div class="card-body">
                            <div class="top_wrapper_plan">
                          <h4 class="card-title text-center p_he">
                            {{plan?.plan_name}}</h4>
                            <small>{{plan?.reoccurring}}</small>
                            <h3 class="card-subtitle mb-2 text-warning text-center">${{plan.price}}</h3>
                          <!-- <h6><span class="text-uppercase">{{plan.duration}}</span></h6> -->
                        </div>
                        <p><b>{{plan?.subTitle}}</b></p>
                        <p class="card-text" >{{plan?.info}}</p>
                       
                          <div class="list-wrapper">
                            <ul>
                                <li *ngFor="let feature of plan?.features">{{feature?.feature_title}}</li>
                           </ul>
                          </div>
                          <p class="text-center" *ngIf="planId != plan?.id && !userID && checkplan!=plan?.id"> <a href="javascript:void(0);" (click)="askForLogInOrRegister(plan.id);" class="btn cu_btn btn-warning" style="border-radius: 45px;">{{planButton}}</a></p>
  
                          <p class="text-center" *ngIf="planId != plan?.id && userID && checkplan!=plan?.id"> <a (click)="goToCheckout(plan?.id)" class="btn cu_btn btn-warning" style="border-radius: 45px;">{{planButton}}</a></p>
                        </div>
                      </div>
                     </div>
                    </ng-template>
                </owl-carousel-o>  
                </div>
            </div>
             
           </div>
  
      </div>
    
  </div>
  </section>
<!-- show terms popup -->
<ng-template #askForLogin let-close="close">
  <button type="button" class="close" (click)="askForLogInOrRegisterClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body bg-dark modal_body_show ask_for_login" id="_pop_up_login">
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-light text-center mt-3 mb-3">You have an Account?</h5>
        <p class="text-center"><button type="button" class="btn btn-warning btn-sm" (click)="goToLogin()">Login
            Now</button></p>
        <h5 class="text-center text-light ">If you don't have an account?</h5>
        <p class="text-center"><button type="button" class="btn btn-success btn-sm" (click)="goToRegister()">Register
            Now</button></p>
      </div>
    </div>
  </div>
</ng-template>

<div class="right_navigation_panel">
  <div class="card bg-dark text-light mb-3">
    <div class="card-header">
      <h4 class="fw-bold">Online Members</h4>
    </div>
    <div class="card-body bg-light over_flow_wrapper" *ngIf="!freeuser">
      <div class="" >
        <div class="row text-center">
          <div class="col-lg-3 col-md-6 col-sm-6 padd" *ngFor="let friend of friendsData | slice:0:5; let i=index">
            <div class="card  cardss mb-2 bg-light" *ngIf='friend.user_status == null'>
              <div class="col-12 text-cener" style="margin:auto;">
    
                <div class="user_profile_ls active text-cener">
                  <img [src]="friend.profile" routerLink="/{{friend?.id}}/member/profile" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                    alt="user-profile" class="img-fluid">
                    <img src="../../../assets//images/verified.png" *ngIf="friend.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
                title="Verified profile">
                </div>
              </div>
              <div class="col-md-12">
                <div class="text-center mt-4" >
                  <h5 class="text-cener"><a style="color: #484848;" routerLink="/{{friend?.id}}/member/profile">{{friend?.name}} <i class="fas fa-heart" style="color: red;"></i></a></h5>
                  
                  <span
                    [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
                    class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
                </div>
              </div>
    
              <div class="col-md-12">
                <div class=" text-center mb-4">
                  <a  class="btn btn-success" style="margin-top: 15px;" data-toggle="tooltip" data-placement="top" title="Send Message" (click)="openAddNewHotdateModal(friend.id,friend.name, friend?.gender, friend?.profile, friend?.user_status)"><i class="fa fa-comments"></i> Message</a>
                </div>
              </div>
            </div>
          </div>
         <div class="col-md-12" style="text-align: center !important;">
          <p *ngIf="dataNotFound " style="color: #484848;text-align: center !important;">There is no Members/friend online.</p>
         </div>
          <div class="card-text text-center" style="margin: auto;" *ngIf="loading">
            <div class="spinner-border text-dark text-center" role="status">
              <span class="visually-hidden text-center"></span>
            </div>
          </div>
        </div>
      </div>
     
    </div>

    <div class="row mt-12" *ngIf="!freeuser && pagelarg">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="mt-4" *ngIf="freeuser">
      <app-subscribe-plan></app-subscribe-plan>
    </div>
  </div>
</div>


<ng-template #SentMessageModalContent  let-close="close">
  <div class="modal-header bg-light" >
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light" >
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="message">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>

          <div class="form-group">
            <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess" placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
            <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary" (click)="onSubmit()"> 
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
  </div>
</div>
  </div>
</ng-template>
import { Component, Input, OnInit, AfterContentChecked, ChangeDetectorRef  } from '@angular/core';

@Component({
  selector: 'app-swinger-side-bar',
  templateUrl: './swinger-side-bar.component.html',
  styleUrls: ['./swinger-side-bar.component.css']
})
export class SwingerSideBarComponent implements OnInit, AfterContentChecked {

  constructor(private cdref: ChangeDetectorRef) {

   }
  male = false;
  female = false;
  c_t_class: string;

  @Input() swinger: any;

  ngOnInit(): void {
  }

  ngAfterContentChecked(){
    switch(this.swinger?.gender){
      case 'm':
        this.male = true;
        this.female = false;
        break;
      case 'f':
        this.male = false;
        this.female = true;
        break;
      default :
      this.male = true;
      this.female = true;
    }
    if(this.swinger?.m_age != '' && this.swinger?.f_age != ''){
      this.male = true;
      this.female = true;
    }
    this.cdref.detectChanges();
  }
}

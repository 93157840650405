<!-- <app-header></app-header> -->

<section  style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
      <div class="row " >
          <div class="col-lg-6 col-md-6 col-sm-12">
           </div>
           <div class="col-lg-6 col-md-6 col-sm-12" >

            <div class="card text-light" style="background-color: transparent; box-shadow: none;border: none;">
                <div class="">
                    <div class="logo" routerLink="['/']">
                        <img src="../../assets/images/logo.png">
                    </div>
                <div class="con" style="background-color: #e22b59;padding: 20px;">
                        <h1 class="text-center text-light siz">About Us</h1>
                        <div class="col-md-12 para" [innerHTML]="TermsData"></div>
               
                    </div>
                </div>
            </div>

           
              
           </div>
  
      </div>
    
  </div>
  </section>


           <app-swinger-navigation></app-swinger-navigation>
         

           <div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header bg-dark"><h6 class="mb-0">Who Viewed Me</h6></div>
            <app-skoltan-loader *ngIf="loading && !nodata"></app-skoltan-loader>
            <div *ngIf="!freeuser" class="card-body over_flow_wrapper_big">
              <div class="row">
              <div class="mb-2 col-md-4 col-6" *ngFor="let swinger of swingers; let i=index">
                <div class="card text-center" style="text-align: center !important;">
              
                  <div class="col-12">
                    <div class="user_profile_ls">
                      <img routerLink="/{{swinger?.id}}/member/profile" src="{{swinger?.profile}}"
                        onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid">
                        <img src="../../../assets/images/verified.png" *ngIf="swinger.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top" title="Paid Members" >
                      </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="mt-3" style="text-align: center !important;">
                      <h5 class="mb-21"><a style="color: #484848;" routerLink="/{{swinger?.id}}/member/profile">{{swinger.name}} <i class="fas fa-heart"
                            style="color: red;"></i></a></h5>
                      <p><span
                        data-toggle="tooltip" data-placement="top" title="Views" class="badge bg-light text-dark"><i
                          class="fas fa-eye"></i> {{swinger.viewCount}}</span></p>
                      <span *ngIf="swinger?.gender != null"
                        [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
                        class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
              
                    </div>
                  </div>
                </div>
              </div>
              </div>
           
            </div>
            <div *ngIf="nodata" class="text-center">
              <h5 style="color: #484848;">No data found</h5>
            </div>
          </div>
          <!-- <div class="mt-4" *ngIf="freeuser">
            <app-subscribe-plan></app-subscribe-plan>
          </div> -->

import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Output, EventEmitter, Input, ElementRef, Renderer2
} from '@angular/core';
import { FriendsService, ProfileViewService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "../../_services/messages.service";
import { Router } from '@angular/router';
import { environment, Popup } from "@environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
@Component({
  selector: 'app-swinger',
  templateUrl: './swinger.component.html',
  styleUrls: ['./swinger.component.css']
})
export class SwingerComponent implements OnInit {
  userCurrentrName = JSON.parse(sessionStorage.getItem('currentUser')).name;
  userCurrentrProfile = JSON.parse(sessionStorage.getItem('currentUser')).profile;
  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  successMsg = false;
  swingerStatus: string;
  uiHide = false;
  messages:any = "kfjhgfdskjhgd"
  @Input() swinger: any;
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;

  constructor(
    private modal: NgbModal,
    private friendService: FriendsService,
    private viewService: ProfileViewService,
    private formBuilder: FormBuilder,
    private MessagesService: MessagesService,
    private Router: Router,
    private eleRef: ElementRef, private renderer: Renderer2
  ) { }
  swingers: Swinger;
  profilePic: any;
  message: FormGroup;
  loadings = false;
  UseName: any;

  ngOnInit(): void {
    this.profilePic = this.swinger.profile;
    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });
  
  }


  sendFriendRequest(firedId: any) {
    // if (this.planId == 1) {
    //   this.checkUserPlan(firedId);
    // } else {
      this.requestLoding = true;
      this.data = {
        from_user_id: this.userId,
        to_user_id: firedId
      };
      this.friendService.sendFriendRequest(this.data).subscribe((res) => {
        this.requestLoding = false;
        this.successMsg = true;
        this.swingerStatus = res.message;
        this.successMsg = false;
        this.uiHide = true;
        // console.warn(res)
      });
    // }

  }

  setProfileViews(id: any) {
    // if (this.planId == 1) {
    //   this.checkUserPlan(id);
    // } else {
      const data = {
        'swinger_id': id
      };
      this.viewService.setProfileViews(data).subscribe(res => {
        console.log(res);
      });
      this.userValidnav(id)
    // }

  }

  btnloading = false;
  cancelRequest(id,name){
    this.btnloading = true;
    const data = {
      "to_user_id": id
      };
    this.friendService.cancelSentRequest(data).subscribe( res => {
      this.btnloading = false
      this.requestLoding = false;
      this.successMsg = true;
      this.swingerStatus = res.message;
      this.successMsg = false;
      this.uiHide = true;
    });
  }

  today: number = Date.now();
  toggled: boolean = false;
  messagess: string = '';

  handleSelection(event) {
    this.messagess += event.char;
    // console.log(this.messagess)
  }



  // convenience getter for easy access to form fields
  get f() { return this.message.controls; }
  senderIDs: any;
  MessagesRes: any;
  // onSubmit() {
  //   if (this.message.invalid) {
  //     return;
  //   }
  //   this.loadings = true;
  //   let body = {
  //     to_user_id: this.senderIDs,
  //     message: this.message.value.messagess,
  //     dateTime: this.today
  //   }
  //   this.loadings = true;
  //   this.MessagesService.sendmessage(body).subscribe(res => {
  //     if (res != null) {
  //       this.loadings = false;
  //       this.MessagesRes = res.message
  //       this.message.reset();
  //       this.closeHotdateModal();
  //       this.sentMessages(this.senderIDs)
  //     }
  //   }, error => {
  //     this.loadings = false;
  //   })
  // }
  ReceiverName:any;
  ReciverPrifile:any;
  ReciverGender:any;
  ReciverID:any;
  onSubmit() {
    const body = {
      name: this.ReceiverName,
      imgurl: this.ReciverPrifile,
      userId: this.userId,
      sender: this.userId,
      gender: this.ReciverGender,
      receiver: this.ReciverID,
    }
    const Data = {
      name: this.userCurrentrName,
      imgurl: this.userCurrentrProfile,
      userId: this.ReciverID,
      sender: this.ReciverID,
      receiver: this.userId,
      gender: this.ReciverGender,
    }
    this.MessagesService.inboxsender(body).subscribe((res) => {
      console.log(res)
    })
    this.MessagesService.inboxreceiver(Data).subscribe((res) => {
      console.log(res)
    })
    this.msgsent()
  }

  msgsent() {
    const body = {
      datTime: this.today,
      message: this.message.value.messagess,
      sender: this.userId,
      receiver: this.ReciverID
    }
    this.MessagesService.sendmessages(body).subscribe((res) => {
      this.message.reset();
      this.closeHotdateModal();
      this.sentMessages(this.senderIDs);
      this.Router.navigate(['/dashboard/message'], { queryParams: { mid: res.receiverIDs, uid: res.receiver } });
    })
  }

  openAddNewHotdateModal(id, name, gender, profile) {

    // if (this.planId == 1) {
    //   this.checkUserPlan(id);
    // } else {
      this.ReceiverName = name;
      this.ReciverPrifile = profile;
      this.ReciverGender = gender;
      this.ReciverID = id;
      this.UseName = name;
      this.senderIDs = id
      let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      };
      this.modal.open(this.SentMessageModalContent, ngbModalOptions);
    // }

  }

  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }
  sentMessages(id) {
    this.Router.navigate(['/dashboard/message'])
  }

  planId = JSON.parse(sessionStorage.getItem('currentUser')).plan_id;

  userValidnav(id) {
    // if (this.planId == 1) {
    //  this.checkUserPlan(id);
    // } else {
      this.Router.navigate([`/${id}/member/profile`])
    // }
  }

  checkUserPlan(id) {
    if (this.planId == 1) {
      Swal.fire({
        title: `${Popup.checkplanstatusTitle}`,
        text: `${Popup.checkplanstatus}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Upgrade Now!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          this.Router.navigate([`/plan`]);
        } else {
         
        }
      });

    }
  }
}

<!-- <app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark set_outline">
                    <div class="card-body">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class=""
    style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
        <div class="row ">
            <div class="col-lg-7 col-md-6 col-sm-12">
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <section class="">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card text-light"
                                    style="background-color: transparent; box-shadow: none;border: none;">
                                    <div class="">
                                        <div class="logo mb-4">
                                            <img src="../../assets/images/logo.png">
                                        </div>
                                        <form [formGroup]="confirmPassForm" (ngSubmit)="onSubmit()" >
                                            <h5 class="text-left mb-4" >UPDATE PASSWORD</h5>
                                            <div class="form-group">
                                                <input [type]="hide ? 'password' : 'text'" placeholder="Password"  formControlName="pssaword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pssaword.errors }" />
                                                <span class="showhide"> 
                                                    <a (click)="hide = !hide"> <i class="fas fa-eye-slash" *ngIf="hide"></i>
                                                     <i class="fas fa-eye" *ngIf="!hide"></i></a>
                                                 </span>
                                                 <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.pattern">Password Minimum eight characters, at
                                                        least one uppercase letter, one lowercase letter, one number and one
                                                        special character</div>
                
                                                    <div *ngIf="f.password.errors.required">Password is required.</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input placeholder="Confirm Password" [type]="hides ? 'password' : 'text'" formControlName="confirmpassword" autocomplete="false" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" />
                                                <span class="showhide"> 
                                                    <a (click)="hides = !hides"> <i class="fas fa-eye-slash" *ngIf="hides"></i>
                                                     <i class="fas fa-eye" *ngIf="!hides"></i></a>
                                                 </span>
                                                <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                                    <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                                                </div>
                                            </div>
                
                                            <div class="text-left">
                                                <button [disabled]="loading" class="btn btn-success">
                                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                        <div class=" text-center" style="margin-top: 6em;">
                                           <a routerLink="/" style="color: white;text-decoration: underline;"><i class="far fa-hand-point-right"></i> Click here to Sign in / Sign up </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
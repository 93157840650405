import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment, Popup } from "@environments/environment";
import { AuthenticationService,CommonService } from "@app/_services";
import { AudioService } from "../../../_services/audio.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-user-audio',
  templateUrl: './user-audio.component.html',
  styleUrls: ['./user-audio.component.css']
})
export class UserAudioComponent implements OnInit {
  @ViewChild('showAudio', { static: false }) showVideo: TemplateRef<any>;
  AudioStoreMessage: string;
  audiofiles:any[];
  IDs: any;
  freeuser = false;
  currentUser: any;
  progress: number = 0;
  disabed = true;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private Router: Router,
    private modal: NgbModal,
    private titleService: Title,
    private AudioServices: AudioService,
    private CommonService:CommonService
  ) {
    this.titleService.setTitle("Audio | ChatnHookUP");
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      // if (this.currentUser.plan_id == 1) {
      //   this.freeuser = true;
      // }
    });

  }

  submitted = false;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  ifSuccess = false;
  message: string;
  error = "";
  audio: FormGroup;
  openModel = false;
  gallerAudio = [];
  audioFiles = [];

  albumModalHeading: string = "Add Audio";


  ngOnInit(): void {
    this.audio = this.fb.group({
      userID: [this.userId],
      audio: ["", Validators.required],
      privacy: ["", Validators.required],
    });
    this.getaudiofiles(this.userId)
  }

  checkImageSize(file) {
    if (file.size > 50097152) {
      Swal.fire(
        `File size error "${file.name}" file greater then 50 MB`,
        `make sure less then 50 MB then upload file.`,
        "error"
      );
      return false;
    } else {
      return true;
    }
  }

  get f() {
    return this.audio.controls;
  }

  loadaudio(files: File) {
    if (files) {
      for (let file in files) {
        let reader = new FileReader();
        const checkSize = this.checkImageSize(files[file]);
        if (checkSize) {
          this.audioFiles.push(files[file]);
          reader.readAsDataURL(files[file]);
          reader.onload = (_event) => {
            this.gallerAudio.push(files[file].name);
          };
        }
      }
    }
  }


  clearForm() {
    this.audio.get('audio_name').reset();
    this.audio.get('privacy').reset();
  }

  storeAudio() {
    this.loading = true;
    this.submitted = true;
    if (this.audio.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append("audios", this.audioFiles[0]);
    formData.append('privacy', this.audio.value.privacy);
    this.AudioServices.storeAudio(formData).subscribe((event: HttpEvent<any>) => { 
      switch (event.type) {
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          }
          Swal.fire("Success", "Your audio uploaded successfully", "success");
          this.getaudiofiles(this.userId);
          this.closeCreateAlbumModal();
          this.clearForm()
          setTimeout(() => {
            this.progress = 0;
          }, 1500);
          this.loading = false;
          this.message = 'Successfully uploaded.';
          this.audioFiles = [];
       
    }, error => {
      Swal.fire(
        'Server Error',
        `${Popup.error}`,
        'error'
      );
      this.audio.reset();
      this.gallerAudio = [];
      this.loading = false;
      this.submitted = false;
    });

  }
  pagination:any;
	checkpages = false;
  albumStatus = false
  getaudiofiles(id:any){
    this.albumStatus = true
    this.AudioServices.getaudio(id).pipe(map(res=>{
      this.pagination = res.data;
      this.albumStatus = false
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
       return res.data.data;
      })).subscribe(audiores => {
        this.audiofiles = audiores;
        if(this.audiofiles.length == 0){
          this.nodatas = true
        }
      })
  }
  nodatas = false;
  activePage(url:string){
    if(url){
      this.CommonService.getPagination(url).pipe(map(res=>{
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     return res.data.data;
    })).subscribe(audiores => {
      this.audiofiles = audiores;
      if(this.audiofiles.length == 0){
        this.nodatas = true
      }
    })
  }
  }


  openCreateAlbumModal() {
    this.openModel = true;
  }

  closeCreateAlbumModal() {
    this.openModel = false;
  }

  deleteVideo(id: any, index: number){
    Swal.fire({  
      title: `Are you sure want to remove this audio?`,  
      text: `You will not be able to recover this audio!`,  
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Yes, delete it!',  
      cancelButtonText: 'No, keep it'  
    }).then((result) => {  
      if (result.value) {
         this.AudioServices.deleteAudio(id).subscribe(res => {
         Swal.fire(  
          'Deleted!',  
          `Your audio file has been deleted.`,  
          'success'  
        )
        this.getaudiofiles(this.userId);
        },error=>{
          Swal.fire(  
            'Server Error',  
            `${Popup.error}`,  
            'error'  
          )
        });
          
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        Swal.fire(  
          'Cancelled',  
          `Your audio file is safe :)`,  
          'error'
        )
      }
    })
}

  audioLike(id: any, index: number){
    const data = {
     'audio_id':id
    };
    this.AudioServices.likeAudio(data).subscribe(res => {
      let datalike = res
      this.audiofiles[index].totallikes = datalike.totalLikes;
    });
  }

  loadingPrivacyStatus = false;
  currentIndex: number;
  api;
  currentItem;
  privacyMode = true;
  videoStatus = false;
  singleaudio:any;
  privacyEditMode(data: any){
    this.audiofiles[data].privacyMode = !this.audiofiles[data].privacyMode;
  }

  changeAudioPrivacy(id: any, privacy: any, index: number){
         this.audiofiles[index].privacyMode = false;
         this.audiofiles[index].loadingPrivacyStatus = true;
         const data = {
             'id':id,
             'privacy':privacy
           };
      this.AudioServices.changeAudioPrivacy(data).subscribe(res => {
        this.audiofiles[index].privacy = privacy;
        this.audiofiles[index].loadingPrivacyStatus = false;
      });

  }

  private currentPlayedElem: HTMLAudioElement = null;
  onPaly(elm: HTMLAudioElement, id: any, index: number) {
    const data = {
      'audio_id':id
     };
    if (this.currentPlayedElem && this.currentPlayedElem !== elm ) {
      this.currentPlayedElem.pause();
    }
    this.currentPlayedElem = elm;
    this.AudioServices.ViewAudioCount(data).subscribe(res => {
      this.audiofiles[index].totalviews = res.views;
    });
  }

}


import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, FriendsService, UserMediaService } from '@app/_services';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "../../_services/messages.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-user-online',
  templateUrl: './user-online.component.html',
  styleUrls: ['./user-online.component.css']
})
export class UserOnlineComponent implements OnInit {
  message: FormGroup;
  loadings = false;
  UseName: any;
  friendsData: any[] = [];
  pagination: any;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  userCurrentrName = JSON.parse(sessionStorage.getItem('currentUser')).name;
  userCurrentrProfile: any;
  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser: any;
  constructor(
    private friends: FriendsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private MessagesService: MessagesService,
    private userMedia: UserMediaService,
    private Title: Title) {
    this.Title.setTitle('Online Members | ChatnHookUP')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      // if (this.currentUser.plan_id == 1) {
      //   this.freeuser = true;
      // }
    });
  }

  ngOnInit(): void {
    this.getOnlineFriends();
    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });

    this.userMedia.getProfile().subscribe(res => {
      this.userCurrentrProfile = (res.data.profile) ? res.data.profile : this.userCurrentrProfile;
    });

  }
  freeuser = false;
  pagelarg = true;
  dataNotFound = false;
  blockedUser = new Array(this.friendsData.length)
  getOnlineFriends() {
    this.friends.getOnlineFriendslist().subscribe(data => {
      this.loading = false;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.friendsData = data.data.data;

      if (this.pagination.links.length == 3) {
        this.pagelarg = false;
      } else {
        this.pagelarg = true;
      }
      if (this.friendsData.length == 0) {
        this.loading = false;
        this.dataNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }

  today: number = Date.now();
  toggled: boolean = false;
  messagess: string = '';

  handleSelection(event) {
    this.messagess += event.char;
    // console.log(this.messagess)
  }
  // convenience getter for easy access to form fields
  get f() { return this.message.controls; }
  senderIDs: any;
  MessagesRes: any;
  //  onSubmit() {
  //    if (this.message.invalid) {
  //      return;
  //    }
  //    this.loadings = true;
  //    let body = {
  //     to_user_id: this.senderIDs,
  //     message: this.message.value.messagess,
  //     dateTime:this.today
  //   }
  //    this.loadings = true;
  //    this.MessagesService.sendmessage(body).subscribe(res=>{
  //      if(res != null){
  //        this.loadings = false;
  //        this.MessagesRes = res.message
  //        this.message.reset();
  //        this.closeHotdateModal();
  //        this.sentMessages(this.senderIDs)
  //      }
  //    }, error => {
  //      this.loadings = false;
  //  });
  //  }

  ReceiverName: any;
  ReciverPrifile: any;
  ReciverGender: any;
  ReciverID: any;
  onSubmit() {
    const body = {
      name: this.ReceiverName,
      imgurl: this.ReciverPrifile,
      userId: this.userId,
      sender: this.userId,
      gender: this.ReciverGender,
      receiver: this.ReciverID,
      blockStatus: this.blockStatus
    }
    const Data = {
      name: this.userCurrentrName,
      imgurl: this.userCurrentrProfile,
      userId: this.ReciverID,
      sender: this.ReciverID,
      receiver: this.userId,
      gender: this.ReciverGender,
      blockStatus: 0
    }
    this.MessagesService.inboxsender(body).subscribe((res) => {
    })
    this.MessagesService.inboxreceiver(Data).subscribe((res) => {
    })
    this.msgsent()
  }

  msgsent() {
    const body = {
      datTime: this.today,
      message: this.message.value.messagess,
      sender: this.userId,
      receiver: this.ReciverID
    }
    this.MessagesService.sendmessages(body).subscribe((res) => {
      this.message.reset();
      this.closeHotdateModal();
      this.sentMessages(this.senderIDs);
      this.router.navigate(['/dashboard/message'], { queryParams: { mid: res.receiverIDs, uid: res.receiver } });
    })
  }

  blockStatus: any;
  openAddNewHotdateModal(id, name, gender, profile, user_status) {
    this.blockStatus = user_status
    this.ReceiverName = name;
    this.ReciverPrifile = profile;
    this.ReciverGender = gender;
    this.ReciverID = id;
    this.UseName = name;
    this.senderIDs = id
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }



  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }

  sentMessages(id) {
    this.router.navigate(['/dashboard/message'])
  }
}



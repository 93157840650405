
<div class="card bg-light text-light mb-3">
    <div class="card-header bg-dark">
        <h6 class="around_space">Videos <span style="float: right; cursor: pointer;" (click)="openCreateAlbumModal()"> <i class="fas fa-plus" style="color: white;"></i> Add Video</span></h6>
    </div>
    <div class="card-body timeline_wrapper" *ngIf="!freeuser">
        <div class="row" id="lightgallery">
             <div class="col-md-12">
                <div *ngIf="videoLoading" class="text-center">
                    <div class="spinner-border text-dark mt-4" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                  <p *ngIf="nodata" style="color: #484848; text-align: center;">There is no video.</p>
             </div>
            <div class="col-md-4  col-6 mb-3 pl-1 pr-1" *ngFor="let video of videosGallery; let i=index">
                <div class="image-wrapper text-center">
                    <div class="top_wrapper">
                        <div class='video_views_wrapper'>
                            <span (click)="videoLike(video.id,i)"><i class="fas fa-thumbs-up like"></i>
                                {{video.totallikes}}</span>
                            <span><i class="fas fa-eye"></i> {{video.totalviews}}</span>
                        </div>
                        <div [ngSwitch]="video.privacy" class='privacy-status edit_mode_item_r'>
                           
                            <span (click)="deleteVideo(video.id, i);"><i class="fas fa-trash-alt mr-3"></i></span>
                            <i *ngSwitchCase="2" (click)="privacyEditMode(i);" class="fas fa-user-friends mr-3"></i>
                            <i *ngSwitchCase="3" (click)="privacyEditMode(i);" class="fas fa-lock mr-2"></i>
                            <i *ngSwitchCase="1" (click)="privacyEditMode(i);" class="fas fa-globe mr-2"></i>
                        </div>
                        <div *ngIf="video.loadingPrivacyStatus" class="spinner-grow spinner-grow-sm staus_grow"
                            role="status">
                            <span class="visually-hidden"></span>
                        </div>


                        <div *ngIf="video.deleteLoadingStatus" class="spinner-grow spinner-grow-sm staus_grow_delete bg-dark"
                            role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                    <div *ngIf="video.privacyMode" class="privacy_mode_wrapper">
                        <ul>
                            <li (click)="changeVideoPrivacy(video.id,1,i)"><i class="fas fa-globe"></i> Public</li>
                            <li (click)="changeVideoPrivacy(video.id,2,i)"><i class="fas fa-user-friends"></i> Friends
                            </li>
                            <li (click)="changeVideoPrivacy(video.id,3,i)"><i class="fas fa-lock"></i> Only me</li>
                        </ul>
                    </div>
                  
                    <video src="{{video.src}}" class="singleVideo"></video>
                    
                    <div class="play-video-wrapper" (click)="getVideoById(video.id, i)">
                        <img [src]="videoPlayIcon" style="width:50px" class="img-fluid">
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- Modal -->
                <div class="modal animate__animated animate__zoomIn" *ngIf="openModel"
                    [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
                    <div class="modal-dialog ">
                        <div class="modal-content">
                            <div class="modal-header bg-dark">
                                <h5 class="modal-title" id="exampleModalLabel">Upload Videos</h5>
                                <button type="button" class="btn-close" (click)="closeCreateAlbumModal();"
                                    data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times" style="color: white;"></i></button>
                            </div>
                            <div class="modal-body bg-light">
                                <!-- <div class="progress form-group" *ngIf="progress > 0">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
                                    </div>
                                    <div class='count-progressbar bg-dark text-center text-light'>{{progress}} %</div>
                                </div> -->
                                <form [formGroup]="videosList" (ngSubmit)="storeVideos()">
                                    <input type="text" formControlName="userID" class="form-control d-none" readonly />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label style="color: #484848;">Select Privacy</label>
                                            <select class="form-control" formControlName="privacy">
                                                <option disabled value="0" [selected]="0">Select Privacy</option>
                                                <option value="1">Public</option>
                                                <option value="2">Show only Friends</option>
                                                <option value="3">Only Me</option>
                                            </select>
                                            <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                                                <div *ngIf="f.videos.privacy.required" style="color: #484848;">Privacy is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="file" #albumImages (change)="loadVideo(albumImages.files)"
                                            accept="video/mp4,video/x-m4v,video/*" class="form-control d-none"
                                            formControlName="videos" />
                                        <div *ngIf="submitted && f.videos.errors" class="invalid-feedback">
                                            <div *ngIf="f.videos.errors.required" style="color: #484848;">Videos is required</div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="create-album-wrapper text-center mb-3"
                                                (click)="albumImages.click();">
                                               <img src="../../../../assets/images/video.png" class="uploadimg">
                                            </div>
                                            <p style="font-size: 12px;color: #ccc;"><i><span style="color: red;">*</span><span style="color: #484848;"> Maximum size of video's 50 MB only.</span></i></p>
                                        </div>
                                        <div class="col-md-6" *ngFor="let videos of gallaryVideos; let i=index">
                                            <i class="fas fa-trash-alt customes" (click)="deleteone(i)"></i>
                                            <video class="overlay-video" width="100%" height="120"
                                                oncontextmenu="return false;">
                                                <source src="{{videos}}" type="video/*">
                                            </video>
                                        </div>
                                    </div>

                                    <div *ngIf="ifSuccess" class="alert alert-success" role="alert" style="color: #484848;"><i
                                            class="fas fa-check-circle"></i> {{message}}</div>
                                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0" style="color: #484848;">{{error}}</div>
                                    <button  [disabled]="!videosList.valid" class="btn btn-success"><div 
                                        *ngIf="loading" class="spinner-border text-dark spinner-border-sm" role="status">
                                        <span class="visually-hidden"></span>
                                        </div>
                                        <span *ngIf="!loading">Submit</span>
                                        <span *ngIf="loading">Please Wait</span>
                                      </button> 
                                   
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!freeuser" class="row mt-1 ">
            <div class="col-md-12 text-center" *ngIf="checkpages">
                <nav aria-label="..." class="text-center">
                    <ul class="pagination justify-content-center">
                        <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
                            [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                                [innerText]="link.label"></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>




    <ng-template #showVideo let-close="close" >
        <div class="video_modal_wrapper bg-dark">
            <button type="button" style="margin-right: 10px; outline: none; color: white;" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body text-light bg-dark">
            <vg-player  (onPlayerReady)="onPlayerReady($event)">
                        <vg-overlay-play (click)="onClickPlaylistItem(singleVideo.id, singleVideo.index)"></vg-overlay-play>
                        <vg-buffering></vg-buffering>

                        <vg-scrub-bar>
                            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                        </vg-scrub-bar>

                        <vg-controls>
                            <vg-play-pause (click)="onClickPlaylistItem(singleVideo.id, singleVideo.index)"></vg-play-pause>
                            <vg-playback-button></vg-playback-button>
                            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                            <vg-mute></vg-mute>
                            <vg-volume></vg-volume>
                            <vg-fullscreen></vg-fullscreen>
                        </vg-controls>
                        <video #media [vgMedia]="media" src="{{singleVideo.src}}" 
                            type="video/*">
                        </video>
            </vg-player>
        </div>
    </ng-template>

    <div class="mt-4" *ngIf="freeuser">
        <app-subscribe-plan></app-subscribe-plan>
    </div>
</div>
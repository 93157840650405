<div *ngIf="!removeUiBlock" class="card mb-3">
<div class="text-center">
    <div class="col-md-12">
        <div class="user_profile_ls mt-4 mb-4">
          <img routerLink="/{{friend?.id}}/member/profile" [src]="(friend.profile == null)?defaultProfile:profilePic" alt="user-profile" class="img-fluid" onerror="this.src='../../../assets/images/avtar-image.jpg';">
          <img src="../../../assets//images/verified.png"   *ngIf="friend.plan_id != 1"class="verifyImage" data-toggle="tooltip" data-placement="top" title="Verified profile" >
        </div>
    </div>
    <div class="col-md-12">
          <div class="mb-4">
            <h5 class="" style="margin-bottom: 2px;"><a style="color: #484848;" routerLink="/{{friend?.id}}/member/profile">{{friend.name}} <i class="fas fa-heart" style="color: red;"></i></a></h5>
            <span *ngIf="friend?.gender != null" [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="btn-group mb-4 text-center">
            <button type="button" class="btn btn-danger" (click)="removeFriend(friend.id)"> <div 
            *ngIf="btnloading" class="spinner-border text-light spinner-border-sm" role="status">
             <span class="visually-hidden"></span>
           </div> Cancel</button>
           </div>
     
    </div>

 
  </div>
</div>
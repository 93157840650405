import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { UserMedia } from '@app/_models/user-media';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserMediaService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
  };



  changeProfileCover(data){
    return this.http.post<any>(`${environment.apiUrl}/user-cover`, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }

    getUserInvoices(){
      return this.http.get<any>(`${environment.apiUrl}/user-invoice`).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      getUserInvoicebyId(id:any){
        return this.http.get<any>(`${environment.apiUrl}/active-invoice/${id}`).pipe(
          retry(1),
          catchError(this.handleError)
        );
        }

    changeGroupCover(data){
      return this.http.post<any>(`${environment.apiUrl}/add-group-cover-page`, data).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

    getProfileCover(){
      return this.http.get<any>(`${environment.apiUrl}/get-user-cover`, this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      getSwingerCover(id: any){
        return this.http.get<any>(`${environment.apiUrl}/get-swinger-cover/${id}`, this.httpOptions).pipe(
          retry(1),
          catchError(this.handleError)
        );
        }

        getSwingerProfile(id: any){
          return this.http.get<any>(`${environment.apiUrl}/get-swinger-profile/${id}`, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
          }



    changeProfile(data){
      return this.http.post<UserMedia>(`${environment.apiUrl}/user-profile`, data).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      changeGroupProfile(data){
        return this.http.post<any>(`${environment.apiUrl}/update-profile-image`, data).pipe(
          retry(1),
          catchError(this.handleError)
        );
        }

       getProfile(){
        return this.http.get<any>(`${environment.apiUrl}/get-user-profile`, this.httpOptions).pipe(
          retry(1),
          catchError(this.handleError)
        );
        }

  

    changeUserPic(data){
      return this.http.post<UserMedia>(`${environment.apiUrl}/user-pic`, data).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

  changeUserVideo(data) {
    return this.http.post<UserMedia>(`${environment.apiUrl}/user-video`, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSwingerDetails(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/user/details/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  cancelSubpaln(id):Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cancel-subscription/${id}`, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



  linksend():Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/resend-emailveryfylink`, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



}

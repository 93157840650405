<div class="card bg-dark text-light mb-3 mt-2">
  <div class="card-header">
    <h6>Notification</h6>
  </div>
  <div class="card-body bg-light over_flow_wrapper_big overflow">
    <div class="card mb-2" *ngIf="seemoredata">
      <div *ngFor="let notifi of AllNotifiiData; let i = index">
        <div class="bgcolors " *ngIf="notifi.userStatus == null"
          [ngClass]="{ backgcolor: notifi.read === null }">
          <div class="row g-0">
            <div class="col-3 col-md-2 pr-1">
              <div class="user_profile_ls">
                <img [src]="notifi.img" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                  class="img-fluid" />
              </div>
            </div>

            <div class="col-9 col-md-10 pl-1" (click)="
                opensinglenoti(
                  notifi.id,
                  notifi.requestType,
                  notifi.friend_user_id,
                  notifi.OrderId
                )
              ">
              <div class="row">
                <div class="col-md-7 col-12">
                  <div class="card-body userdet">
                    <h5 class="card-title" [ngClass]="{ nonread: notifi.read === null }">
                      <a>{{ notifi?.sent_by }}</a>
                    </h5>
                    <p class="card-text" [ngClass]="{ nonread: notifi.read === null }" [innerHtml]="notifi?.subject" style="margin-bottom: 0px;"></p>
                    <p style="font-size: 10px; margin-bottom: 5px;">{{notifi?.create}}</p>
                    <div *ngIf="notifi?.requestType == 'Friend Request sent'">
                      <p *ngIf="notifi.status == 1" class="frindNotFriend" >
                        Friend
                      </p>
                      <p *ngIf="notifi.status == 2" class="frindNotFriend">
                        Not intrested
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12" *ngIf="notifi?.requestType == 'Friend Request sent'">
                  <div class="btn-group mt-4" *ngIf="notifi?.status == 0">
                    <button type="button" class="btn btn-primary" (click)="
                        acceptrequest(
                          notifi.friend_user_id,
                          notifi.requestType,
                          notifi.OrderId,
                          notifi.id
                        )
                      ">
                      Accept
                    </button>
                    <button type="button" class="btn btn-warning" (click)="
                        rejectRequest(
                          notifi.friend_user_id,
                          notifi.requestType,
                          notifi.OrderId,
                          notifi.id
                        )
                      ">
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="paginanat">
        <div class="col-md-12 text-center">
          <nav aria-label="..." class="text-center">
            <ul class="pagination justify-content-center">
              <li *ngFor="let link of pagination?.links; let i = index" class="page-item"
                [ngClass]="link.active ? 'active' : ''">
                <a class="page-link" (click)="getPageData(link.url)" [innerText]="link.label"></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div *ngIf="!seemoredata">
      <div *ngIf="timelineviews">
        <div class="col-md-3 mb-2 pl-1 pr-1" *ngFor="let photos of newDataNotifi; let i = index">
          <p>Timeline</p>
          <div class="top_wrapper">
            <div class="video_views_wrapper">
              <span><i class="fas fa-thumbs-up like"></i> {{ photos.Likes }}</span>
              <span><i class="fas fa-eye"></i> {{ photos.views }}</span>
            </div>
          </div>
          <div class="image-wrapper text-center">
            <img src="{{ photos?.s3Image }}" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt=""
              class="timeline-item" />
          </div>
        </div>
      </div>

      <div *ngIf="albumlikesviews">
        <div class="col-md-3 p-1" *ngFor="let image of albumImages; let i = index">
          <p><span>Album | </span>{{ image?.albumname }}</p>
          <div class="top_wrapper">
            <div class="video_views_wrapper">
              <span class="like"><i class="fas fa-thumbs-up"></i> {{ image.likes }}</span>
              <span class="like"><i class="fas fa-eye"></i> {{ image.views }}</span>
            </div>
          </div>
          <div class="image-wrapper text-center">
            <img src="{{ image.images }}" class="img-fluid" />
          </div>
        </div>
      </div>

      <div *ngIf="likeaudiosviews">
        <div class="col-md-6 p-1 card bg-dark" *ngFor="let audio of audiofiles; let i = index">
          <p><span>Audio | </span></p>
          <div class="lokecon">
            <ul>
              <li>
                <span (click)="audioLike(audio.id, i)" class="like"><i class="fas fa-thumbs-up"></i> {{ audio.totallikes
                  }}</span>
              </li>
              <li>
                <span class="like"><i class="fas fa-eye"></i> {{ audio.totalviews }}</span>
              </li>
            </ul>
          </div>
          <audio #audio (play)="onPaly(audio, audio.id, i)" style="outline: none; width: 100%" controls
            controlsList="nodownload">
            <source [src]="audio?.src" type="audio/mp3" />
          </audio>
        </div>
      </div>

      <div *ngIf="likevideosviews">
        <div class="col-md-4 mb-2 pl-1 pr-1" *ngFor="let video of videosGallery; let i = index">
          <p>Video</p>
          <div class="image-wrapper text-center">
            <div class="top_wrapper">
              <div class="video_views_wrapper">
                <span><i class="fas fa-thumbs-up like"></i>
                  {{ video.totallikes }}</span>
                <span><i class="fas fa-eye"></i> {{ video.totalviews }}</span>
              </div>
            </div>

            <vg-player (onPlayerReady)="onPlayerReady($event)">
              <vg-overlay-play (click)="onClickPlaylistItem(video.id, i)"></vg-overlay-play>
              <vg-buffering></vg-buffering>

              <vg-scrub-bar>
                <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>

              <vg-controls>
                <vg-play-pause (click)="onClickPlaylistItem(video.id, i)"></vg-play-pause>
                <vg-playback-button></vg-playback-button>
                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                <vg-mute></vg-mute>
                <vg-volume></vg-volume>
                <vg-fullscreen></vg-fullscreen>
              </vg-controls>
              <video #media [vgMedia]="media" src="{{ video?.src }}" class="singleVideo" type="video/*"></video>
            </vg-player>
          </div>
        </div>
      </div>
    </div>

    <div class="card-text text-center" *ngIf="loading">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
</div>
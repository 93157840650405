<!-- nav -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark _nav_fixed" style="padding: 0 1rem !important;">
  <div class="container">
    
      <div class="imgsLogo">
        <img src="{{siteLogoUrl}}"  routerLink="/dashboard/members">
      </div>

    <span class="example-spacer"></span>
    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin " style="padding: 0px 0px;border-bottom: none;
    ">
      <a class="nav-link" (click)="seeAllmessage()" data-toggle="dropdown"><i class="fas fa-envelope"></i>
        <span class="badge bg-success" style="border: none;">{{countmessages}}</span></a>
    </div>
    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin " style="padding: 0px 0px;border-bottom: none;">
      <a class="nav-link"  (click)="seeAllNotifi();" data-toggle="dropdown"><i class="fas fa-bell"></i><span
          class="badge bg-warning" style="border: none;">{{countnotifinum}}</span></a>
    </div>

    <button class="navbar-toggler moble-nav" style="padding: 12px;" type="button" (click)='mobileNav($event);' id="mobile-navigation">
      <span class="nav-bar-1"></span>
      <span class="nav-bar-2"></span>
      <span class="nav-bar-3"></span>
    </button>
    <div class="collapse navbar-collapse justify-end">
      <ul class="navbar-nav ms-auto me-0 ">
      </ul>
    </div>

    <div class="collapse navbar-collapse justify-end" id="right_navbar">
      <ul class="navbar-nav ms-auto me-0 ">
       <!-- message -->
        <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
          <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true" (click)="seeAllmessage()"><i class="fas fa-envelope"></i><span class="badge bg-success" style="border: none;">{{countmessages}}</span></a>
         
        </li>

        <!-- notefication -->
        <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
          <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true"><i class="fas fa-bell"></i><span class="badge bg-warning" style="border: none;">{{countnotifinum}}</span></a>
          <div class="dropdown-menu dropdown-menu-lg-right dropdown-secondary"
            aria-labelledby="navbarDropdownMenuLink-5">
            <span *ngFor="let notifi of notifiData | slice:0:4; let i=index">
              <a class="dropdown-item waves-effect waves-light dispblock "
              
              [ngClass]="{'backgcolor' : notifi.read === null}" *ngIf="notifi.userStatus == null">
               <span >
                <div class="inl">
                  <div class="notimg">
                    <img [src]="notifi.img" onerror="this.src='../../../assets/images/avtar-image.jpg';" class="imgnotif">
                  </div>
                </div>
                <div class="inl">
                  <div class="testdata"
                    (click)="gotoNotification(notifi.id, notifi.requestType, notifi.friend_user_id, notifi.OrderId)">
                    <h3 [ngClass]="{'nonread' : notifi.read === null}" class="username mb-0" >{{notifi?.sent_by}}</h3>
                    <p [ngClass]="{'nonread' : notifi.read === null}"
                      class="notifisub" [innerHtml]="notifi?.subject"></p>
                      <p style="font-size: 10px; margin-bottom: 5px;">{{notifi?.create}}</p>
                      <div *ngIf="notifi?.requestType == 'Friend Request sent'">
                        <p *ngIf="notifi.status == 1" class="frindNotFriend">friend</p>
                        <p *ngIf="notifi.status == 2" class="frindNotFriend"> not intrested</p>
                      </div>
                  </div>
                  <div class="testdata" *ngIf="notifi?.requestType == 'Friend Request sent'">
                    <div *ngIf="notifi?.status == 0">
                      <button type="button" class="btn btn-primary buttons"
                        (click)="acceptrequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                        Accept
                      </button>
                      <button type="button" type="button" class="btn btn-light buttons"
                        (click)="rejectRequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                        Decline
                      </button>
                    </div>
  
                  </div>
                 
                </div>
               </span>
            </a>
            </span>
            
            <div *ngIf="seemoreshow" class="see_more_wrapper " (click)="seeAllNotifi()">
              <a class="pr-3" style="color: #484848;">see all <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
        </li>


        <li class="nav-item dropdown" *ngIf="isLogin">
          <a class="nav-link dropdown-toggle profile_image" href="javascript:void(0);" id="user_profile" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="{{defaultProfile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" #profileImg
              class="img-fluid logoimag">
          </a>
          <ul class="dropdown-menu" aria-labelledby="user_profile" style="background-color: #e22b59;">
            <li><a class="dropdown-item" routerLink="/dashboard/members">Dashboard </a></li>
            <li><a class="dropdown-item" routerLink="/dashboard/edit-profile">My Profile</a></li>
            <li><a class="dropdown-item"  routerLink="/dashboard/profile-comments">Profile Comment</a></li>
            <li><a class="dropdown-item" routerLink="/dashboard/plan-invoce">Plan History</a></li>
            <li><a class="dropdown-item" routerLink="/dashboard/change-password">Change Password</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>


<!-- start mobile navigation -->

<div class="justify-end navbarSmallDiveces nav-hide" #mobilenavWr id="sidebar-wrapper">
  <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
    <i class="far fa-times-circle text-light" style="font-size:28px"></i>
  </button>
  <ul class="navbar-nav ms-auto me-0 mt-2 ">
    <li class="nav-item dropdown" *ngIf="isLogin">
      <a class="nav-link profile_image" href="javascript:void(0);" id="user_profile">
        <img src="{{defaultProfile}}" class="img-fluid">
      </a>
      <span class="active-user-name text-light">{{username}}</span><br />
      <span><a class="badge bg-warning text-dark custom_badge">{{PlaneName}}</a></span>
      <span *ngIf="planId == '1'"><a routerLink="/plan" class="badge bg-primary text-dark custom_badge">Upgrade Plan</a></span>
    </li>

    <li class="nav-item " *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/members" routerLinkActive="active"
        (click)='mobileNav($event);'>Dashboard</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/user-online" routerLinkActive="active"
        (click)='mobileNav($event);'>Who's Online <i class="fas fa-circle cutom"></i></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/message" routerLinkActive="active"
        (click)='mobileNav($event);'>Message</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/edit-profile" routerLinkActive="active" (click)='mobileNav($event);'>My
        Profile</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/profile-comments" routerLinkActive="active" (click)='mobileNav($event);'>Profile Comment</a>
    </li>

  
  </ul>

  <ul class="navbar-nav ms-auto me-0 ">

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/members" routerLinkActive="active"
        (click)='mobileNav($event);'>Members</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/friends" routerLinkActive="active"
        (click)='mobileNav($event);'>Friends</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/create-album" routerLinkActive="active"
        (click)='mobileNav($event);'>Photo</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/upload-videos" routerLinkActive="active"
        (click)='mobileNav($event);'>Video</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/upload-audio" routerLinkActive="active"
        (click)='mobileNav($event);'>Audio</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/hot-dates/hot-date-list" routerLinkActive="active"
        (click)='mobileNav($event);'>Hot dates</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/event-dates/event-date-list" routerLinkActive="active"
        (click)='mobileNav($event);'>Events</a>
    </li>



    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/plan-invoce" routerLinkActive="active"
        (click)='mobileNav($event);'>Plan History </a>
    </li>
    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/change-password" routerLinkActive="active"
        (click)='mobileNav($event);'>Change Password </a>
    </li>


    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" href="javascript:void(0);" (click)="logout()" (click)='mobileNav($event);'>Logout</a>
    </li>

  </ul>
</div>
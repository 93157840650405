import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { catchError, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    authUrl = 'https://stagingapi.swingerflings.com/oauth/token';
    options: any;
    handleError: any;
    httpOptions = {
        headers: new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json'
        })
    };


    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }



    login(name: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`,{"email":name,"password":password
        }, this.httpOptions).pipe(map(user => {
                sessionStorage.setItem('currentUser', JSON.stringify(user));
                sessionStorage.setItem('user-profile',user.profile);
                this.currentUserSubject.next(user);
                return user;
            }));
    }




    register(data: any){
        return this.http.post<any>(`${environment.apiUrl}/register`, data, this.httpOptions).pipe(map(user => {
            sessionStorage.removeItem("planId");
           if(data.plan_id != 1){
            sessionStorage.setItem('planId', data.plan_id);
           }
          return user;
        }));
    }


    logout() {
        return this.http.post<any>(`${environment.apiUrl}/logout`,{ }, this.httpOptions).pipe(map(user => {
            sessionStorage.removeItem('currentUser');
            sessionStorage.removeItem('user-profile');
            this.currentUserSubject.next(null);
        }));
    }


    forgotpassword(body):Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}/send-restpasswordlink`, body, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    resetpassword(body):Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}/reset-password`, body, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    updateOlduserpassword(body):Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}/update-olduserpassword`, body, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    Olduseremail(body):Observable<any>{
        return this.http.get<any>(`${environment.apiUrl}/user-get/${body}`, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

}

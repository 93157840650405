import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PlanService } from '@app/_services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Popup } from "@environments/environment";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    rewind: false,
    autoplay: false,
    autoplayHoverPause: true,

    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  };

  constructor(private planService: PlanService, private modal: NgbModal,  private router: Router,
    private Title:Title) {
    this.Title.setTitle('Pricing & Plans | ChatnHookUP')
   }
  @ViewChild('askForLogin', { static: true }) askForLogin: TemplateRef<any>;
 userID = JSON.parse(sessionStorage.getItem('currentUser'))?.id;
 planId = JSON.parse(sessionStorage.getItem('currentUser'))?.plan_id;
 checkplan:any;
 plans: any;
 selectedPlanId;
 planButton = 'Choose Plan';
 packageLoading = false;

  ngOnInit(): void {
    this.getPlansWithFeatures();
    this.packageLoading = true;
    if(this.userID){
      this.planButton = 'Upgrade Plan';
    }
    if(this.planId != 1){
     this.checkplan = 1;
    }else{
      this.checkplan == 0
    }
  }

  getPlansWithFeatures() {
    this.planService.getPlansWithFeatures().subscribe(res => {
      this.packageLoading = false;
      res.data[0].subTitle = `${Popup.freePlanTitle}`;
      res.data[0].info = `${Popup.freePlanInFO}`;
    
      this.plans = res.data;
    }, (error) => {
      this.packageLoading = false;
    });
  }

  askForLogInOrRegister(planId : number) {
    this.selectedPlanId = planId;
    this.modal.open(this.askForLogin,{ size: 'xl', windowClass: 'dark-modal' });
  }

  askForLogInOrRegisterClose() {
    this.modal.dismissAll(this.askForLogin);
  }

  goToRegister(){
    this.router.navigate([`/`],{ queryParams: { id: this.selectedPlanId}});
    this.askForLogInOrRegisterClose();
  }

  goToCheckout(id: number){
    this.selectedPlanId = id;
    this.router.navigate([`/check-out`],{ queryParams: { id: this.selectedPlanId, uid:this.userID}});
  }

  goToLogin(){
    this.router.navigate(['/']);
    this.askForLogInOrRegisterClose();
  }


}

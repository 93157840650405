import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  constructor(private http: HttpClient) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
  };

  storeAudio(data: any):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/store-audio`, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getaudio(id):Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/get-audio/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSingelaudio(id):Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/get-single-audio/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  likeAudio(body):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/audio-likes`,body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteAudio(id):Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/delete-audio/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  changeAudioPrivacy(body):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/audio-privacy`,body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  ViewAudioCount(body):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/audio-view`,body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}

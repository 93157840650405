<div class="form-group">
    <label *ngIf="mapShow">Enter address</label>
    <input type="text" [value]="customAddress" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Location (City/ZipCode)" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
  </div>
  
  <agm-map *ngIf="mapShow" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="height: 250px; width:100%">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
    ></agm-marker>
  </agm-map>

  <h5 *ngIf="mapShow" class="mt-2">Address</h5>
  <p *ngIf="mapShow" class="h5">{{address}}</p>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, SwingersService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-who-viewed',
  templateUrl: './who-viewed.component.html',
  styleUrls: ['./who-viewed.component.css']
})


export class WhoViewedComponent implements OnInit, OnDestroy {
  animation = 'pulse';
  contentLoaded = false;
  count = 10;
  widthHeightSizeInPixels = 50;
  intervalId: number | null = null;
  styles = {
    'border-radius': '5px',
    height: '180px',
    width: '170px',
    margin:'5px',
    'background-color': '#e22b59',
    border: '1px solid #323232',
    'animation-duration': '2s'
  }
  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser:any;
  freeuser = false;
  
  constructor(private swingersService: SwingersService,
    private authenticationService: AuthenticationService,
    private titleService:Title 
    ){
      this.titleService.setTitle('Who Viewed Me | ChatnHookUP')
    this.authenticationService.currentUser.subscribe(x => {
      // console.log(x)
      this.currentUser = x;
      // if(this.currentUser.plan_id == 1){
      //   this.freeuser = true;
      // }
  });

   }
  swingers: any[] = [];
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;

  ngOnInit(): void {
    this.getAllViewsSwinger();

    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);

    this.intervalId = window.setInterval(() => {
      this.animation = this.animation === 'pulse' ? 'progress-dark' : 'pulse';
      this.count = this.count === 2 ? 5 : 2;
      this.widthHeightSizeInPixels =
        this.widthHeightSizeInPixels === 50 ? 100 : 50;
    }, 5000);
  }
  nodata = false;
  getAllViewsSwinger(){
    this.loading = true;
    this.swingersService.getWhoViews().subscribe(data => {
      if(data.length == 0){
        this.nodata = true;
      }else{
        this.loading = false;
        this.swingers = data;
        for (let index = 0; index < this.swingers.length; index++) {
          if(this.swingers[index].user_status == 0){
           this.swingers.splice(index,1)
          }
        }
        for(let swinger in data){
          data[swinger].profile = (data[swinger].profile) ? data[swinger].profile: this.defaultProfile;
        }
      }
    
     
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}

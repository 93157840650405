import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EventService } from '@app/_services';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { Popup } from "@environments/environment";
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  eventData: any;
  @Input() eventDateList: any;
  @ViewChild('updateHotDate', { static: false }) updateHotDate: TemplateRef<any>;
  eventDateUpdateIndex: any;
  eventAddress:string;
  constructor(
    private eventDateService: EventService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private Title:Title
  ) {
    this.Title.setTitle('Events | ChatnHookUP')
  }

  options: FlatpickrDefaultsInterface;


  addEventDateForm: FormGroup;
  addressStatus = false;

  ngOnInit(): void {
    this.addEventDateForm = this.fb.group({
      id: [''],
      event_name: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      start_date: [''],
      latitude:[''],
      longitude:[''],
      end_date: [''],
      sort_description: [''],
      long_description: [''],
      country: [''],
      privacy: [''],
      status: [''],
    });

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };
    this.checkdates();
  }


  checkdates(){
    this.addEventDateForm.valueChanges.subscribe(val => {
      if(val.end_date){
      if(val.start_date>val.end_date){
        Swal.fire(
          'Error',
          `${Popup.eventCheckDate}`,'error'
        );
        this.addEventDateForm.get('end_date').setValue('');
      }
    }
      
  });
}


  updateAddress(){
    this.addressStatus = true;
  }

  getlatitudeLongitude(mapData: any) {
    this.addEventDateForm.get('latitude').setValue(mapData.lat);
    this.addEventDateForm.get('longitude').setValue(mapData.lng);
    this.addEventDateForm.get('address').setValue(mapData.address);
    this.addEventDateForm.get('zip_code').setValue(mapData.postalCode);
}


  deleteEventDate(index: number, id: number){
    Swal.fire({
      title: `${Popup.eventDeleteTitle}`,
      text: `${Popup.eventDatetext}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.eventDateService.deleteEventDate(id).subscribe(res => {
          Swal.fire(
            'Success',
            `${res.message}`,'success'
          );
          this.eventDateList.splice(index, 1);
         },( error ) => {
          Swal.fire(
            'Server Error',
            `${Popup.error}`,
            'error'
          );
         });
      }else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', `${Popup.eventDateSate}`, 'error');
      }
    });
  }
  addresss:any;
  addEventLoader = false;
  updateEventDates(index: number, EventAddress){
    if(this.addEventDateForm.value.address == null || this.addEventDateForm.value.address == ''){
      this.addresss = EventAddress
    }else{
      this.addresss = this.addEventDateForm.value.address
    }
    const data = this.addEventDateForm.value;
    let body = {
      id: this.addEventDateForm.value.id,
      event_name:this.addEventDateForm.value.event_name,
      address: this.addresss,
      zip_code: this.addEventDateForm.value.zip_code,
      city: this.addEventDateForm.value.city,
      start_date: this.addEventDateForm.value.start_date,
      end_date: this.addEventDateForm.value.end_date,
      latitude:this.addEventDateForm.value.latitude,
      longitude:this.addEventDateForm.value.longitude,
      sort_description: this.addEventDateForm.value.sort_description,
      long_description: this.addEventDateForm.value.long_description,
      country: this.addEventDateForm.value.country,
      privacy: this.addEventDateForm.value.privacy,
      status: this.addEventDateForm.value.status,
    }
    this.eventDateList[index] = body;
    this.addEventLoader = true;
    this.eventDateService.updateEventDates(body).subscribe(res => {
      Swal.fire(
        'Success',
        `${res.message}`,'success'
      );
      this.addEventLoader = false;
      this.addEventDateForm.reset();
      this.closeUpdateEventDateModal();
    }, error => {
      Swal.fire(
        'Error',
        `${Popup.error}`,'error'
      );
      this.addEventLoader = false;
    });
  }

  openUpdateEventDateModal(index: number, id: any){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
  };
    this.eventDateUpdateIndex = index;
    this.addEventDateForm.get('id').setValue(id);
    this.eventDateService.getEventDateById(id).subscribe(res => {
      const eventData = res.data;
      this.addEventDateForm.get('id').setValue(id);
      this.addEventDateForm.get('event_name').setValue(eventData.event_name);
      this.eventAddress = eventData.address;
      this.addEventDateForm.get('zip_code').setValue(eventData.zip_code);
      this.addEventDateForm.get('city').setValue(eventData.city);
      this.addEventDateForm.get('country').setValue(eventData.country);
      this.addEventDateForm.get('start_date').setValue(eventData.start_date);
      this.addEventDateForm.get('end_date').setValue(eventData.end_date);
      this.addEventDateForm.get('sort_description').setValue(eventData.sort_description);
      this.addEventDateForm.get('long_description').setValue(eventData.long_description);
      this.modal.open(this.updateHotDate, ngbModalOptions);
    },( err ) => {
      Swal.fire(
        'Error',
        `${Popup.error}`,'error'
      );
    });
  }

  closeUpdateEventDateModal(){
    this.modal.dismissAll(this.updateHotDate);
  }

}

import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SwingersModule } from './swingers/swingers.module';
import { UserModule } from './userDashboard/user.module';
import { HotDatesModule } from '@app/hot-dates/hot-dates.module';
import { EventsModule } from '@app/events/events.module';
import { GroupsModule } from './groups/groups.module';
import { appRoutingModule } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterUserComponent } from './register-user/register-user.component';
import { LookingFormComponent } from './form/looking-form/looking-form.component';
import { PlansComponent } from './plans/plans.component';
import { ComunitySectionComponent } from './components/comunity-section/comunity-section.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { GalleryImageComponent } from './components/gallery-image/gallery-image.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CheckOutComponent } from './check-out/check-out.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PushNotificationsModule  } from 'ng-push-ivy';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { SharingModuleModule } from './Sharing/sharing-module.module';
import { SearchComponent } from './form/search/search.component'
import { DataService } from "./_services/dataShare";
import { HottestmemberComponent } from './home/hottestmember/hottestmember.component';
import { HowitworkComponent } from './home/howitwork/howitwork.component';
import { HottestgroupComponent } from './home/hottestgroup/hottestgroup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { SwingersdifferentComponent } from "./home/swingersdifferent/swingersdifferent.component";
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';;
import { OlduserupdatepasswordComponent } from './olduserupdatepassword/olduserupdatepassword.component';
import { UsernotexistComponent } from './usernotexist/usernotexist.component'
;
import { AboutUsComponent } from './about-us/about-us.component'
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { LoadingBarModule } from '@ngx-loading-bar/core';
@NgModule({
    imports: [
        BrowserModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerWhenStable:30000'
          }),
        ReactiveFormsModule,
        HttpClientModule,
        EventsModule,
        HotDatesModule,
        GroupsModule,
        SwingersModule,
        UserModule,
        BrowserAnimationsModule,
        PushNotificationsModule,
        CarouselModule,
        SharingModuleModule,
        FlatpickrModule.forRoot(),
        appRoutingModule,
        LoadingBarModule
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        RegisterUserComponent ,
        LookingFormComponent ,
        PlansComponent,
        ComunitySectionComponent,
        PrivacypolicyComponent,
        TopBannerComponent,
        GalleryImageComponent,
        CheckOutComponent,
        ContactComponent,
        EmailVerifyComponent,
        SearchComponent,
        HottestmemberComponent ,
        HowitworkComponent ,
        HottestgroupComponent ,
        ForgotPasswordComponent,
        ConfirmPasswordComponent,
        SwingersdifferentComponent,
        TermsAndConditionComponent,
        OlduserupdatepasswordComponent ,
        UsernotexistComponent ,
        AboutUsComponent],
    providers: [
        DataService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ]
})
export class AppModule { }
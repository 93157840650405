import { Component, OnInit } from '@angular/core';
import { SwingersService } from "../../_services/swingers.service";
import { Router } from "@angular/router";
import { AuthenticationService } from '@app/_services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hottestmember',
  templateUrl: './hottestmember.component.html',
  styleUrls: ['./hottestmember.component.css']
})
export class HottestmemberComponent implements OnInit {

  constructor(
    private SwingersService:SwingersService,
    private Router:Router,
    private authenticationService: AuthenticationService
  ) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    rewind: true,
    autoplay: true,
    autoplayHoverPause: true,
    margin: 10,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
  };

  ngOnInit(): void {
    this.gethottestmemb();
  }
  resdata:any= [];
  gethottestmemb(){
    this.SwingersService.gethottestmem().subscribe((res:any)=>{
      this.resdata = res.data.data
      // console.log( this.resdata )
    })
  }

  gotodiscoverpages(){
    this.authenticationService.currentUser.subscribe(check => {
      console.log(check)
      if(check == null){
        this.Router.navigate(['/'])
      }else{
        this.Router.navigate(['/dashboard/members'])
      }
    });
    
  }
}

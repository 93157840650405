<section *ngIf="dataLoaded; else skeleton"></section>
<ng-template #skeleton>
    <main class="container mt-2">
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="col-12 col-lg-12 p-2">
                        <div class="user-img m-2 mr-auto ml-auto">
                            <img src="https://raw.githubusercontent.com/abbasogaji/angularUX-skeleton-screen/master/src/assets/img/user.png"
                                class="img-fluid rounded-circle">
                        </div>
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                        <p class="lead sm-mr-auto sm-ml-auto" style="z-index: 999;"></p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="col-12 col-lg-12 p-2">
                        <div class="user-img m-2 mr-auto ml-auto">
                            <img src="https://raw.githubusercontent.com/abbasogaji/angularUX-skeleton-screen/master/src/assets/img/user.png"
                                class="img-fluid rounded-circle">
                        </div>
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                        <p class="lead sm-mr-auto sm-ml-auto" style="z-index: 999;"></p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="col-12 col-lg-12 p-2">
                        <div class="user-img m-2 mr-auto ml-auto">
                            <img src="https://raw.githubusercontent.com/abbasogaji/angularUX-skeleton-screen/master/src/assets/img/user.png"
                                class="img-fluid rounded-circle">
                        </div>
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                        <p class="lead sm-mr-auto sm-ml-auto" style="z-index: 999;"></p>
                    </div>
                </div>
            </div>
        </div>
    </main>

</ng-template>



<app-header></app-header>
<section class="defualt_bg mt-4">
    <div class="container" >
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center text-light bar_bottom">Checkout</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
        <form [formGroup]="checkoutForm" >
       <div class="row">
           <div class="col-md-6">
               <h4 class="card-header bg-dark text-light">Billing Address</h4>
            <div class="card text-light ">
                <div class="card-body bg-dark">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="username">First Name<span style="color: red;">*</span></label>
                                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">First name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="username">Last Name<span style="color: red;">*</span></label>
                                    <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                                    <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.last_name.errors.required">Last name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">Email<span style="color: red;">*</span></label>
                                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="phone">Phone(Optional)</label>
                                    <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="address_1">Address(Optional)</label>
                                    <app-search-place [mapShow]="mapShow" (mapData)="getlatitudeLongitude($event)">
                                    </app-search-place>
                                    <!-- <input type="text" formControlName="address_one" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address_one.errors }" /> -->
                                    <div *ngIf="submitted && f.address_one.errors" class="invalid-feedback">
                                        <div *ngIf="f.address_one.errors.required">Last name is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="country">Country</label>
                                    <select formControlName="country" (change)="getStates();" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.country.errors }" >
                                        <option  *ngFor="let country of countries" value="{{country?.name}}">{{country?.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                        <div *ngIf="f.country.errors.required">Country is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="state">State</label>
                                    <select formControlName="state" (change)="getCities();" class="form-control" [ngClass]="{'is-invalid': submitted && f.state.errors }" >
                                        <option  *ngFor="let state of states" value="{{state?.name}}">{{state?.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                        <div *ngIf="f.state.errors.required">State is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="city">City</label>
                                    <select formControlName="city"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                        <option  *ngFor="let city of cities" value="{{city?.name}}">{{city?.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <div *ngIf="f.city.errors.required">City is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        
                      
                </div>
            </div>
           </div>
           <div class="col-md-6">
             <div class="row">
                 <div class="col-md-12">
                     <h4 class="card-header bg-dark text-light">Selected Plan</h4>
                     <div class="card bg-dark text-light mb-3">
                         <div class="card-body">
                          <h5>Plan name: <i>{{plan?.plan_name}}</i></h5>
                          <h5>Price: <span class="text-warning">${{plan?.price}}</span></h5>
                          <h5>Validity: <i>{{plan?.duration}} {{plan?.duration_type}}</i></h5>
                        </div>
                     </div>
                 </div>
                 <div class="col-md-12">
                     <h4 class="text-light card-header bg-dark ">Card Details</h4>
                    <div class="card bg-dark text-light">
                        <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="card_number">Card Number<span style="color: red;">*</span></label>
                                            <input type="text" formControlName="cardNumber" placeholder="Enter Card Number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cardNumber.errors }" />
                                            <div *ngIf="submitted && f.cardNumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.cardNumber.errors.required">Card Number is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <label for="expiration_date">Expiration Date<span style="color: red;">*</span></label>
                                        <div class="form-group">
                                        <div class="row">
                                            <div class=col-md-4>
                                                <select formControlName="expiration_month"   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expiration_month.errors }">
                                                    <option  *ngFor="let month of monthArray" value="{{month}}">{{month}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.expiration_month.errors" class="invalid-feedback">
                                                    <div *ngIf="f.expiration_month.errors.required">Expiration month is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-1 p-0 text-center">
                                                /
                                            </div>
                                            <div class="col-md-6">
                                                <select formControlName="expiration_year"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.expiration_year.errors }">
                                                    <option  *ngFor="let year of yearArray" value="{{year}}">{{year}}</option>
                                                </select>
                                                <div *ngIf="submitted && f.expiration_year.errors" class="invalid-feedback">
                                                    <div *ngIf="f.expiration_year.errors.required">Expiration month is required</div>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="security_code">Security Code (cvc)<span style="color: red;">*</span></label>
                                            <input type="text" formControlName="cvv" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }" />
                                            <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                                                <div *ngIf="f.cvv.errors.required">Security code is required</div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="discount_code">Discount Code(Optional)</label>
                                            <input type="text" formControlName="discount_code" class="form-control"  />
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <button [disabled]="loading" class="btn btn-warning border-radius-0" (click)="userCheckout()">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    <span *ngIf="!loading">Submit and Checkout »</span>
                                    <span *ngIf="loading">Please Wait</span>
                                </button>&nbsp;&nbsp;
                                <button class="btn btn-danger border-radius-0" (click)="cancelpayment()">
                                    <span >Cancel</span>
                                </button>
                                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </div>
                    </div>
                </div>
             </div>
           </div>
       </div>
    </form>
  
</div>
</div>
        </div>

</section>
    

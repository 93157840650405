<app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark set_outline">
                    <!-- <img src="{{sitelogo}}" class="img-fluid logo_cu"> -->
                    <div class="card-body">
                        <form [formGroup]="confirmPassForm" (ngSubmit)="onSubmit()">
                            <h1 class="text-center titles" ><i class="fas fa-unlock" style="color: #ffc107;"></i> &nbsp;UPDATE PASSWORD</h1>
                            
                            <div class="form-group">
                                <label for="pssaword">Email</label>
                                <input  value="{{EmailID}}" class="form-control" readonly />
                            </div>
                           
                            <div class="form-group">
                                <label for="pssaword">New Password</label>
                                <input [type]="hide ? 'password' : 'text'"  formControlName="pssaword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pssaword.errors }" />
                                <span class="showhide"> 
                                    <a (click)="hide = !hide"> <i class="fas fa-eye-slash" *ngIf="hide"></i>
                                     <i class="fas fa-eye" *ngIf="!hide"></i></a>
                                 </span>
                                 <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.pattern">Password Minimum eight characters, at
                                        least one uppercase letter, one lowercase letter, one number and one
                                        special character</div>

                                    <div *ngIf="f.password.errors.required">Password is required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="confirmpassword">Confirm Password</label>
                                <input [type]="hides ? 'password' : 'text'" formControlName="confirmpassword" autocomplete="false" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" />
                                <span class="showhide"> 
                                    <a (click)="hides = !hides"> <i class="fas fa-eye-slash" *ngIf="hides"></i>
                                     <i class="fas fa-eye" *ngIf="!hides"></i></a>
                                 </span>
                                <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                                </div>
                            </div>

                            <button [disabled]="loading" class="btn btn-danger">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
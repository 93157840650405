import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment, Popup } from '@environments/environment';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthenticationService } from '@app/_services';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'old-user-update-password',
  templateUrl: './olduserupdatepassword.component.html',
  styleUrls: ['./olduserupdatepassword.component.css']
})
export class OlduserupdatepasswordComponent implements OnInit {
  EmailID: any;
  ids:any
  hide = true;
  hides = true;
  confirmPassForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  sitelogo = environment.siteLogolight;
 
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private Title:Title
  ) {
    this.Title.setTitle('Old User Update Password | ChatnHookUP')
    this.route.queryParams.subscribe(params => {
      this.ids = params['id']
      this.getuserEmail(this.ids)
    });
  }
  getuserEmail(id:any){
    this.authenticationService.Olduseremail(id).subscribe(res=>{
      this.EmailID = res.data.email;
      // console.log(this.EmailID)
    })
  }

  ngOnInit() {
   
    this.confirmPassForm = this.formBuilder.group({
      pssaword: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirmpassword: ['',[Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
  }


  // convenience getter for easy access to form fields
  get f() { return this.confirmPassForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.confirmPassForm.invalid) {
      return;
    }

    let body = {
      email: this.EmailID,
      password: this.f.pssaword.value,
      password_confirmation: this.f.confirmpassword.value
    }
    this.authenticationService.updateOlduserpassword(body).subscribe(res => {
      if (res) {
        this.confirmPassForm.reset();
        this.router.navigate(['/login'])
      }
      Swal.fire("Success", `${res.message}`, "success");
      this.loading = false;
    }, error => {
      this.loading = false;
      Swal.fire("error", `${Popup.OldPasssUpdate}`, "error");
    });
  }


}

<div class="firend-navigation">
  <div class="container">
    <div class="row">
        <div class="col-md-12 p-0">
            <ul class="nav">
              <li class="nav-item">
                <a class="btn btn-light " routerLink="/dashboard/friends"  routerLinkActive="active"><i class="fas fa-users"></i> Friends</a>
              </li>
                <li class="nav-item">
                  <a class="btn btn-light " routerLink="/dashboard/firend-request-pandding"  routerLinkActive="active"><i class="fas fa-user-clock"></i> Pending Requests</a>
                </li>
                <li class="nav-item">
                  <a class="btn btn-light me-4" routerLink="/dashboard/firend-request-sent" routerLinkActive="active"><i class="fas fa-user-friends"></i> Sent Requests</a>
                </li>
               
                <li class="nav-item">
                  <a class="btn btn-light me-4" routerLink="/dashboard/block-friends" routerLinkActive="active"><i class="fas fa-user-alt-slash"></i> Block Friends</a>
                </li>

                <!-- <li class="nav-item">
                  <a class="btn btn-light me-4" routerLink="/dashboard/favourite-friends" routerLinkActive="active"><i class="fas fa-star"></i> favourite Friends</a>
                </li> -->
              </ul>
        </div>
    </div>
</div>
</div>

<div class="card bg-dark text-light mb-3 mt-2">
  <div class="card-header"><h6>Plan History</h6></div>
  <div class="card-body over_flow_wrapper_big table-responsive">
    <table class="table table-striped table-dark">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Plan name</th>
          <th scope="col">Payment</th>
          <th scope="col">Validity</th>
          <th scope="col">Remaining Days</th>
          <th scope="col">Status</th>
          <th scope="col">Invoice</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of userInvoces; let i = index">
          <th scope="row">{{ 1 + i }}</th>
          <td>{{ invoice?.plan_name }}</td>
          <td>{{ invoice?.payment_amount | currency: "USD" }}</td>
          <td>{{ invoice?.expiry_date }}</td>
          <td>{{ invoice?.remaining }} Days</td>
          <td>
            <span
              class="badge badge-success"
              *ngIf="invoice.payment_status == '1'"
              >Active</span
            >

            <span
              class="badge badge-danger"
              *ngIf="invoice.payment_status == '0'"
              >Deactive</span
            >
          </td>
          <td>
            <button
              class="btn btn-sm btn-warning"
              (click)="getUserInvoicebyId(invoice.id)"
            >
              Get Invoice
            </button>
          </td>
          <td>
            <button *ngIf="invoice?.plan_id != '1'"
              class="btn btn-sm btn-danger mr-2"
              [disabled]="invoice.payment_status == '0'"
              (click)="cancelMembership(invoice.subscription_id)"
            >
              <span *ngIf="invoice.payment_status == '1'"
                >Cancel Membership</span
              >
              <span *ngIf="invoice.payment_status == '0'"
                >Canceled Membership</span
              >
            </button>
            <button *ngIf="invoice?.plan_id == '1'" class="btn btn-sm btn-warning mr-2" routerLink="/plan">
              <span>Upgrade plan</span >
            </button>

          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center" *ngIf="loading">
      <div class="spinner-border text-light spinner-border-sm" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>
</div>

<ng-template #checkInvoice let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Invoice</h5>
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-12">
        <div id="print-section">
          <app-invoce [invoceData]="invoceData"></app-invoce>
        </div>
        <span class="text-right pull-right">
          <button
            class="btn btn-sm btn-warning"
            printSectionId="print-section"
            [useExistingCss]="true"
            ngxPrint>
            <i class="fas fa-print"></i> Print
          </button>
        </span>
      </div>
    </div>
  </div>
</ng-template>

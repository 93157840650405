
<!-- <app-header></app-header> -->
<section class="" style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
  <div class="container">
    <div class="row " >
        <div class="col-lg-7 col-md-6 col-sm-12">
         </div>
         <div class="col-lg-5 col-md-6 col-sm-12" >
           <app-register-user></app-register-user>
            <!-- <app-looking-form>
                <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </app-looking-form>   -->
         </div>

    </div>
  
</div>
</section>
<!-- <section>
  <app-hottestmember></app-hottestmember>
  <app-hottestgroup></app-hottestgroup>
  <app-swingersdifferent></app-swingersdifferent>
  <app-howitwork></app-howitwork>
</section> -->
<section *ngIf="dataLoaded; else skeleton"></section>
<ng-template #skeleton>
    <main class="container mt-2">
        <div class="row">
            <div class="col-md-3">
                <div >
                    <div class="col-12 col-lg-12 p-2">
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                  
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div >
                    <div class="col-12 col-lg-12 p-2">
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                      
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div >
                    <div class="col-12 col-lg-12 p-2">
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                        
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div >
                    <div class="col-12 col-lg-12 p-2">
                        <h2 class="display-5 text-capitalize sm-mr-auto sm-ml-auto" style="z-index: 999;"></h2>
                      
                    </div>
                </div>
            </div>
        </div>
    </main>

</ng-template>



<app-user-gallery-navigation></app-user-gallery-navigation>
<div class="card bg-light text-light mb-3 mt-2">
  <div class="card-header bg-dark">
    <h6><span class="hoverPointer" (click)="openAlbumWrapper();">Albums</span> <span *ngIf="albumName"
        class="text-capitalize"> | {{albumName}}</span> <span style="float: right; cursor: pointer;"
        (click)="openCreateAlbumModal()"> <i class="fas fa-plus" style="color: white;"></i> {{Create}}</span></h6>
  </div>
  <div class="card-body" *ngIf="!freeuser">
    <div class="row" id="wrapper">
    <div class="col-md-12">
        <div *ngIf="albumLoading" class="text-center">
        <div class="spinner-border text-dark mt-4" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>
      <ng-template [ngIf]="!openAlbumImages">
        <div class="col-md-4 col-sm-6" *ngFor="let album of albums; let i = index;">
          <div class="top_wrapper">
            <div class="edit_mode_wrapper">
              <div [ngSwitch]="album.privacy" class='edit_mode_item_r'>

                <i *ngSwitchCase="2" (click)="privacyEditMode(i);" class="fas fa-user-friends"></i>
                <i *ngSwitchCase="3" (click)="privacyEditMode(i);" class="fas fa-lock"></i>
                <i *ngSwitchCase="1" (click)="privacyEditMode(i);" class="fas fa-globe m-1"></i>
              </div>
              <span (click)="deleteAlbum(album.id, i)"><i class="fas fa-trash-alt m-1"></i></span>
            </div>
          </div>
          <div *ngIf="album.privacy_mode" class="privacy_mode_wrapper">
            <ul>
              <li (click)="changeAlbumPrivacy(album.id, 1, i);"><i class="fas fa-globe"></i> Public</li>
              <li (click)="changeAlbumPrivacy(album.id, 2, i);"><i class="fas fa-user-friends"></i> Friends</li>
              <li (click)="changeAlbumPrivacy(album.id, 3, i);"><i class="fas fa-lock"></i> Only me</li>
            </ul>
          </div>
         <div class="text-center">
          <div *ngIf="album.deleteLoadingStatus" class="spinner-grow spinner-grow-sm staus_grow_delete" role="status">
            <span class="visually-hidden"></span>
          </div>
          <div *ngIf="album.loadingPrivacyStatus" class="spinner-grow spinner-grow-sm staus_grow" role="status">
            <span class="visually-hidden"></span>
          </div>
         </div>

          <div class="create-album-wrapper text-center " (click)="openAlbum(album.id,i)">
            <div class="row album-wrapper">
              <div class="col-12">
                <img class="img-fluid" src="{{album.imagename[0]}}" onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
              </div>
              <div class="col-6 img_2">
                <img class="img-fluid" src="{{album.imagename[1]}}"  onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
              </div>
              <div class="col-6 img_3">
                <img class="img-fluid" src="{{album.imagename[2]}}"  onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
                <span class="more_img" *ngIf="album?.Pluse">   
                {{album?.newCount}} <i  class="fas fa-plus"  aria-hidden="true" *ngIf="album?.Pluse"></i>
              </span>
              </div>
            </div>

            <div class="bottom-text-wrapper">
              <p class="text-capitalize mb-0 text-center h6 testwrap">{{album.name}}</p>

            </div>
          </div>
        </div>
      </ng-template>



      <div class="text-center col-md-12">
        <div *ngIf="albumStatus" class="text-center">
          <div class="spinner-border text-dark mt-4" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
        <p *ngIf="nodata" style="color: #484848; text-align: center;">There is no album.</p>
      </div>

      <div class="col-md-3 col-6 p-1" *ngFor="let image of albumImages; let i=index">
        <div class="top_wrapper">
          <div class='video_views_wrapper'>
            <span (click)="albumImageLike(image.id,i)" class="like"><i class="fas fa-thumbs-up"></i>
              {{image.likes}}</span>
            <span class="like"><i class="fas fa-eye"></i> {{image.views}}</span>
          </div>
          <span (click)="deleteAlbumImage(image.id, image.album_id)" class="delete_wr"><i
              class="fas fa-trash-alt"></i></span>
        </div>
        <div class="image-wrapper text-center">
          <img src="{{image.s3Image}}" onerror="this.src='../../../assets/images/onerroralbum.png';" (click)="open(i)" class="img-fluid">
        </div>
      </div>

    </div>
    <!-- <div *ngIf="alImgcheckpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of alImgPagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="imageActivePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12">
        <div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'"
          id="createAlbum" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header bg-dark">
                <h5 class="modal-title" style="color: #ffffff;" id="exampleModalLabel">{{albumModalHeading}}</h5>

                <button type="button" class="btn-close" (click)="closeCreateAlbumModal();" data-bs-dismiss="modal"
                  aria-label="Close"><i class="fas fa-times" style="color: white;"></i></button>
              </div>
              <div class="modal-body bg-light">
                <!-- <div class="progress form-group" *ngIf="progress > 0">
                  <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                    [style.width.%]="progress">
                  </div>
                  <div class='count-progressbar bg-dark text-center text-light'>{{progress}} %</div>
                </div> -->

                <form [formGroup]="album" (ngSubmit)="storeGallery()">
                  <div class="form-group" *ngIf="!albumUpdateStatus">
                    <label for="sortTagline" style="color: #484848;">Album Name</label>
                    <input type="text" class="form-control" formControlName="album_name">
                  </div>

                  <div class="form-group" *ngIf="!albumUpdateStatus">
                    <label for="location"></label>
                    <div class="row">
                      <div class="col-md-6">
                        <label style="color: #484848;">Select privacy</label>
                        <select class="form-control" formControlName="privacy">
                          <option disabled value="0" selected>Select Privacy</option>
                          <option value="1">Public</option>
                          <option value="2">Show only Friends</option>
                          <option value="3">Only Me</option>
                        </select>
                      </div>
                    </div>
                    <small *ngIf="!albumUpdateStatus" style="color: #484848;">What type Chat & HookUP you want to show
                      Album</small>

                    <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                      <div *ngIf="f.privacy.errors.required" style="color: #484848;">Type is required.</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="file" #albumImages (change)="loadImages(albumImages.files)" accept='image/*'
                      class="form-control d-none" multiple="" formControlName="photos" />
                    <div *ngIf="submitted && f.photos.errors" class="invalid-feedback">
                      <div *ngIf="f.photos.errors.required" style="color: #484848;">Photos is required</div>
                    </div>
                  </div>

                  <div class="row mb-3 over_flow">
                    <div class="col-md-3">
                      <div class="create_album_bx text-center mb-3" (click)="albumImages.click();">
                        <img src="../../../../assets/images/file-upload-image-icon-png.png" onerror="this.src='../../../assets/images/onerroralbum.png';" class="uploadimg">
                      </div>
                    </div>
                    <div class="col-md-3" *ngFor="let image of gallerImages; let i =index">
                      <i class="fas fa-trash-alt customes" (click)="deleteone(i)"></i>
                      <img src="{{image}}" onerror="this.src='../../../assets/images/onerroralbum.png';" class="img-fluid gallery_image_rs">

                    </div>
                  </div>


                  <div *ngIf="message" class="alert alert-success" role="alert"><i
                      class="fas fa-check-circle"></i>{{message}}</div>
                  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                  <p style="font-size: 12px;color: #ccc;"><i><span style="color: red;">*</span><span
                        style="color: #484848;"> Please attach 5 photo's at a time. Maximum size of photo's 25 MB
                        only.</span></i></p>
                  <button [disabled]="loading" class="btn btn-success">
                    <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                      <span class="visually-hidden"></span>
                    </div> <span *ngIf="!loading">Submit</span>
                    <span *ngIf="loading">Please Wait</span>
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!freeuser">
    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DataService } from "../../_services/dataShare";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  defaultProfile = environment.defaultProfile;
  Datas: any;
  searchSwingers: any;
  pagination: any;
  isData = false;
  constructor(
    private DataService: DataService,
    private router: Router,
    private Title:Title
  ) {
    this.Title.setTitle('Search | Chatnhookup')
    this.Datas = this.DataService.getOption();

    this.searchSwingers = this.Datas.data.data;
    this.pagination = this.Datas.data;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
    for (const swinger in this.searchSwingers) {
      this.searchSwingers[swinger].Profile = (this.searchSwingers[swinger].Profile) ? `${environment.imgUrl}/${this.searchSwingers[swinger].id}/images/user/profile/${this.searchSwingers[swinger].Profile}` : this.defaultProfile;
      this.searchSwingers[swinger].distance = Math.round(this.searchSwingers[swinger].distance)
    }
    if (this.Datas == null || this.Datas == undefined || this.Datas == '') {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

  }

  gotouserinfo(id) {
    const userId = JSON.parse(sessionStorage.getItem('currentUser'));
    if (userId == null || userId == undefined || userId == '') {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate([`/${id}/swinger/profile`])
    }
  }

}

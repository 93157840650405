import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment, Popup } from '@environments/environment';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthenticationService } from '@app/_services';
import { Title } from '@angular/platform-browser';
import { MessagesService } from '../_services/messages.service';
@Component({ templateUrl: 'login.component.html',
             styleUrls: ['./login.component.css'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    hide = true;
    sitelogo = environment.siteLogolight;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private Title:Title,
        private MessagesService:MessagesService
    ) { 
        this.Title.setTitle('Login | Chatnhookup')
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember: ['']
        });

        // get return url from route parameters or default to '/'
       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
   
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
             .subscribe(data => {
                const per = JSON.parse(sessionStorage.getItem('currentUser'))
                if(per.error == 'UnAuthorised'){
                  sessionStorage.clear();
                  this.loading = false;
                  Swal.fire('Error', `${Popup.login}`, 'error').then();
                }else{
                    // console.log(data.user_status)
                    if(data.user_status == 1){
                        this.router.navigate([this.returnUrl]);
                    }else{
                        this.loading = false;
                        sessionStorage.clear();
                        Swal.fire('Warning', `Your account has been blocked. Please contact support team (support@chatnhookup.com)`, 'warning');  
                    }
                }
            }, error => {
                this.loading = false;
                const errorData = JSON.parse(sessionStorage.getItem('error')).errors;
                if (errorData) {
                    Swal.fire('Error', `${errorData[0]}`, 'error');
                    sessionStorage.removeItem('error');
                    this.loading = false;
                }
                   //this.error = error;
            });
    }


    
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

getallunreadinbox():Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-total-unseen-message`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getallusermessage():Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-user-message`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


getallmessage(id):Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-all-message/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


sendmessage(body) :Observable<any>{
  return this.http.post<any>(`${environment.apiUrl}/send-message`,body, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

// clearAllmessage(id):Observable<any> {
//   return this.http.get<any>(`${environment.apiUrl}/clear-chat/${id}`, this.httpOptions).pipe(
//     retry(1),
//     catchError(this.handleError)
//   );
// }

searchInboxList(body):Observable<any>{
 return this.http.post(`${environment.apiUrl}/search-user-inbox`, body, this.httpOptions).pipe(
   retry(1),
   catchError(this.handleError)
 );
}

// nodejs Api

// get Data user Inbox
getAllUserInbox(id:any){
  return this.http.get(`${environment.mongoapiurl}/user/inbox/fetch/${id}`, this.httpOptions).toPromise();
}

// update User Status

userStatus(id:any, body:any){
 return this.http.put(`${environment.mongoapiurl}/user/online/ststus/${id}`,body, this.httpOptions).toPromise();
}

userStatusTyping(id:any, body:any){
 return this.http.put(`${environment.mongoapiurl}/user/online/typing/${id}`,body, this.httpOptions).toPromise();
}

// message send Data 
 sendmessages(body:any):Observable<any>{
  return this.http.post<any>(`${environment.mongoapiurl}/user/message/insert`, body, this.httpOptions).pipe(
     retry(1),
     catchError(this.handleError)
   );
 }

 inboxsender(body:any):Observable<any>{
   return this.http.post<any>(`${environment.mongoapiurl}/user/inbox/sender`, body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
 inboxreceiver(body:any):Observable<any>{
   return this.http.post<any>(`${environment.mongoapiurl}/user/inbox/receiver`, body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

//messages
 getuserMessages(body:any){
   return this.http.get(`${environment.mongoapiurl}/user/profile/${body}`, this.httpOptions).toPromise();
 }

 allmessagesuser(body:any){
   return this.http.post(`${environment.mongoapiurl}/user/message/get`,body, this.httpOptions).toPromise();
 }

 allmessagesuserPag(body:any){
   return this.http.post(`${environment.mongoapiurl}/user/message`,body, this.httpOptions).toPromise();
 }

 updaemsgseen(body:any){
   return this.http.put(`${environment.mongoapiurl}/user/message/seen/${body}`, this.httpOptions).toPromise();
 }

 usersearch(body):Observable<any>{
  return this.http.post<any>(`${environment.mongoapiurl}/search/inbox`,body, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
 }


 
 messagecount(body):Observable<any>{
  return this.http.get<any>(`${environment.mongoapiurl}/unread/count/${body}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
 }

 clearAllmessage(id):Observable<any> {
  return this.http.put<any>(`${environment.mongoapiurl}/user/message/delete`,id, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getFriends(body:any):Observable<any>{
  return this.http.post<any>(`${environment.mongoapiurl}/user/inboxSingle/fetch`, body, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  )
}
}

import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService, PlanService, GlobalLocationsService, UserService } from '@app/_services';
import { environment, Popup } from '@environments/environment';
import { first, map } from 'rxjs/operators';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { commonData } from '@app/_helpers/common';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
import Bowser from 'bowser';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})

export class RegisterUserComponent implements OnInit {
  selectedPlanDetails: any;
  sitelogo = environment.siteLogolight;
  registerForm: FormGroup;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  data: any;
  options: FlatpickrDefaultsInterface;
  plans: any;
  planFeatures: any;
  freePlan = false;
  closeResult = '';
  termData = false;
  countries: any;
  states: any;
  cities: any;
  checkPlanId = true;
  checkTermsCondition = false;
  currentUser: any;
  isLogin = false;
  checkPlanIdSession = sessionStorage.getItem('planId');
  genders = commonData.gender;
  mapShow = false;
  hide = true;
  hides = true;
  userAgent = Bowser.parse(window.navigator.userAgent);
  browser = Bowser.getParser(window.navigator.userAgent);
  userAgentDetails = JSON.stringify(this.userAgent, null, 4);
  browserDetails = JSON.stringify(this.browser.getBrowser(), null, 4);
  ipAddress:any;
  previousUrl: string;

  @ViewChild('selectedPlan') selectedPlan: ElementRef;
  @ViewChild('termsCondition', { static: false }) termsCondition!: TemplateRef<any>;
  @ViewChild('logins', { static: false }) logins!: TemplateRef<any>;
  @ViewChild('planss', { static: false }) planss!: TemplateRef<any>;
  dobs: any = new Array("18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99");
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private planService: PlanService,
    private modal: NgbModal,
    private globalLocation: GlobalLocationsService,
    private Title: Title,
    private userService: UserService,
  ) {
    this.Title.setTitle('ChatnHookUP')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser != null) {
        this.isLogin = true;
        this.router.navigate(['/dashboard'])

      } else {
        this.isLogin = false;
      }
    });
    this.planService.getPlans().subscribe(res => {
      this.registerForm.get('plan_id').setValue(1);
      this.plans = res.data;
      this.checkActiveUrl();
      if (this.checkPlanId) {
        this.getFeaturesById();
      }
    }, (error) => {
      console.log(error);
    });
    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      maxDate: '2002-01',
      enable: [
        {
          from: '1860-04-01',
          to: '2002-04-01',
        },
      ],
    };
    this.userService.getIPAddress().then((data:any)=>{
      let responce = data;
      let body = {
        user_id:'',
        plan_id: '',
        browser : JSON.parse(this.userAgentDetails).browser.name,
        os : JSON.parse(this.userAgentDetails).os.name,
        device : JSON.parse(this.userAgentDetails).platform.type,
        ip_address : responce.ip,
        from_page : '/home',
        error_on_page:'/register/login',
        bIlling_status : 'Wisit'
     }
     this.userService.userAvodit(body).subscribe(res=>{})
    }).catch((err=>{
      console.log(err)
    })) 
  }

  ngOnInit(): void {
    this.gettermCondi();
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      dob: ['18', Validators.required],
      gender: ['m', Validators.required],
      latitude: [''],
      longitude: [''],
      address: [''],
      plan_id: [1, Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      confirm_password: ['', Validators.required],
      zipcode: ['', Validators.required],
      terms: ['1', Validators.required],
    },{ validators: this.checkPasswords });
    this.checkValidPassword();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: ['']
    });
    this.returnUrl = this.activeRoute.snapshot.queryParams['returnUrl'] || 'dashboard';
    this.getPlansFeaturs();
  }

  notSame = false;
  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirm_password')?.value;
    if(confirmPass.length != 0){
      if(pass !== confirmPass){
        this.notSame = true;
      }else{
        this.notSame = false;
      }
    }
    return pass === confirmPass ? null : { notSame: true }
  }
  get f() { return this.registerForm.controls; }

  checkValidPassword() {
    this.registerForm.get('password').valueChanges.subscribe(paw => {
    })
  }

  registerUser() {
    this.submitted = true;
    const termCheck = this.registerForm.get('terms').value;
    if (termCheck == '' && this.registerForm.invalid) {
      this.checkTermsCondition = true;
      return;
    }
    this.loading = true;
    if (this.f.password.status == 'VALID') {
      this.authenticationService.register(this.registerForm.value).pipe(first()).subscribe(data => {
        // console.log(data)
        if (Number(this.checkPlanIdSession) != 1) {
          this.router.navigate([`/check-out`], { queryParams: { id: this.checkPlanIdSession, uid: data.user_id } });
        } else {
          Swal.fire(
            'Success',
            `${Popup.register}`,
            'success'
          );
          this.router.navigate([`/`]);
          this.loading = false;
        }
        this.registerForm.reset();
      }, (error) => {
        const errorData = JSON.parse(sessionStorage.getItem('error')).errors;
        if (errorData) {
          Swal.fire('Error', `${errorData[0]}`, 'error');
          sessionStorage.removeItem('error');
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
      return;
    }

  }


  getlatitudeLongitude(mapData: any) {
    this.registerForm.get('latitude').setValue(mapData.lat);
    this.registerForm.get('longitude').setValue(mapData.lng);
    this.registerForm.get('address').setValue(mapData.address);
    this.registerForm.get('zipcode').setValue(mapData.postalCode);
  }

  getPlans() {
    
  }

  getDataId(name: string, dataList: any) {
    const obj = dataList.find(resData => {
      if (resData.name == name) {
        return resData;
      }
    });
    return obj?.id;
  }


  checkActiveUrl() {
    this.activeRoute.queryParams.subscribe(activeUrlData => {
      const planId = activeUrlData.id;
      if (planId) {
        this.registerForm.get('plan_id').setValue(planId);
        this.checkPlanId = false;
        this.getFeaturesById();
      } else {
        this.checkPlanId = true;
      }
    });
  }

  getFeaturesById() {
    const planId = (this.registerForm.get('plan_id').value) ? this.registerForm.get('plan_id').value : 1;
    this.checkPlanIdSession = planId;
    sessionStorage.setItem('planId', planId);
    const plan = this.plans.find(obj => obj.id == planId);
    if (plan.price === '0') {
      this.freePlan = true;
    } else {
      this.freePlan = false;
    }

    this.planService.getFeaturesWithPlanId(planId).subscribe(res => {
      this.selectedPlanDetails = plan;
      this.planFeatures = res.data;
    }, (err) => {
      Swal.fire(
        'Error',
        `${Popup.planID}`, 'error'
      );
    });
  }

  checked = true;
  GetAllPlans: any;
  getPlansFeaturs() {
    this.planService.getPlansWithFeatures().subscribe(res => {
      this.GetAllPlans = res.data;
    }, (err) => {
      Swal.fire(
        'Error',
        `${Popup.planID}`, 'error'
      );
    });
  }

  // Open Terms Dialog Box
  showTerms() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl'
    };
    this.modal.open(this.termsCondition, ngbModalOptions);
  }
  closeTerms(disAgree?: any) {
    if (disAgree == 0) {
      this.modal.dismissAll(this.termsCondition);
      this.registerForm.get('terms').setValue(0);
    } else {
      this.modal.dismissAll(this.termsCondition);
    }
  }

  termsOnChange(agree?: any) {
    if (agree == 1) {
      this.registerForm.get('terms').setValue(1);
      this.checkTermsCondition = false;
    } else {
      this.termData = this.registerForm.get('terms').value;
      if (this.termData === true) {
        this.registerForm.get('terms').setValue(1);
        this.checkTermsCondition = false;
      } else {
        this.registerForm.get('terms').setValue(0);
        this.checkTermsCondition = true;
      }
    }
  }


  checkAgeStatus() {
    const stateName = this.registerForm.get('dob').value;
    if (stateName == 0) {
      Swal.fire(
        'Age Restriction',
        `${Popup.checkAge}`,
        'error'
      );
      this.registerForm.reset();
    }
  }

  // Open Login Dialog Box
  showlogins() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.logins, ngbModalOptions);
  }
  closesignin() {
    this.modal.dismissAll(this.logins);
  }

  gotoResetPass() {
    this.router.navigate(['/forgot-password']);
    this.closesignin();
  }

  // convenience getter for easy access to form fields
  get flog() { return this.loginForm.controls; }
  loadinglogin = false;
  submitt = false
  onSubmit() {
    this.submitt = true;
    this.loadinglogin = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService.login(this.flog.username.value, this.flog.password.value)
      .pipe(first())
      .subscribe(data => {
        if (data.remaining_dayes <= 5 && data.remaining_dayes > 0) {
          if (data.plancheck == 1) {
            Swal.fire('Warning', `Your ChatnHookUP Current Plan will expire within ${data.remaining_dayes} Days.`, 'warning');
          }
          this.closesignin();
        } else if (data.remaining_dayes === 0) {
          this.closesignin();
          this.loadinglogin = false;
        }

        else {
          const per = JSON.parse(sessionStorage.getItem('currentUser'))
          if (per.error == 'UnAuthorised') {
            sessionStorage.clear();
            this.loadinglogin = false;
            Swal.fire('Error', `${Popup.login}`, 'error').then();
          } else {
            // console.log(data.user_status)
            if (data.user_status == 1) {
              this.closesignin();
              this.router.navigate([this.returnUrl]);
            } else {
              this.loadinglogin = false;
              sessionStorage.clear();
              Swal.fire('Warning', `Your account has been blocked. Please contact support team (support@chatnhookup.com)`, 'warning');
            }
          }
        }

      }, error => {
        this.loadinglogin = false;
        const errorData = JSON.parse(sessionStorage.getItem('error')).errors;
        if (errorData) {
          Swal.fire('Error', `${errorData[0]}`, 'error');
          sessionStorage.removeItem('error');
          this.loadinglogin = false;
        }
      });
  }

  // Open Plans Dialog Box
  showplans() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl'
    };
    this.modal.open(this.planss, ngbModalOptions);

  }
  closePlans() {
    this.modal.dismissAll(this.planss);

  }
  statusSelectPlan: any;
  getFeaturesselect(id, i: any, event) {
    this.statusSelectPlan = event.target.checked;
  }

  chooseother(id, i: any) {
    if (this.statusSelectPlan == true) {
      this.registerForm.get('plan_id').setValue(id);
      this.checkPlanIdSession = id;
      this.closePlans();
    } else {
      Swal.fire('warning', `Please select Plan`, 'warning')
    }

  }

  TermsData: any;
  gettermCondi() {
    this.planService.getterms().subscribe(res => {
      let DataTerms: any = [];
      DataTerms = res.data
      for (let index = 0; index < DataTerms.length; index++) {
        if (DataTerms[index].information_type == 'Terms & Conditions') {
          this.TermsData = `${DataTerms[index].content}`
        }
      }

    })
  }

}





<app-friend-navigation></app-friend-navigation>

<div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header bg-dark">
    <h6 class="mb-0">Pending Requests</h6>
  </div>
  <div class="container">
    <div class="row mb-2 bg-light" *ngIf="!freeuser">
      <div class="col-md-4" *ngFor="let friend of panddingFirend">
        <app-pandding-friend [requestedfirend]="friend"></app-pandding-friend>
      </div>
      
    
    </div>
  </div>
    <app-skoltan-loader *ngIf="loading"></app-skoltan-loader>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center mt-4" *ngIf="!freeuser">
        <p *ngIf="dataNotFound" style="color: #484848;">There is no pending request.</p>
      </div>
    </div>
  </div>
  
  <div class="row mt-4" *ngIf="!freeuser && pagelarg">
    <div class="col-md-12 text-center">
      <nav aria-label="..." class="text-center">
        <ul class="pagination justify-content-center">
          <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
            [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
              [innerText]="link.label"></a></li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>
</div>
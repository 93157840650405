<div *ngIf='!uiHide' class="card bg-light">
  <div class="dropdown">
    <i class="fas fa-ellipsis-v vermenu "></i>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <!-- <li *ngIf="friend.is_faverate==0">
        <a class="dropdown-item" (click)="addToFavourite(friend.id)">
          <div *ngIf="favouriteloading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
          </div> <i *ngIf="!favouriteloading" class="fa fa-heart" aria-hidden="true"></i> Favourite
        </a>
      </li> -->
      <li>
        <a class="dropdown-item" data-toggle="tooltip" data-placement="top" title="Send Message"
          (click)="openAddNewHotdateModal(friend.id,friend.name, friend?.gender, friend?.is_pic, friend?.user_status)"><i
            class="fa fa-comments"></i> Message</a>
      </li>
      <li>
        <a class="dropdown-item" data-toggle="tooltip" data-placement="top" title="View profile"
          (click)="UnFriends(friend.id)">
          <div *ngIf="unfriendloading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
          </div> <i *ngIf="!unfriendloading" class="fas fa-user-slash" style="font-size: 14px;"></i> Unfriend
        </a>
      </li>

      <li>

        <a (click)="blockFriend(friend.id)" class="dropdown-item">
          <div *ngIf="blockloading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
          </div> <i *ngIf="!blockloading" class="fas fa-ban"></i> Block friend
        </a>

      </li>
    </ul>
  </div>
  <div class="text-center">
    <div class="col-12">
      <div class="user_profile_ls"><img routerLink="/{{friend.id}}/member/profile" [src]="friend.is_pic"
          onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
          class="img-fluid user-profile">
          <img src="../../../assets//images/verified.png" *ngIf="friend.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top" title="Verified profile" >
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="mt-4">
          <h5 class="mt-3"><a routerLink="/{{friend.id}}/member/profile" style="margin-bottom: 2px;color: #484848;"
              (click)="setProfileViews(friend?.id)">{{friend?.name}} <i class="fas fa-heart"
                style="color: red;"></i></a></h5>
          <div class="mt-3">
            <span *ngIf="friend?.gender != null"
              [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
              class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
          </div>
        </div>
      </div>
   
    </div>
  </div>
</div>



<ng-template #SentMessageModalContent let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="message">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>

          <div class="form-group">
            <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess"
              placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
            <!-- <buton class="btn btn-warning" >
              <span class="input-group-text">
              <i (click)="toggled = !toggled" [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                ></i>
            </span>
          </buton> -->
            <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary"
              (click)="onSubmit()">
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="!hideIfFriend" class="text-cenrter card">
  <div class="row">
    <div class="col-md-12">
      <div class="dropdown">
        <i class="fas fa-ellipsis-v vermenu "></i>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li >
            <a class="dropdown-item"  (click)="makeFriend(requestedfirend.id)">
              <div *ngIf="confirm" class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div> Confirm
            </a>
          </li>
          <li >
            <a class="dropdown-item" (click)="declinerequest(requestedfirend.id)">
              <div *ngIf="confirm" class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div> Decline
            </a>
          </li>
         
        </ul>
      </div>
      <div class="user_profile_ls mt-4 mt-2">
        <img routerLink="/{{requestedfirend.id}}/member/profile" [src]="(requestedfirend.profile == null)?defaultProfile:profilePic" alt="user-profile" class="img-fluid" />
        <img src="../../../assets//images/verified.png" *ngIf="requestedfirend.plan_id != 1" class="verifyImage" >
      </div>
  </div>

  <div class="col-12 text-center">
        <div >
          <h5 class="mt-3" style="color: #484848;"><a style="color: #484848;" routerLink="/{{requestedfirend.id}}/member/profile">{{requestedfirend?.name}} <i class="fas fa-heart" style="color: red;"></i></a></h5>
         <div class="mt-3">
          <span *ngIf="requestedfirend?.gender != null" [ngClass]="requestedfirend?.gender=='f'?'badge-success':''||requestedfirend?.gender=='m'?'badge-info':''||requestedfirend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{requestedfirend?.gender|genderLable:requestedfirend?.gender}}</span>
         </div>
        </div>
  </div>
  </div>

  </div>
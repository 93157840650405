import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Swinger } from '@app/_models/swinger';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    httpOptions = {
        headers: new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json'
        })
    };
    constructor(private http: HttpClient) { }
    handleError: any;

    getAll() {
        return this.http.get<Swinger>(`${environment.apiUrl}/users`).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    getuser() {
        return this.http.get<Swinger>(`${environment.apiUrl}/user`).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    updateUserInfo(data) {
        return this.http.post<Swinger>(`${environment.apiUrl}/update-user-about-info`, data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    updateUserPreferences(data) {
        return this.http.post<Swinger>(`${environment.apiUrl}/update-preferences`, data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    getInterests() {
        return this.http.get<any>(`${environment.apiUrl}/get-all-interests`).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    getUserInterests() {
        return this.http.get<any>(`${environment.apiUrl}/get-user-interests`).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    updateuserLocation(data) {
        console.log(data);
        return this.http.post<Swinger>(`${environment.apiUrl}/update-user-location`, data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    userChangePass(data) {
        console.log(data);
        return this.http.post<any>(`${environment.apiUrl}/change-password`, data).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    // Gey Ip Address
    public getIPAddress() {
        return this.http.get('https://jsonip.com').toPromise();
    }

    userAvodit(body):Observable<any>{
        return this.http.post<any>(`${environment.apiUrl}/add-billingaduit`, body, this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
        )
    }
}
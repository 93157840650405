import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_helpers';
import { HotdateWrapperComponent } from './hotdate-wrapper/hotdate-wrapper.component';
import { HotdatesComponent } from './hotdates/hotdates.component';
import { SwingerHotdatesComponent } from './swinger-hotdates/swinger-hotdates.component';

const routes: Routes = [
  {
    path: 'hot-dates',
    component: HotdateWrapperComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'hot-date-list', component: HotdatesComponent },
      { path: 'member-hot-dates', component: SwingerHotdatesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotDatesRoutingModule { }

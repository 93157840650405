<app-friend-navigation></app-friend-navigation>
<div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header bg-dark">
    <h6 style="color: #ffffff;" class="mb-0">Block Friends</h6>
  </div>
  <div class="card-body over_flow_wrapper_big" *ngIf="!freeuser">
    <div class="row">
      <div class="col-md-4" *ngFor="let friend of blockFriends">
        <app-friend-list [friend]="friend"></app-friend-list>
      </div>
    </div>
    <div class="row mt-4" *ngIf="!freeuser && pagelarg">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
    <app-skoltan-loader *ngIf="loading"></app-skoltan-loader>
    <p *ngIf="dataNotFound" class="text-center" style="color: #484848;"> There is no friend block.</p>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>
</div>


<div class="firend-navigation mt-2 mb-2">
  <div class="container">
    <div class="row">
        <div class="col-md-12 p-0">
            <ul class="nav">
                <li class="nav-item">
                  <a class="btn btn-light " routerLink="/{{swingersIDs}}/member/events"  routerLinkActive="active"><i class="fa fa-calendar"></i> Events of {{UserName}}</a>
                </li>
                <li class="nav-item">
                  <a class="btn btn-light me-4" routerLink="/{{swingersIDs}}/member/members-events" routerLinkActive="active"><i class="fa fa-calendar"></i> Members Events</a>
                </li>
              </ul>
        </div>
    </div>
</div>
</div>
<div class="row">
  <div class="col-md-12 col-lg-5">
    <full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
  </div>
  <div class="col-md-12 col-lg-7">
    <!-- <h3 class="text-light">
      Events Details
      <div class="clearfix"></div>
  </h3> -->
    <div class="table-responsive">
      <table class="table table-dark table-striped">
        <thead style="background-color: #ffc107;">
          <tr>
            <th class="text-center">Sr.No</th>
            <th>Title</th>
            <th>Tagline</th>
            <th>Details</th>
            <th>Location</th>
            <th class="text-center">From</th>
            <th class="text-center">To</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody class="bg-light">
          <tr *ngFor="let eventDetail of eventDateList;let i=index">
            <td class="text-dark text-center">{{i+1}}.</td>
            <td style="max-width:300px;" class="text-dark">
              {{eventDetail?.event_name}}
            </td>
            <td class="text-dark text-left">
              <div class="wrapper">
                <p class="demo-1"> {{eventDetail?.sort_description}}</p>
              </div>
              
            </td>
            
            <td class="text-dark text-left">
              <div class="wrapper">
                <p class="demo-1"> {{eventDetail?.long_description}}</p>
              </div>
              
            </td>
            <td style="max-width:300px;" class="text-dark">
             
              {{eventDetail?.address}}
            </td>
            <td class="text-dark text-center">
              {{eventDetail?.start_date}}
            </td>
            <td class="text-dark text-center">
              {{eventDetail?.end_date}}
            </td>
            <td class="text-dark text-center" >
              <button class="btn btn-sm btn-warning" (click)="dateEventClick(' ',eventDetail.id)">view</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  
  </div>
</div>



<ng-template #eventDetails let-close="close">
    <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
        <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
        <div class="d-flex justify-content-center mb-1 z_2 p-2">
            <h4 class="text-light"><i>{{actionEventData?.event_name}}</i></h4>
        </div>
        <div class="d-flex justify-content-between mt-2 z_2 p-2">
            <span class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light"> {{actionEventData?.start_date}}</span></span>
            <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light"> {{actionEventData?.end_date}}</span></span>
        </div>

    </div>
    <div class="modal-body text-light">
        <p class=" text-light mb-2"><i>Address:</i> {{actionEventData?.address}}</p>
        <p class=" text-light mb-2"><i>Description:</i> {{actionEventData?.sort_description}}</p>
        <p class=" text-light">{{actionEventData?.long_description}}</p>
    </div>
</ng-template>




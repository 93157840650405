<!-- <app-header></app-header>
<section class="defualt_bg">
    <div class="container" >
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center text-light bar_bottom">Contact Us</h1>
            </div>
        </div>
        <div class="row">
         <div class="col-md-12">
       
       <div class="row">
           <div class="col-md-6 col-sm-12">
            <div class="card text-light bg-dark">
                <div class="card-body">
                    <h4 class="text-light">Get in touch</h4>
                    <p class="text-mute">Have an inquiry or some feedback for us? <br />
                    Fill out the form below to contact our team.</p>
                      <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="username">Name</label>
                                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">First name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="subject">Subject</label>
                                    <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                                    <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject.errors.required">Last name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="message">Message</label>
                                    <textarea formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" > </textarea>
                                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Last name is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        <button [disabled]="loading" class="btn btn-warning border-radius-0">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Get in touch
                        </button>
                    </form>
                </div>
            </div>
           </div>

           <div class="col-md-6">
               <div class="img-contact-wrapper">
                  <img src="{{bannerImage}}" class="img-fluid" /> 
               </div>
           </div>
           
       </div>
  
  
</div>
</div>
        </div>

</section> -->

<!-- <app-header></app-header> -->

<section  style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
      <div class="row " >
          <div class="col-lg-7 col-md-6 col-sm-12">
           </div>
           <div class="col-lg-5 col-md-6 col-sm-12" >
            <div class="card text-light" style="background-color: transparent; box-shadow: none;border: none;">
                <div class="">
                    <div class="logo" routerLink="['/']">
                        <img src="../../assets/images/logo.png">
                    </div>
                    <div >
                        <h4 class="text-light">Get in touch</h4>
                        <p class="text-light">Have an inquiry or some feedback for us? <br />
                        Fill out the form below to contact our team.</p>
                          <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <!-- <label for="username">Name</label> -->
                                        <input type="text" formControlName="name" placeholder="Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">First name is required</div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <!-- <label for="email">Email</label> -->
                                        <input type="text" formControlName="email" placeholder="Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <!-- <label for="phone">Phone</label> -->
                                        <input type="text" formControlName="phone" placeholder="Phone (optional)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <!-- <label for="subject">Subject</label> -->
                                        <input type="text" formControlName="subject" placeholder="Subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                            <div *ngIf="f.subject.errors.required">Last name is required</div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <!-- <label for="message">Message</label> -->
                                        <textarea formControlName="message" placeholder="Message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" > </textarea>
                                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                            <div *ngIf="f.message.errors.required">Last name is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                            <button [disabled]="loading" class="btn btn-warning border-radius-0">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
             
           </div>
  
      </div>
    
  </div>
  </section>
    


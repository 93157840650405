<div *ngIf="emailStatus" class="user-navbar" #userNavds>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/members" routerLinkActive="active">Members</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/friends" routerLinkActive="active">Friends</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/create-album" routerLinkActive="active">photo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/upload-videos" routerLinkActive="active">Video</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/upload-audio" routerLinkActive="active">Audio</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/hot-dates/member-hot-dates" routerLinkActive="active">Hot dates</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/event-dates/member-event-dates" routerLinkActive="active">Events</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/message" routerLinkActive="active">Message</a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>
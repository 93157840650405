import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CommonService, FriendsService, SwingersService } from '@app/_services';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-swinger-friends',
  templateUrl: './swinger-friends.component.html',
  styleUrls: ['./swinger-friends.component.css']
})

export class SwingerFriendsComponent implements OnInit {
  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  contentNotFound = false;
  pagination: any;
  checkpages = false;

  constructor(
    private friendsService: FriendsService,
    private swingerService: SwingersService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private Title: Title
  ) {
    this.Title.setTitle('ChatnHookUP Friend | ChatnHookUP')
  }
  swingers: any[] = [];
  SwingersIsd: any;
  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.SwingersIsd = params.swingerId;
      this.getSwingerFriends(params.swingerId);
    });
  }
  nodatas = false;
  
  getSwingerFriends(id: any) {
    this.loading = true;
    this.swingerService.getAllFriendsBySwingerId(id).subscribe(res => {
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4 ? true : false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.loading = false;
      this.swingers = res.data.data;
      if (this.swingers.length == 0) {
        this.nodatas = true;
      }
    }, error => {
      this.loading = false;
    });
  }


  activePage(url: string) {
    if (url) {
      this.commonService.getPagination(url).subscribe(res => {
        this.pagination = res.data;
        this.checkpages = this.pagination.links.length >= 4 ? true : false;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        this.loading = false;
        this.swingers = res.data.data;
      }, error => {
        this.loading = false;
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name: filterValue,
      friend_id: this.SwingersIsd
    }
    this.swingerService.getAllFriendsBySearch(body).subscribe(res => {
      this.swingers = null;
      this.loading = false;
      this.swingers = res.data.data;
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for (const swinger in this.swingers) {
        this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].is_pic}` : this.defaultProfile;
      }
      if (this.swingers.length == 0) {
        this.contentNotFound = true;
      } else {
        this.contentNotFound = false;
      }
    })
  }


}

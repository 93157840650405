<div *ngIf='!uiHide'>
  <div class="card text-center">
    <div class="col-md-12">
      <div class="user_profile_ls"><img routerLink="/{{friend?.id}}/member/profile"
          [src]="(friend.profile == null)?defaultProfile:profilePic" alt="user-profile" class="img-fluid">
          <img src="../../../assets//images/verified.png" class="verifyImage" data-toggle="tooltip" data-placement="top" title="Verified profile" >
        </div>
    </div>
    <div class="col-md-12">

      <div class=" mb-4">
        <h5 style="color: #484848;"><a style="color: #484848;" routerLink="/{{friend?.id}}/member/profile">{{friend.name}} <i class="fas fa-heart" style="color: red;"></i></a></h5>
        <span *ngIf="friend?.gender != null"
          [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
          class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="btn-group mb-4">
        <button type="button" class="btn btn-danger" (click)="removeFavourite(friend.id)">
          <div *ngIf="blockloading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
          </div> Remove
        </button>
      </div>
    </div>
  </div>
</div>
import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    loading = false;
    users: User[];

    constructor(private userService: UserService, private Title:Title) {
        this.Title.setTitle('Home | Chatnhookup')
     }

    ngOnInit() {
    }
    
}
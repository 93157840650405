<div *ngIf="!uiHide" class="card bg-light">
  <div class="text-center">
    <div class="col-12 mb-2">
      <div class="dropdown">
        <i class="fas fa-ellipsis-v vermenu "></i>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li *ngIf="swinger.friendStatus == 'not firends'">
            <a class="dropdown-item"  (click)='sendFriendRequest(swinger.id)'>
              <div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div> <i *ngIf='!requestLoding' class="fas fa-user-plus"></i> Sent Friend Request
            </a>
          </li>
          <li *ngIf="swinger.friendStatus == 'not firends'">
            <a class="dropdown-item" data-toggle="tooltip" data-placement="top" title="Send Message"
              (click)="openAddNewHotdateModal(swinger.id,swinger.name, swinger?.gender, swinger?.profile)"><i
                class="fa fa-comments"></i> Message</a>
          </li>
          <li *ngIf="swinger.friendStatus == 'not firends'">
            <a class="dropdown-item" data-toggle="tooltip" data-placement="top" title="View profile"
              (click)="setProfileViews(swinger?.id)"><i class="fas fa-eye"></i>  Profile</a>
          </li>

          <li *ngIf="swinger.friendStatus != 'not firends'">

            <a disabled *ngIf="swinger?.friendStatus == 'send firends request'" class="dropdown-item">
              <p style="margin-bottom: 0px;color: #484848;"><i class="fas fa-user-check"></i> Sent</p>
            </a>
            <a *ngIf="swinger?.friendStatus == 'send firends request'" class="dropdown-item" data-toggle="tooltip"
              data-placement="top" title="Cancel Friend Request" (click)="cancelRequest(swinger.id,swinger.name)">
              <div *ngIf='btnloading' class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
              <i *ngIf='!btnloading' class="fas fa-user-times" style="font-size: 14px;"></i> Cancel request
            </a>
          </li>
          <li *ngIf="swinger.friendStatus != 'not firends'">
            <a class="dropdown-item" readonly *ngIf="swinger?.friendStatus == 'Block'"> Blocked</a>
            <a class="dropdown-item" readonly *ngIf="swinger?.friendStatus == 'firends'"> Friends</a>
          </li>
        </ul>
      </div>
      <div class="mt-4">
        <div class="user_profile_ls mt-3">
          <img (click)="userValidnav(swinger?.id)" [src]="swinger?.is_pic"
            onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img">
          <img src="../../../assets//images/verified.png" *ngIf="swinger.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
            title="Verified profile">
        </div>
      </div>

    </div>
    <div>
      <div class="col-12 mb-2">
        <div class="text-center">
          <h5 class="card-title mb-0 mt-3"><a (click)="userValidnav(swinger?.id)" style="color: #484848;"
              (click)="setProfileViews(swinger?.id)">{{swinger?.name}} <i class="fas fa-heart"
                style="color: red;"></i></a></h5>
          <div class="mt-3">
            <span *ngIf="swinger?.gender != null"
              [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
              class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 mb-2 mt-2 text-center">
        <p *ngIf="successMsg" class="text-small text-success">{{swingerStatus}}</p>
      </div>
    </div>
  </div>
</div>




<ng-template #SentMessageModalContent let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-md-12">

        <form [formGroup]="message">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>

          <div class="form-group">
            <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess"
              placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
            <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary"
              (click)="onSubmit()">
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
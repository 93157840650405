<div class="card text-light mb-3 mt-2">
  <div class="card-header bg-dark">
    <h6>Change Password</h6>
  </div>
  <div class="card-body bg-light">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="userChangePass" (ngSubmit)="onSubmit()">
          <input type="text" formControlName="userID" class="form-control d-none" readonly />
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="sortTagline">Name</label>
                <input type="text" formControlName="name" class="form-control" readonly />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="sortTagline">Email</label>
                <input type="email" formControlName="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required.</div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="password">Password</label>
                <input [type]="hide ? 'password' : 'text'" placeholder="Password"
                autocomplete="false" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <span class="showhide">
                  <a (click)="hide = !hide"> <i class="fas fa-eye-slash" *ngIf="hide"></i>
                    <i class="fas fa-eye" *ngIf="!hide"></i></a>
                </span>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required.</div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="confirm">Confirm Password</label>
                <input [type]="hides ? 'password' : 'text'" placeholder="Confirm Password"
                autocomplete="false" formControlName="confirm" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.confirm.errors }" />
                <span class="showhide">
                  <a (click)="hides = !hides"> <i class="fas fa-eye-slash" *ngIf="hides"></i>
                    <i class="fas fa-eye" *ngIf="!hides"></i></a>
                </span>
                <div *ngIf="notSame" style="color: rgb(255, 255, 255); font-size: 12px;">Confirm Password didn't Match.</div>
              </div>
            </div>
          </div>

          <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i>
            {{message}}</div>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
          <button [disabled]="loading" class="btn btn-success">
            <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
              <span class="visually-hidden"></span>
            </div> Update
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
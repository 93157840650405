<!-- <app-user-gallery-navigation></app-user-gallery-navigation> -->
<div class="card text-light mb-3 mt-2">
    <div class="card-header  bg-dark text-left">
        <h6><span class="hoverPointer">Audio</span> <span (click)="openCreateAlbumModal()"
                style="float: right; color:#ffffff;cursor: pointer;"><i class="fas fa-plus" style="color:#ffffff"></i> Add Audio</span></h6>
    </div>
    <div class="card-body  bg-light" *ngIf="!freeuser">
        <div class="row" id="wrapper">
            <div *ngIf="albumLoading">
                <div class="spinner-border text-light mt-4" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <div class="col-md-12 mb-2 pl-1 pr-1">
                <div class="row">
                    <div class="col-md-6" *ngFor="let audio of audiofiles; let i=index">
                        <div class="card mb-2 bg-light paddin">
                            <div class="text-center">
                                <div class="top_wrapper">
                                    <div class='video_views_wrapper'>
                                        <span style="color:#484848" (click)="audioLike(audio.id,i)"><i class="fas fa-thumbs-up like"></i>
                                            {{audio?.totallikes}}</span>
                                        <span style="color:#484848"><i class="fas fa-eye"></i> {{audio?.totalviews}}</span>
                                    </div>
                                    <div [ngSwitch]="audio.privacy" class='privacy-status edit_mode_item_r'>

                                        <span (click)="deleteVideo(audio.id, i);"><i
                                                class="fas fa-trash-alt" style="padding: 5px"></i></span>
                                        <i *ngSwitchCase="2" (click)="privacyEditMode(i);"
                                            class="fas fa-user-friends"></i>
                                        <i *ngSwitchCase="3" (click)="privacyEditMode(i);" class="fas fa-lock" ></i>
                                        <i *ngSwitchCase="1" (click)="privacyEditMode(i);" class="fas fa-globe" style="padding: 5px"></i>
                                    </div>
                                    <div *ngIf="audio.loadingPrivacyStatus"
                                        class="spinner-grow spinner-grow-sm staus_grow" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>


                                    <div *ngIf="audio.deleteLoadingStatus"
                                        class="spinner-grow spinner-grow-sm staus_grow_delete" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div><br>
                                <div *ngIf="audio.privacyMode" class="privacy_mode_wrapper">
                                    <ul>
                                        <li (click)="changeAudioPrivacy(audio.id,1,i)"><i class="fas fa-globe"></i>
                                            Public</li>
                                        <li (click)="changeAudioPrivacy(audio.id,2,i)"><i
                                                class="fas fa-user-friends"></i> Friends
                                        </li>
                                        <li (click)="changeAudioPrivacy(audio.id,3,i)"><i class="fas fa-lock"></i> Only
                                            me</li>
                                    </ul>
                                </div>

                                <audio #audio (play)="onPaly(audio, audio.id,i)" style="outline:none; width: 100%;" controls controlsList="nodownload">
                                    <source  [src]="audio?.src" type="audio/mp3">
                                </audio>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="!freeuser" class="row mt-4">
                <div class="col-md-12 text-center" *ngIf="checkpages">
                    <nav aria-label="..." class="text-center">
                        <ul class="pagination justify-content-center">
                            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
                                [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                                    [innerText]="link.label"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>

            
        </div>

        <div *ngIf="nodatas" class="text-center">
            <p style="color: #484848;">There is no audio.</p>
        </div>
        
        <div *ngIf="albumStatus" class="text-center">
            <div class="spinner-border text-light mt-4" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="modal animate__animated animate__zoomIn" *ngIf="openModel"
                    [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
                    <div class="modal-dialog ">
                        <div class="modal-content">
                            <div class="modal-header bg-dark">
                                <h5 class="modal-title" id="exampleModalLabel">{{albumModalHeading}}</h5>

                                <button type="button" class="btn-close" (click)="closeCreateAlbumModal();"
                                    data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times" style="color: white;"></i></button>
                            </div>
                            <div class="modal-body bg-light">
                                <div class="progress form-group" *ngIf="progress > 0">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
                                    </div>
                                    <div class='count-progressbar bg-dark text-center text-light'>{{progress}} %</div>
                                </div>

                                <form [formGroup]="audio" (ngSubmit)="storeAudio()">


                                    <div class="form-group" *ngIf="!albumUpdateStatus">
                                        <label for="location"></label>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label style="color: #484848;">Select privacy</label>
                                                <select class="form-control" formControlName="privacy">
                                                    <option disabled value="0" selected>Select Privacy</option>
                                                    <option value="1">Public</option>
                                                    <option value="2">Show only Friends</option>
                                                    <option value="3">Only Me</option>
                                                </select>
                                            </div>
                                        </div>
                                        <small *ngIf="!albumUpdateStatus" style="color: #484848;">What type Swinger you want to show
                                            Audio</small>

                                        <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                                            <div *ngIf="f.privacy.errors.required" style="color: #484848;">Type is required.</div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input type="file" #audioImages (change)="loadaudio(audioImages.files)"
                                            accept="audio/*" class="form-control d-none" formControlName="audio" />
                                        <div *ngIf="submitted && f.audio.errors" class="invalid-feedback">
                                            <div *ngIf="f.audio.errors.required" style="color: #484848;">Audio's is required</div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 over_flow">
                                        <div class="col-md-3">
                                            <div class="create_album_bx text-center mb-3"
                                                (click)="audioImages.click();">
                                               <img src="../../../../assets/images/aud.jpg" class="uploadimg">
                                            </div>
                                        </div>
                                        <div class="col-md-3" *ngFor="let image of gallerAudio; let i =index">
                                            <!-- <i class="fas fa-trash-alt customes" (click)="deleteone(i)"></i> -->
                                            <img style="margin-bottom:0px" src=""
                                                onerror="this.src='../../../assets/images/audio-file-icon.png';"
                                                class="img-fluid gallery_image_rs">
                                            <p style="font-size: 12px;line-height: 14px; color: #484848;">{{image}}</p>

                                        </div>
                                    </div>


                                    <div *ngIf="message" class="alert alert-success" role="alert"><i
                                            class="fas fa-check-circle"></i>{{message}}</div>
                                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                                    <p style="font-size: 12px;color: #ccc;"><i><span style="color: red;">*</span><span style="color: #484848;">
                                                Please attach 1 audio's at a time. Maximum size of photo's 50 MB
                                                only.</span></i></p>
                                    <button [disabled]="loading" class="btn btn-success">
                                        <div *ngIf="loading" class="spinner-border text-light spinner-border-sm"
                                            role="status">
                                            <span class="visually-hidden"></span>
                                        </div> <span *ngIf="!loading">Submit</span>
                                        <span *ngIf="loading">Please Wait</span>
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4" *ngIf="freeuser">
        <app-subscribe-plan></app-subscribe-plan>
    </div>

</div>
<!-- <app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark set_outline">
                    <div class="card-body">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class=""
    style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
        <div class="row ">
            <div class="col-lg-7 col-md-6 col-sm-12">
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <section class="">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card text-light"
                                    style="background-color: transparent; box-shadow: none;border: none;">
                                    <div class="">
                                        <div class="logo">
                                            <img src="../../assets/images/logo.png">
                                        </div>
                                        <form [formGroup]="EmailVerifyForm" (ngSubmit)="onSubmit()">
                                            <div class="form-group mt-4">
                                                <label for="email">Please enter your registered email id. We will send you an verification email
                                                    to reset your password.</label>
                                                <input type="text" formControlName="email" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email address is required</div>
                                                </div>
                                            </div>
                                           <div class="text-right">
                                            <button [disabled]="loading" class="btn btn-success">
                                                <span *ngIf="loading"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                                Submitt
                                            </button>
                                           </div>
                                        </form>
                                        <div class="text-center" style="margin-top: 8em;">
                                           <a  class="text-center" routerLink="/" style="color: white;text-decoration: underline;"><i class="far fa-hand-point-right"></i> Click here to Sign in / Sign up </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

  <div class="table-responsive table-responsive-sm">
    <table class="table table-bordered ">
      <thead class="text-center" style="background-color: #ffc107;">
        <tr class="text-center">
          <th>Sr.No</th>
          <th>Title</th>
          <th>Description</th>
          <th>Location</th>
          <th>From</th>
          <th>To</th>
          <th>Action</th>
        </tr>
      </thead>
  
      <tbody class="bg-light text-dark text-center">
        <tr *ngFor="let hotdate of hotdateList;let i=index" class="text-center">
          <td >{{i+1}}.</td>
          <td  class="text-dark">
            {{hotdate?.sort_description}}
          </td>
          <td class="text-dark text-left">
            <div class="wrapper">
              <p class="demo-1">{{hotdate?.long_description}}</p>
            </div>
            
          </td>
          <td  class="text-dark address">
            {{hotdate?.address}}
          </td>
          <td class="text-dark ">
            {{hotdate?.start_date}}
          </td>
          <td class="text-dark ">
            {{hotdate?.end_date}}
          </td>
          <td class="text-dark ">
            
            <!-- <button class="btn btn-sm btn-success " (click)="openupdateHotDateModal(i, hotdate?.id)"> -->
                <i data-toggle="tooltip" data-placement="top" title="Edit" (click)="openupdateHotDateModal(i, hotdate?.id)" class="fas fa-edit mr-2" style="color: tomato;"></i>
            <!-- </button> -->

            <!-- <button class="btn btn-sm btn-danger ml-1" (click)="deleteHotDate(i,hotdate?.id)"> -->
             <i data-toggle="tooltip" data-placement="top" title="Delete" (click)="deleteHotDate(i,hotdate?.id)" class="fas fa-trash-alt" style="color: darkgray;"></i>
            <!-- </button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>


    <!-- update hotdates -->

    <ng-template #updateHotDate  let-close="close">
      <div class="modal-header bg-dark" >
        <h5 class="modal-title" style="color: white;">Update Hot Date</h5>
        <button type="button" class="close" style="outline: none;opacity: 1; color: white;" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-light" >
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="addHotdateEventForm" (ngSubmit)="updateHotDates(hotDateUpdateIndex, hotdateAddress)">
              <div class="mb-3">
                <input type="text" class="form-control" formControlName="sort_description" id="sort_discription" placeholder="Hot Date Name">
              </div>
  
             <div *ngIf="addressStatus">
              <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
            </div>
              <div *ngIf="!addressStatus">
               <label>Current Address : </label><span class="text-success ml-2" style="font-size:20px" (click)="updateAddress();"><i class="fas fa-pen-square"></i></span>
               <h6 class="text-dark">{{hotdateAddress}}</h6>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  formControlName="start_date"
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="Start Date"
                  [options]="options"
                />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                  class="form-control"
                  type="text"
                  formControlName="end_date"
                  mwlFlatpickr
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="End Date"
                  [options]="options"
                />
                 
                </div>
              </div>
            </div>
              
            <div class="mb-3">
              <textarea type="text" class="form-control" formControlName="long_description" id="log_discription" placeholder="Discription"> </textarea>
            </div>
            
          <div class="mb-3">
          <p class="text-right">
            <button type="submit" class="btn btn-warning" [disabled]="!addHotdateEventForm.valid">
              <span *ngIf="addEventLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="!addEventLoader">Update Hot Date</span>
              <span *ngIf="addEventLoader">Please Wait</span>
              </button>
          </p>
            </div>
          </form>
      </div>
    </div>
      </div>
    </ng-template>
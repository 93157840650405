
            <div class="card top_form ">
                <div class="card-body">
                    <h2 class="form_heading mb-2"><i>Chatnhookup</i> </h2>
                        <h2 class="form_heading  mb-2" style="font-size: 25px;"><span>Turning fantasies into reality!</span></h2>
                        <p class="h5 mb-4 light-text">Meet sexy singles & couples near you..!!</p>
                    <form [formGroup]="lookingForm" (ngSubmit)="lookingMembers()">
                     
                        <div class="form-group mb-1">
                           
                            <div class="form-group">
                                <label for="username">You're Looking for...</label>
                                <select formControlName="first_gender"  class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.first_gender.errors }">
                                    <option selected >I am</option>
                                    <option *ngFor="let gender of genders" >{{gender}}</option>
                                </select>
                                <div *ngIf="submitted && f.first_gender.errors" class="invalid-feedback">
                                    <div *ngIf="f.first_gender.errors.required">You're Looking for is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div id="looking_for" class="form-text">What are you looking for?</div>
                            <select formControlName="second_gender"  class="form-control " [ngClass]="{ 'is-invalid': submitted && f.second_gender.errors }" aria-describedby="looking_for">
                                <option selected>Looking for...</option>
                                <option *ngFor="let gender of genders" >{{gender}}</option>
                            </select>
                            <div *ngIf="submitted && f.second_gender.errors" class="invalid-feedback">
                                <div *ngIf="f.second_gender.errors.required">What are you looking for is required?</div>
                            </div>
                            
                        </div>

                        <div class="row">
                          
                            <div class="col-md-6">
                                
                                <div class="form-group">
                                    <label>Age</label>
                                    <select formControlName="distanceFrom" [ngClass]="{ 'is-invalid': submitted && f.distanceFrom.errors }"  class="form-control ">
                                        <option  selected>From</option>
                                        <option *ngFor="let km of distanceInKm" >{{km}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.distanceFrom.errors" class="invalid-feedback">
                                        <div *ngIf="f.distanceFrom.errors.required">Age from is required?</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label></label>
                                    <select formControlName="distanceTo"  [ngClass]="{ 'is-invalid': submitted && f.distanceTo.errors }"   class="form-control ">
                                        <option  selected>To</option>
                                        <option *ngFor="let km of distanceInKm" >{{km}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.distanceTo.errors" class="invalid-feedback">
                                        <div *ngIf="f.distanceTo.errors.required">Age to is required?</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
                        </div>
                      
                        <div class="form-group mt-4">
                            <select formControlName="distanceMiles" [ngClass]="{ 'is-invalid': submitted && f.distanceMiles.errors }"  class="form-control" >
                                <option  selected>Distance in miles</option>
                                <option *ngFor="let distance of distances" >{{distance}}</option>
                            </select>
                            <div *ngIf="submitted && f.distanceMiles.errors" class="invalid-feedback">
                                <div *ngIf="f.distanceMiles.errors.required">Distance in miles is required</div>
                            </div>
                        </div>

                        <button [disabled]="loading" class="btn btn-danger mt-2">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                           <span *ngIf="!loading"> Search</span>
                           <span *ngIf="loading"> Please Wait</span>
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </form>
                </div>
            </div>
    
       
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, SwingersService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  pagination: any;
  currentUser: any;
  freeuser = false;
  constructor(private swingersService: SwingersService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private titleService:Title 
    ) {
      this.titleService.setTitle('Matches | ChatnHookUP')
      this.authenticationService.currentUser.subscribe(x => {
         this.currentUser = x;
        // if(this.currentUser.plan_id == 1){
        //   this.freeuser = true;
        // }
    });
	
     }
  swingers: any[] = [];
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;


  ngOnInit(): void {
    this.getMatcheSwingers();
  }

  getMatcheSwingers(){
    this.swingersService.getWhoMatches().pipe(map(res=>{
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(data=>{
       data.with = `width:${data.swingerMatched}${'%'}`
      })
      return res.data.data;
    }))
    .subscribe(data => {
      console.log(data)
      this.swingers = data;
       for (let index = 0; index < this.swingers.length; index++) {
            if(this.swingers[index].user_status == 0){
             this.swingers.splice(index,1)
            }
          }
      this.loading = false;
    });
  }


  activePage(url:string){
    if(url){
    this.commonService.getPagination(url)
    .pipe(map(res=>{
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(data=>{
       data.with = `width:${data.swingerMatched}${'%'}`
      })
      return res.data.data;
    })).subscribe(data => {
      this.swingers = data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      for(let swinger in this.swingers){
        this.swingers[swinger].profile = (this.swingers[swinger].profile) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].profile}`: this.defaultProfile;
      }
      this.loading = false;
    });
  }
}


}

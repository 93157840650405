import { Component, OnInit,AfterViewChecked, HostListener } from '@angular/core';
import { UserMediaService } from '@app/_services/user-media.service';
import { environment } from '@environments/environment';
import { AuthenticationService, FriendsService,ProfileLikeService,ProfileViewService} from '@app/_services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from '@app/_services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-swingers-wrapper',
  templateUrl: './swingers-wrapper.component.html',
  styleUrls: ['./swingers-wrapper.component.css']
})
export class SwingersWrapperComponent implements OnInit,AfterViewChecked {
  clickEventsubscription: Subscription;
  emailStatus = true;
  hidenav: boolean;
  id: number;
  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  requestLoding = false;
  swinger: any;
  swingerId: any;
  male = false;
  female = false;
  c_t_class: string;
  totalLikes: any;
  checkid:any;
  showUserMInfo = true;
  constructor(private userMedia: UserMediaService,
              private route: Router,
              private activeRoute: ActivatedRoute,
              private friendService: FriendsService,
              private profileLike: ProfileLikeService,
              private profileView: ProfileViewService,
              private authenticationService: AuthenticationService,
              private sharedService: SharedService,
              ) {

                
                this.authenticationService.currentUser.subscribe(check => {
                  if(check.email_status!=1){
                  this.emailStatus = false;
                  }
               })
             
              }
  
  @HostListener('window:load', ['$event']) checkWindowWidth() {
    window.innerWidth <= 575.98 ? this.showUserMInfo = false:  this.showUserMInfo = true;
  }

  ngOnInit(): void {
    const currentUrl: string = this.route.url;
    this.swingerId = currentUrl.split('/')[1];
    this.getdetails(this.swingerId);
  }

  ngAfterViewChecked(){
    const currentfri: string = this.route.url;
    const ffID = currentfri.split('?')[1] ? currentfri.split('?')[1] :this.route.url+"?g=0";
    const ffIDs = currentfri.split('=')[1];
    const id = currentfri.split('/')[1];
    const idr = ffID.charAt(0)
    let newvar = 0;
    if(idr == 'd' && newvar ==  0){
      id != this.checkid ? this.getdetails(id): '';
      this.checkid = id; 
      return newvar = 1;  
    }else if(idr == 'f'){
      ffIDs != this.checkid ? this.getdetails(ffIDs): '';
      this.checkid = ffIDs; 
      
    }
    
  }



  sendFriendRequest(firedId: any){
    this.requestLoding = true;
    const data = {
      from_user_id: this.userId,
      to_user_id: firedId
    };
    this.friendService.sendFriendRequest(data).subscribe((res) => {
    this.requestLoding = false;
  });
  }

  getdetails(id: any) {
    this.swinger = null;
    // console.log(id)
    this.userMedia.getSwingerDetails(id).subscribe(res =>{
      // console.log(res)
      res.data.profile = (res.data.profile) ? res.data.profile: this.defaultProfile;
      res.data.cover = (res.data.cover) ? res.data.cover: this.defaultProfile;
      if(res.data.m_drink == '0' || res.data.m_smoke == 0 || res.data.f_drink == 0 || res.data.f_smoke == '0' ){
        res.data.m_drink = 'No';
        res.data.m_smoke = 'No';
        res.data.f_drink = 'No';
        res.data.f_smoke = 'No';
      }else{
        res.data.m_smoke = 'Yes';
        res.data.m_drink = 'Yes';
        res.data.f_smoke = 'Yes';
        res.data.f_drink = 'Yes';
      }

      if(res.data.like_smoke == '0' || res.data.like_drink == '0'){
        res.data.like_smoke = 'No';
        res.data.like_drink = 'No';
        this.c_t_class = 'badge badge-pill badge-danger';
      }else{
        res.data.like_smoke = 'Yes';
        res.data.like_drink = 'Yes';
        this.c_t_class = 'badge badge-pill badge-success';
      }
      this.swinger = res.data;
      // console.log(this.swinger)
    }, error => {
      console.log(error);
    });

}


}
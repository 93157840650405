import { Component, OnInit } from '@angular/core';
import { EventService, HotDateService, SwingersService, UserMediaService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, CommonService } from '@app/_services';
import { environment } from '@environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { CalendarOptions } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import { map } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-members-hot-date',
  templateUrl: './members-hot-date.component.html',
  styleUrls: ['./members-hot-date.component.css']
})
export class MembersHotDateComponent implements OnInit {
  swingersIDs:any;
  
  eventBackground = ['../../../assets/images/date_bg_1.jpg', '../../../assets/images/date_bg_2.jpg',
  '../../../assets/images/date_bg_3.jpg', '../../../assets/images/date_bg_4.jpg', '../../../assets/images/date_bg_5.jpg'];

  setEventBackground: any;
  pagination:any;
	checkpages = false;
  freeuser = false;
  currentUser:any;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    events: [
    ],
    plugins: [ interactionPlugin ],
    eventClick: this.dateEventsClick.bind(this)
  };
  constructor(
    private route: ActivatedRoute,
    private userMedia:UserMediaService,
    private fb: FormBuilder,
    private hotdateServive: HotDateService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private titleservice:Title,
    private modal: NgbModal,
  ) {
    this.titleservice.setTitle('Members Events | ChatnHookUP')
      this.route.parent.params.subscribe( params => {
        this.swingersIDs = params.swingerId;
        this.getDetails(params.swingerId)
      });
  
   }
   @ViewChild('eventDetails', { static: false }) eventDetails: TemplateRef<any>;
   @ViewChild('filterWrapper', { static: false }) filterWrapper: TemplateRef<any>;
 
   filterStatus = false;
 
   defaultProfile = environment.defaultProfile;
   userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
   eventData: any;
   hotdateList: any;
   addHotdateEventForm: FormGroup;
   loading = false;
   totalHotDates: number;
   searchData = false;
   mapShow = false;
 
   options: FlatpickrDefaultsInterface;
  
  UserName:any;
  getDetails(id: any) {
    this.userMedia.getSwingerDetails(id).subscribe(res => {
      this.UserName = res.data.name;
    }, error => {
    });
  }

  ngOnInit(): void {
    this.addHotdateEventForm = this.fb.group({
      address: ['', Validators.required],
      zip_code: [''],
      city: [''],
      couple:[''],
      male:[''],
      female:[''],
      latitude:[''],
      longitude:[''],
      radues:[''],
      start_date:[''],
      end_date: [''],
      sort_description: [''],
      log_description: [''],
      privacy: [''],
      status: [''],
    });
    this.getSwingerHotDates();
    this.getTotalActiveHotDates();
    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };
  }

  getSwingerHotDates(){
    this.loading = true;
   this.hotdateServive.getSwingerHotDates().pipe( map(res =>{
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
    res.data.data.map(hot => {
      hot.start = hot.start_date;
      hot.id = hot.id;
      hot.end = hot.end_date;
      hot.title = hot.sort_description;
      hot.source = hot;
      hot.classNames = `cl_date_${hot.id}`;
     })
    return res.data.data;
  })).subscribe(dates => {
    console.log(dates)
     this.loading = false;
     this.hotdateList = dates;
     this.hotdateList = dates.reverse();
     this.calendarOptions.events = this.hotdateList;
     
   });
  }




  activePage(url:string){
    if(url){
    this.loading = true;
    this.commonService.getPagination(url).pipe( map(res =>{
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';

    return res.data.data;
  })).subscribe(dates => {
     this.loading = false;
     this.hotdateList = dates;
     
   });
    }
  }

    getlatitudeLongitude(mapData: any) {
      this.addHotdateEventForm.get('latitude').setValue(mapData.lat);
      this.addHotdateEventForm.get('longitude').setValue(mapData.lng);
  }

  
  dateEventClick( name: string, id: any){
    this.modal.open(this.eventDetails, { size: 'lg' });
    const eventid = id;
    this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
    this.hotdateServive.getSwingerHotDateById(eventid).subscribe(res => {
      res.data.name = name;
      this.eventData = res.data;
      
    });
  }

  openFilterWrapper(){
    this.filterStatus = !this.filterStatus;
  }

  filterHotDate(){
    this.loading = true;
    this.hotdateServive.searchSwingerHotDate(this.addHotdateEventForm.value).pipe( map(res =>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map( hotdate => {
        hotdate.sw_distance = `${Math.round(hotdate.sw_distance)} K.M `;
        hotdate.image = hotdate.image;
     }); 
      return res.data.data;
    })).subscribe(hotdatedata => {
      this.loading = false;
      this.hotdateList = [];
      this.hotdateList = hotdatedata;
      if(this.hotdateList.length == 0){
        this.searchData = true;
      }else{
        this.searchData = false;
      }
    }, (err) =>{
      this.loading = false;
    });
  }

getTotalActiveHotDates(){
  this.hotdateServive.getTotalActiveHotDate().subscribe(res => {
    this.totalHotDates = res.totalHotDates;
  });

}

handleDateClick(arg) {
}

dateEventsClick(info) {
 let ngbModalOptions: NgbModalOptions = {
   backdrop : 'static',
   keyboard : false,
   size: 'lg'
};
 const eventid =  info.event.id;
 this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
 this.hotdateServive.getHotDateById(eventid).subscribe(res => {
   this.eventData = res.data;
   this.modal.open(this.eventDetails, ngbModalOptions);
 });
 info.el.style.borderColor = '#696666';
 info.el.style.backgroundColor = '#6b796e';
}

}


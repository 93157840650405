import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {
     emailStatus = true;

     @ViewChild('userNavds') userNavds : ElementRef;
     constructor(
      private authenticationService: AuthenticationService,
      private renderer: Renderer2
      ) { 

      this.authenticationService.currentUser.subscribe(x => {
        
        if(x.email_status!=1){
          this.emailStatus = false;
        }
      })

     }

  ngOnInit(): void {
  }

  scrollTopUserNav(event:any){
   console.log(event);
  }



  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
     if(window.pageYOffset > 250){
      this.renderer.addClass(this.userNavds.nativeElement,'fiexd-user-nav');
     }else{
      this.renderer.removeClass(this.userNavds.nativeElement,'fiexd-user-nav');
     }
 }

}

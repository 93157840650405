import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, NgZone, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, SwingersService } from '@app/_services';
import { MessagesService } from "../../_services/messages.service";
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Message } from "../../_models/chat";
import { Title } from "@angular/platform-browser";
import { UserMediaService } from '../../_services/user-media.service'

@Component({
  selector: 'message',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})

export class InboxComponent implements OnInit {
  socket: any;
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  loading = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  userCurrentrName = JSON.parse(sessionStorage.getItem('currentUser')).name;
  userCurrentrProfile: any;
  submitted = false;
  @ViewChild('mobilenavWr') elRef: ElementRef;
  @ViewChild('userNavds') userNavds: ElementRef;
  message: FormGroup;
  messagepopup: FormGroup;
  userIds: any;
  UserName: any;
  UsermsgID: any;
  swingersData: any = [];
  senderIDs;
  notselectusers = false;
  loadingform = false;
  timer = timer(5000, 8000);
  allmessages: Message[];
  shareData: any
  nodata = true;
  userdata: any;
  loadingmsg = false;
  currentUser: any;
  freeuser = false;


  constructor(
   private lc: NgZone,
    private swingersService: SwingersService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private MessagesService: MessagesService,
    private authenticationService: AuthenticationService,
    private Title: Title,
    private userMedia: UserMediaService,
  ) {
    this.Title.setTitle('Message | ChatnHookUP')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });

    this.route.queryParams.subscribe(params => {
      const ids = params['mid'];
      this.MessangerId = params['uid'];
      if (ids == undefined || ids == null || ids == '') {
        this.notselectusers = true;

        return;
      } else {
        this.getusermsgprof()
        this.senderIDs = ids;
        this.getUserMessage(this.senderIDs);
        
      }
    })
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('new-message', (data: any) => {
      if (data) {
        setTimeout(() => {
          this.getusermsgprof()
          this.getUserMessage(this.senderIDs)

        }, 100);
      }
    });

    this.toggled = false;
    this.userMedia.getProfile().subscribe(res => {
      this.userCurrentrProfile = (res.data.profile) ? res.data.profile : this.userCurrentrProfile;
    });
  }

  ngOnInit(): void {
    this.getallmsg();
    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });

    this.messagepopup = this.formBuilder.group({
      messagesss: ['', Validators.required],
    });

  }

  messages;
  pagination = {
    currentPage: 0,
    totalPages: 100,
    itemsPerPage: 10
  }


  lastScrollTop: number = 1;
  messsagecount:number = 5;
  limit:number =  5;
  direction: string = "";
  onScroll(event: any) {
    const startingScrollHeight = event.target.scrollHeight;
      if (event.target.offsetHeight + event.target.scrollTop == startingScrollHeight) {
          let st = window.pageYOffset;
          this.limit =  this.messsagecount * ++this.lastScrollTop;
          this.getUserMessage(this.senderIDs)
           return false;
      }
  }


  toggled: boolean;
  messagess: String = "";
  handleSelection(event) {
    this.messagess = this.messagess + "" + event.char;
    // this.toggled = false;
  }

  applyFilters(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
  }


  showmessageusers(receiver_id1, UserIDs) {
    this.senderIDs = receiver_id1;
    this.MessangerId = UserIDs;
    this.getUserMessage(receiver_id1);
    this.router.navigate(['/dashboard/message'], { queryParams: { mid: receiver_id1, uid: UserIDs } });
  }

  userblock = true;
  today: number = Date.now();
  getUserMessage(id: any) {
    let data = {
      page:"0",
      limit:this.limit,
      receiverIDs:id
    }
    this.MessagesService.allmessagesuserPag(data).then((res: any) => {
      this.notselectusers = false;
        this.nodata = false;
      if (res.length != 0) {
        this.allmessages = res;
        this.messageSeensender(this.userId)
        
      } else {
        this.notselectusers = false;
        this.nodata = false;
        this.allmessages = null
      }
      return;
    }).catch((err) => {
      console.log(err)
    })
  }
  trackById(index: number, item:any) {
    return item.id
}


  messageSeensender(id: any) {
    this.MessagesService.updaemsgseen(id).then((res) => {
    }).catch((err) => { console.log(err) })
  }

  trackHero(index, data) {
    return data ? data.id : undefined;
  }

  ReceiverName: any;
  ReciverID: any;
  ReciverPrifile: any;
  ReciverGender: any;
  blockStatus: any
  selectUser(name, id, profile, gender, user_status) {
    this.blockStatus = user_status
    this.ReceiverName = name;
    this.ReciverID = id;
    this.ReciverPrifile = profile;
    this.ReciverGender = gender;
    this.UserName = name;
    this.senderIDs = id;
    this.swingersData = null;
  }

  MessangerData: any;
  MessangerId: any;
  getusermsgprof() {
    this.MessagesService.getuserMessages(this.MessangerId).then((res: any) => {
      if (res.length != 0) {
        this.MessangerData = res;
      }
      return;
    }).catch((err) => {
      console.log(err)
    })
  }
  get f() { return this.message.controls; }
  addEmoji(event:any){
    let data = this.message.get('messagess');
    data.patchValue(data.value + event.emoji.native)
  }
 
  onSubmit() {
    this.submitted = true;
    if (this.message.invalid) {
      return;
    }
    const body = {
      datTime:  Date.now(),
      message: this.message.value.messagess,
      sender: this.userId,
      receiver: this.MessangerId
    }
    console.log(body)
    this.MessagesService.sendmessages(body).subscribe((res) => {
      console.log(res)
      this.message.reset();
      let body = {
        ststusUser: 'online'
      }
      this.MessagesService.userStatusTyping(this.userId, body).then((res) => {
      }).catch((err) => {
        console.log(err)
      })
      this.socket.emit('save-message',)
    })

    this.socket.emit('my message', body);
  }

  checkNav = 0;
  mobileNav(event: any) {
    if (this.checkNav == 0) {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
      this.renderer.addClass(this.elRef.nativeElement, "nav-show");
      this.checkNav = 1;
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
      this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
      this.checkNav = 0;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name: filterValue
    }
    this.swingersService.searchswing(body).subscribe(res => {
      this.swingersData = res.data.data
      console.log(this.swingersData)
    })
  }


  messagesData: any;
  countmessages: any;
  isUserdata = true;
  loadingssss = false;
  getallmsg() {
    this.MessagesService.getAllUserInbox(this.userId).then((res: any) => {
      if (res.length != 0) {
        this.messagesData = res;
      }
      return;
    }).catch((err) => {
      console.log(err)
    });
  }

  nodatas = false;
  searchInboxdata(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name: filterValue,
      userID: this.userId
    }
    this.MessagesService.usersearch(body).subscribe(res => {
      this.messagesData = null;
      this.messagesData = res;
      this.nodatas = false;
      if (res.length == 0) {
        this.nodatas = true;
      }

    })
  }
  gotoprofile(user_status, receiver_id1) {
    if (user_status == 0) {
      this.router.navigate([`/user-not-exist`])
    } else {
      this.router.navigate([`${receiver_id1}/member/profile`]);
    }
  }


  deletechates() {
    Swal.fire({
      title: 'Are you sure want to delete all message history?',
      text: 'You will not be able to recover this message record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        let body = {
          sender: this.userId,
          messageID: this.senderIDs
        }
        this.MessagesService.clearAllmessage(body).subscribe(res => {
          Swal.fire(
            'Success',
            `${res.message}`, 'success',

          );
          this.getUserMessage(this.senderIDs);
        }, (error) => {
          Swal.fire(
            'Server Error',
            'Something is wrong please try again.',
            'error'
          );
        });
      }
    });
  }




  // Popup messages
  openAddNewHotdateModal() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }

  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }
  onSubmits() {
    const body = {
      name: this.ReceiverName,
      imgurl: this.ReciverPrifile,
      userId: this.userId,
      sender: this.userId,
      gender: this.ReciverGender,
      receiver: this.ReciverID,
      blockStatus: this.blockStatus
    }
    const Data = {
      name: this.userCurrentrName,
      imgurl: this.userCurrentrProfile,
      userId: this.ReciverID,
      sender: this.ReciverID,
      receiver: this.userId,
      gender: this.ReciverGender,
      blockStatus: 0
    }
    this.MessagesService.inboxsender(body).subscribe((res) => {
      this.socket.emit('save-message');
    })
    this.MessagesService.inboxreceiver(Data).subscribe((res) => {
      this.socket.emit('save-message');
    })
    this.msgsent()
  }


  
  msgsent() {
    const body = {
      datTime: Date.now(),
      message: this.messagepopup.value.messagesss,
      sender: this.userId,
      receiver: this.ReciverID
    }
    this.MessagesService.sendmessages(body).subscribe((res) => {
      this.messagepopup.reset();
      this.closeHotdateModal();
      this.getallmsg();
      this.socket.emit('save-message',)
    })
  }


  count = 1;
  onKeypressEvent(event: any) {
    console.log(event)
    if (this.count == 1) {
      let body = {
        ststusUser: 'typing...'
      }
      this.MessagesService.userStatusTyping(this.userId, body).then((res) => {
      }).catch((err) => {
        console.log(err)
      })
      this.socket.emit('save-message');
      return this.count = 0;
    }


  }
}

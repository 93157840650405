<app-swinger-navigation></app-swinger-navigation>
<div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header text-left bg-dark">
    <table style="width: 100%;">
      <tr>
        <th >
          <h6 style="color: #ffffff;" class="mb-0">Members</h6>
        </th>
        <th >
          <span style="float: right;"><input class="text" (keyup)="applyFilter($event)" placeholder="search.."
            #input>
            <i class="fa fa-search icon"></i></span>
        </th>
      </tr>
    </table>
    
  </div>
  <div class="card-body ">
    <div class="row">
      <app-swinger [swinger]='swinger' class=" mb-4 col-xs-6 col-sm-4 col-md-4" *ngFor="let swinger of swingers; let i=index" ></app-swinger>
    </div>
    <app-skoltan-loader *ngIf="loading && !nodatainsearch"></app-skoltan-loader>
    <div class="card-text text-center" *ngIf="nodatainsearch">
      <h6 style="color: #484848;">Sorry, this user isn't available</h6>
    </div>
    <div class="row mt-4" *ngIf="pagelarg">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    
  </div>

  </div>
</div>





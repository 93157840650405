import { Component, OnInit, Input} from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService} from '@app/_services';
import { Friends } from '@app/_models/friends';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  btnloading = false;
  removeUiBlock = false;
  constructor(private friendService: FriendsService) { }
  requestedFirend: Friends;
  @Input() friend: any;
  profilePic: any;
  
  ngOnInit(): void {
    this.profilePic = this.friend.profile;
  }

  removeFriend(friendId: any){
    console.log(friendId);
    this.btnloading = true;
    const data = {
      "to_user_id": friendId
      };
    this.friendService.cancelSentRequest(data).subscribe(res => {
      // console.log(res);
      this.btnloading = false;
      this.removeUiBlock = true;
    } );
  }


}

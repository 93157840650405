import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService, UserMediaService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "../../_services/messages.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-right-navigation-panel',
  templateUrl: './right-navigation-panel.component.html',
  styleUrls: ['./right-navigation-panel.component.css']
})
export class RightNavigationPanelComponent implements OnInit {
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  userCurrentrName = JSON.parse(sessionStorage.getItem('currentUser')).name;
  userCurrentrProfile: any;
  message: FormGroup;
  loadings = false;
  UseName: any;

  defaultProfile = environment.defaultProfile;
  loading = true;
  constructor(
    private friends: FriendsService,
    private router: Router,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private userMedia: UserMediaService,
    private MessagesService: MessagesService,) { }
  friendsData: any[] = [];
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;

  ngOnInit(): void {
    this.getOnlineFriends();
    this.message = this.formBuilder.group({
      messages: ['', Validators.required],
    });

    this.userMedia.getProfile().subscribe(res => {
      this.userCurrentrProfile = (res.data.profile) ? res.data.profile : this.userCurrentrProfile;
    });
  }
  dataNotFound = false;
  getOnlineFriends() {
    this.friends.getOnlineFriendslist().subscribe(data => {
      // console.log(data)
      this.loading = false;
      this.friendsData = data.data.data;
      if (this.friendsData.length == 0) {
        this.loading = false;
        this.dataNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }
  gotoOnline() {
    this.router.navigate(['/dashboard/user-online'])
  }

  // convenience getter for easy access to form fields
  get f() { return this.message.controls; }
  senderIDs: any;
  MessagesRes: any;
  today: number = Date.now();
  //  onSubmit() {
  //    if (this.message.invalid) {
  //      return;
  //    }
  //    this.loadings = true;
  //    let body = {
  //      to_user_id:  this.senderIDs,
  //      message:this.message.value.messages
  //    }
  //    this.loadings = true;
  //    this.MessagesService.sendmessage(body).subscribe(res=>{
  //      if(res != null){
  //        this.loadings = false;
  //        this.MessagesRes = res.message
  //        this.message.reset();
  //        this.closeHotdateModal();
  //        this.sentMessages(this.senderIDs)
  //      }
  //    }, error => {
  //      this.loadings = false;
  //  });
  //  }
  ReceiverName: any;
  ReciverPrifile: any;
  ReciverGender: any;
  ReciverID: any;
  blockStatus: any;
  onSubmit() {
    const body = {
      name: this.ReceiverName,
      imgurl: this.ReciverPrifile,
      userId: this.userId,
      sender: this.userId,
      gender: this.ReciverGender,
      receiver: this.ReciverID,
      blockStatus: this.blockStatus
    }
    const Data = {
      name: this.userCurrentrName,
      imgurl: this.userCurrentrProfile,
      userId: this.ReciverID,
      sender: this.ReciverID,
      receiver: this.userId,
      gender: this.ReciverGender,
      blockStatus: 0
    }
    this.MessagesService.inboxsender(body).subscribe((res) => {
    })
    this.MessagesService.inboxreceiver(Data).subscribe((res) => {
    })
    this.msgsent()
  }

  msgsent() {
    const body = {
      datTime: this.today,
      message: this.message.value.messages,
      sender: this.userId,
      receiver: this.ReciverID
    }
    this.MessagesService.sendmessages(body).subscribe((res) => {
      // console.log(res)
      this.message.reset();
      this.closeHotdateModal();
      this.sentMessages(this.senderIDs)
      this.router.navigate(['/dashboard/message'], { queryParams: { mid: res.receiverIDs, uid: res.receiver } });
    })
  }
  openAddNewHotdateModal(id, name, gender, profile, user_status) {
    this.blockStatus = user_status
    this.ReceiverName = name;
    this.ReciverPrifile = profile;
    this.ReciverGender = gender;
    this.ReciverID = id;
    this.UseName = name;
    this.senderIDs = id
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }



  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }

  sentMessages(id) {
    this.router.navigate(['/dashboard/message'])
  }
}



<app-dashboard-header></app-dashboard-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark mt-2">
                    <div class="card-body">
                        <div class="imgcontainer">
                            <img class="card-img-top" src="../../../assets/images/avtar-image.jpg" 
                              alt="Card image cap">
                        </div>
                        <div class="text-center">
                            <h5 class="mt-2">Sorry, this user isn't available</h5>
                            <button class="btn btn-primary mt-2" routerLink="/dashboard/members">Back to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="!uiHide" class="card mt-2 mb-2">
    <div class="row text-center">
        <div class="col-12">
            <div class="user_profile_ls">
              <img (click)="reloadWindow(swinger?.id);"[src]="(swinger.is_pic == null)?defaultProfile:swinger.is_pic " alt="user-profile" class="img-fluid">
              <img src="../../../assets//images/verified.png" *ngIf="swinger.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
              title="Paid Members">
            </div>
        </div>
        
            <div class="col-md-12">
              <div class="">
                <h5  style="color: #484848;"><a (click)="reloadWindow(swinger?.id);">{{swinger?.name}}</a></h5>
                <span *ngIf="swinger?.gender=='f'" class="badge badge-pill badge-success mb-2">Female</span>
                <span *ngIf="swinger?.gender=='m'" class="badge badge-pill badge-info mb-2">Male</span>
                <span *ngIf="swinger?.gender=='c'" class="badge badge-pill badge-warning mb-2">Couple</span>
                <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
              </div>
            </div>
            <div class="col-md-12 test-center">
              <p *ngIf="successMsg" class="text-small text-success" style="color: #484848;">{{swingerStatus}}</p>
              <div class="btn-group mt-4 text-center">
              <button *ngIf="swinger?.friend_status == 'not friends'" type="button" class="btn btn-warning" (click)='sendFriendRequest(swinger?.id)'><div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div> <i class="fas fa-user-plus"></i></button>
    
              <span *ngIf="swinger?.friend_status == 'Requested'" class="badge bg-info text-light"> Requested</span>
    
              <span *ngIf="swinger?.friend_status == 'friends'" class="badge bg-success text-light"> Friends</span>
              
              <!-- <a  class="btn btn-info" routerLink="/{{swinger?.id}}/swinger/profile" (click)="setProfileViews(swinger?.id)"><i class="fas fa-eye"></i></a> -->
              </div>
          
        </div>
      </div>
    </div>

<div class="row">
  <div class=" col-md-7  text-light mb-3" *ngIf="!freeuser">
    <div class="bg-dark card-header d-flex justify-content-between">
      <h5 class="mt-1">Hot Dates | <span class="badge badge-warning text-dark">Total Hot Dates: {{totalHotDates}}</span></h5>
      <a class="btn btn-warning btn-sm" style=" margin-bottom: 10px;" (click)="openFilterWrapper();">
        <!-- <i class="fas fa-search"></i> -->
         Search</a>
    </div>
    <div class="search-hot-date-wrapper" *ngIf="filterStatus">
      <!-- <p class="mt-2"><i class="fas fa-search"></i> Search hot dates</p> -->
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="addHotdateEventForm" (ngSubmit)="filterHotDate()">
            <app-search-place [mapShow]="mapShow" (mapData)="getlatitudeLongitude($event)"></app-search-place>
            <!-- <input type="text" class="col-md-3" formControlName="zip_code" id="zipcode" placeholder="Zip code"> -->
            <select class="col-md-4" formControlName="radues" id="city">
              <option value=''>-- Distance --</option>
              <option value="5">5 mi</option>
              <option value="10">10 mi</option>
              <option value="15">15 mi</option>
              <option value="20">20 mi</option>
              <option value="25">25 mi</option>
              <option value="35">35 mi</option>
              <option value="50">50 mi</option>
              <option value="100">100 mi</option>
              <option value="200">200 mi</option>
              <option value="400">400 mi</option>
              <option value="500">500 mi</option>
            </select>
            <input class="col-md-4" type="text" mwlFlatpickr formControlName="start_date" [enableTime]="false"
              dateFormat="Y-m-d" placeholder="Start Date" [options]="options" />

            <input class="col-md-4" type="text" formControlName="end_date" mwlFlatpickr [enableTime]="false"
              dateFormat="Y-m-d" placeholder="End Date" [options]="options" />

            <!-- <div class="row">
              <div class="col-md-12 mt-2">
                <h6>Looking For</h6>
              </div>
              <div class="col-md-4 mt-2">
                <input type="checkbox" formControlName='couple'> Couple (M/F)
              </div>
              <div class="col-md-4 mt-2">
                <input type="checkbox" formControlName='male'> Single Male
              </div>
              <div class="col-md-4 mt-2">
                <input type="checkbox" formControlName='female'> Single Female
              </div>
            </div> -->

            <div class="mb-3">
              <p class="text-right"><button type="submit" class="btn btn-warning btn-sm mt-2">
                <i
                    class="fas fa-search"></i>
                     Search</button></p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body bg-sky over_flow_wrapper_big p-0">
      <!-- end hot date filter -->
      <div *ngIf="searchData" class="text-center mt-4">
        <p class="text-center" style="color: #484848;">Hot Dates not found.</p>
      </div>
      <div *ngIf="loading" class="card-text text-center">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div class="row padd">
        <div class=" mb-2 mt-2 col-md-6 text-center" *ngFor="let hotdate of hotdateList">
          <div class="card" style="text-align: center !important;">
            <div class="col-md-12">
              <div class="user_profile_ls"><img routerLink="/{{hotdate?.user_id}}/member/profile"
                  [src]="(hotdate.image)?(hotdate.image):defaultProfile"
                  onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid">
                  <img src="../../../assets//images/verified.png" *ngIf="hotdate.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
                  title="Verified profile">
              </div>
            </div>
            <div class="col-md-12 text-center">
              <div class="card-body" style="text-align: center;">
                <h5 class="card-title mb-1" style="text-align: center;"><a
                    routerLink="/{{hotdate?.user_id}}/member/profile" style="color: #484848;">{{hotdate?.username}}</a>
                </h5>
                <p class="card-text  mb-0"><span *ngIf="hotdate?.gender != null"
                    [ngClass]="hotdate?.gender=='f'?'badge-success':''||hotdate?.gender=='m'?'badge-info':''||hotdate?.gender=='c'?'badge-warning':''"
                    class="badge badge-pill  mb-2">
                    <span *ngIf="hotdate?.gender == 'm'">Male</span>
                    <span *ngIf="hotdate?.gender == 'f'">female</span>
                    <span *ngIf="hotdate?.gender == 'c'">Couple</span>
                  </span></p>
                <p class="card-text  mb-1" style="color: #484848;">From: <span class="badge bg-success text-light"
                    style="color: #484848;"> {{hotdate?.start_date}}</span> to: <span
                    class="badge bg-danger text-light"> {{hotdate?.end_date}}</span></p>
                <p *ngIf="hotdate?.sw_distance" class="mb-0">Distance: <span class="badge bg-info text-light"
                    style="color: #484848;">{{hotdate?.sw_distance}}</span></p>

                <div class="text-center _mt_btn_36">
                  <button class="btn btn-warning hot-date-btn btn-sm"
                    (click)="dateEventClick(hotdate?.username,hotdate?.id)">Get Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #eventDetails let-close="close">
        <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="d-flex justify-content-center mb-1 z_2 p-2">
            <h4 class="text-light"><i>{{eventData.sort_description}}</i></h4>
          </div>
          <div class="d-flex justify-content-between mt-2 z_2 p-2">
            <span class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light">
                {{eventData.start_date}}</span></span>
            <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light">
                {{eventData.end_date}}</span></span>
          </div>

        </div>
        <div class="modal-body text-light">
          <p class=" text-light mb-2"><i>Address:</i> {{eventData.address}}</p>
          <p class=" text-light">{{eventData.long_description}}</p>
        </div>
      </ng-template>
    </div>

    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
  </div>
</div>
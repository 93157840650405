import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Swinger } from '@app/_models/swinger';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwingersService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

  getSwingers(id: any) {
    return this.http.get<Swinger>(`${environment.apiUrl}/swingers`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

 

  getWhoMatches() {
    return this.http.get<Swinger>(`${environment.apiUrl}/get-who-matched`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getWhoViews(){
    return this.http.get<any>(`${environment.apiUrl}/get-who-viewed`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // swinger info view services

  getAllFriendsBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-friend/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllFriendsBySearch(body){
    return this.http.post<any>(`${environment.apiUrl}/search-swinger-friends`,body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getAllEventsBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-event/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllVideosBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-video/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllHotDatesBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-hotdates/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllTimelineBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-timeline/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllAlbumsBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-album/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllGroupsBySwingerId(id: number){
    return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-groups/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  gethottestmem():Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/hottest-member`, this.httpOptions).pipe(
      retry(1),catchError(this.handleError)
    )
  }


  searchswing(body):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/search-swingers`, body, this.httpOptions).pipe(
      retry(1),catchError(this.handleError)
    )
  }


  getintouch(body):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/contact-us`, body, this.httpOptions).pipe(
      retry(1),catchError(this.handleError)
    )
  }

}


           <app-swinger-navigation></app-swinger-navigation>
           <div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header bg-dark"><h6 class="mb-0">Profile Matches</h6></div>
            <div class="card-body over_flow_wrapper_big"  *ngIf="!freeuser">
             <div class="row">
               <div class="col-md-4" *ngFor="let swinger of swingers">
                <div class="card mb-2 bg-light" >
                  <div class="">
                    <div class="col-md-12">
                        <div class="user_profile_ls">
                          <img routerLink="/{{swinger.id}}/member/profile" src="{{swinger.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid">
                          <img src="../../../assets//images/verified.png" *ngIf="swinger.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top" title="Verified profile" >
                        </div>
                    </div>
                    <div class="col-md-12">
                      <div class="text-center mt-4">
                        <h5 class="card-title mb-21"> <a style="color: #484848;" routerLink="/{{swinger.id}}/member/profile">{{swinger?.name}} <i class="fas fa-heart" style="color: red;"></i></a></h5>
                        <span *ngIf="swinger?.gender != null"
                          [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
                          class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="text-md-center mt-4 mb-4" *ngIf="swinger.swingerMatched > 0; else notMatched;">
                       
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style]="swinger.with" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"><span >{{swinger.swingerMatched}}%</span></div>
                        </div>
                      </div>
                      
                    </div>
  
                    <ng-template #notMatched >
                      <div class="col-12 mt-4 mb-4" >
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" [style]="swinger.with" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"><span style="color: #484848;">{{swinger.swingerMatched}}%</span></div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
               </div>
             </div>
             <app-skoltan-loader *ngIf="loading"></app-skoltan-loader>
             <div class="row mt-4" *ngIf="!freeuser">
              <div class="col-md-12 text-center">
                <nav aria-label="..." class="text-center">
                  <ul class="pagination justify-content-center">
                    <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
                  </ul>
                </nav>
              </div>
            
            </div>
        </div>
            <!-- <div class="mt-4" *ngIf="freeuser">
              <app-subscribe-plan></app-subscribe-plan>
             </div> -->
          </div>
      

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_helpers';
import { EventsComponent } from './events/events.component';
import { EventWrapperComponent } from './event-wrapper/event-wrapper.component';
import { SwingerEventsComponent } from './swinger-events/swinger-events.component';

const routes: Routes = [{
  path: 'event-dates',
  component: EventWrapperComponent,
  canActivate: [AuthGuard],
  children: [
    { path: 'event-date-list', component: EventsComponent },
    { path: 'member-event-dates', component: SwingerEventsComponent },
  ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }

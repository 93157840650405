
           <app-friend-navigation></app-friend-navigation>

           <div class="card bg-light text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header text-left bg-dark"><h6 class="mb-0" style="color: #ffffff;">Friends</h6></div>
            <div class="card-body over_flow_wrapper_big"  *ngIf="!freeuser">
              <div class="row mb-2">
               <div class="col-md-4 mb-2"  *ngFor="let friend of firends">
                <app-list-friend *ngIf="friend.user_status == null"  [friend]='friend'></app-list-friend>
               </div>
              </div>
              <app-skoltan-loader *ngIf="loading"></app-skoltan-loader>
              <p *ngIf="dataNotFound" class="text-center" style="color: #484848;"> There is no friend.</p>
            </div>
            <div class="row mt-4"  *ngIf="!freeuser && pagelarg">
              <div class="col-md-12 text-center">
                <nav aria-label="..." class="text-center">
                  <ul class="pagination justify-content-center">
                    <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="mt-4" *ngIf="freeuser">
              <app-subscribe-plan></app-subscribe-plan>
             </div>
          </div>
      

<footer class="">
  <div class="container">
    <div class="row">
      <div class="col-md-6 ">
        <ul class="list-unstyled text-small" style="display: inline;">
          <li><a class="link-secondary" routerLink="/about-us" routerLinkActive="">About us</a></li>
          <li><a class="link-secondary" routerLink="/plan" routerLinkActive="">Pricing & Plans</a></li>
          <li><a class="link-secondary" routerLink="/contact" routerLinkActive="">Contact</a></li>
          <li><a class="link-secondary" routerLink="/term-conditoins" routerLinkActive="">Terms & Conditions</a></li>
          <li><a class="link-secondary" routerLink="/privacy-policy" routerLinkActive="">Privacy Policy</a></li>

        </ul>
      </div>
      <div class="col-md-6 text-right">
        <form [formGroup]="subForms" class="form-inline text-right" style="padding-top: 10px;float: right;">
          <div class="form-group mx-sm-6 mb-2">
            <input style="border-radius: 20px;" type="email" class="form-control" formControlName="email" placeholder="Enter email...">
          </div>
          &nbsp;<button type="submit" class="btn btn-warning mb-2" (click)="insertdata()">Subscribe</button>
        </form>
      </div>
    </div>
  </div>

</footer>
<div class="container">
  <div class="row">
    <div class="col-md-6">
      <div class="design-by">
        <p style="margin-bottom: 0px;
        margin-top: 5px;">© 2021 | ChatnHookUP | All Rights Reserved.</p>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <ul class="list-unstyled pt-2">
        <li><a class="link-secondary" href="https://www.facebook.com/groups/438146267788661" target="_blank"><i class="fab fa-facebook-square " style="color:#e22b59"></i></a></li>
        <!-- <li><a class="link-secondary" href="/" target="_blank"><i class="fab fa-instagram-square" style="color:#e22b59"></i></a></li> -->
       
      </ul>
    </div>
  </div>
</div>

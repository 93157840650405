<div class="padin" style="background-image:linear-gradient(rgba(129, 129, 129, 0.6), rgba(107, 106, 106, 0.6)), url('../../../../assets/images/defualt.jpg');  background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;">
    <div class="mbmsg" *ngIf="message?.senderId != userId">
        <div class="text-left recbgstext">
          <span >
            <p>
              {{message?.mesage}}
            </p>
            <p style="font-size: 12px;margin-right: 10px;text-align: right;"> {{message?.datandTime | date:'medium'}}</p>
          </span>
        </div>
        <!-- <div class="bgstextTimeres" >
          
        </div> -->
      </div><br>
      <!-- Current User Send message -->
      <div class=" postss" *ngIf="message?.senderId == userId">
        <div class="text-right sendbgstext">
          <span >
            <p style="text-align: left;">
              {{message.mesage}}
            </p>
            <p style="font-size: 12px;margin-right: 10px;color: #636363;text-transform: capitalize;"><span style="float: left;"
              ><i [ngClass]="{'checkcolore' : message?.readMessage == 'seen'}" style="font-size:10px;text-transform: capitalize;" class="fas fa-check-double"></i></span>{{message?.datandTime | date:'medium' }}</p>
          </span>
        </div>
        <!-- <div class="bgstextTime" >
          <p style="font-size: 12px;margin-right: 10px;color: #636363;"><span style="float: left;"
           ><i [ngClass]="{'checkcolore' : message?.seen_status == 1}" style="font-size:10px" class="fas fa-check-double"></i></span>{{message?.date_time | date:'medium' }}</p>
        </div> -->
      </div>
      <br>
</div>


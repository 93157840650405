<div class="card bg-light text-light mb-3 mt-2">
  <div class="card-header bg-dark color">
    <h5 style="color: white;"><span class="hoverPointer" (click)="openAlbumWrapper();">Albums</span> <span
        *ngIf="albumName" class="text-capitalize"> | {{albumName}}</span> </h5>
  </div>
  <div class="card-body">
    <div class="text-center" *ngIf="albumLoading">
      <div class="spinner-border text-light mt-4" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div class="row" id="wrapper">
      <ng-template [ngIf]="!openAlbumImages">
        <div class="col-md-3 " *ngFor="let album of albums; let i = index;">
        <div class="create-album-wrapper text-center card" (click)="openAlbum(album.id,i)">
          <div class="row album-wrapper">
            <div class="col-12">
              <img class="img-fluid" src="{{album.imagename[0]}}" onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
            </div>
            <div class="col-6 img_2">
              <img class="img-fluid" src="{{album.imagename[1]}}"  onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
            </div>
            <div class="col-6 img_3">
              <img class="img-fluid" src="{{album.imagename[2]}}"  onerror="this.src='../../../assets/images/onerroralbum.png';" alt="{{album.name}}">
                <span class="more_img" *ngIf="album?.Pluse">   
                  {{album?.newCount}} <i  class="fas fa-plus"  aria-hidden="true" *ngIf="album?.Pluse"></i>
            </span>
            </div>
          </div>

          <div class="bottom-text-wrapper" style="margin: 5px;">
            <p class="text-capitalize mb-0 text-center h6 testwrap">{{album.name}}</p>

          </div>
        </div>
      </div>
      </ng-template>


      <div *ngIf="albumStatus" class="text-center">
        <div class="spinner-border text-light mt-4" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>


      <div class="col-md-2 col-6 p-1" *ngFor="let image of albumImages; let i=index">
        <div class="top_wrapper">
          <div class='video_views_wrapper'>
            <span (click)="albumImageLike(image.id,i)" class="like"><i class="fas fa-thumbs-up"></i>
              {{image.likes}}</span>
            <span class="like"><i class="fas fa-eye"></i> {{image.views}}</span>
          </div>
        </div>
        <div class="image-wrapper text-center">
          <img src="{{image.s3Image}}" onerror="this.src='../../../assets/images/onerroralbum.png';" (click)="open(i)"
            class="img-fluid">
        </div>
      </div>
    </div>
    <app-album-skoltan *ngIf="albumLoading"></app-album-skoltan>
    <p *ngIf="dataNotFound  " class="text-center">There is no photo album available. </p>
    <div *ngIf="alImgcheckpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of alImgPagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="albumImageActivePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>


    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
</div>
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Subject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import { Popup } from "@environments/environment";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { AuthenticationService, CommonService, EventService } from '@app/_services';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  freeuser = false;
  currentUser:any;

  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  addEventdateForm: FormGroup;
  eventBackground = ['../../../assets/images/date_bg_1.jpg','../../../assets/images/date_bg_2.jpg',
'../../../assets/images/date_bg_3.jpg','../../../assets/images/date_bg_4.jpg','../../../assets/images/date_bg_5.jpg'
];
  setEventBackground: any;
  eventDateList: any;
  actionEventData: any;
  addressStatus = false;
  mapShow = false;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    events: [
    ],
    plugins: [ interactionPlugin ],
    eventClick: this.dateEventClick.bind(this)
  };
  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private eventDateService: EventService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;  
      // if(this.currentUser.plan_id == 1){
      //   this.freeuser = true;
      // }
  });
  }

  options: FlatpickrDefaultsInterface;
  addEventLoader = false;
  pagination:any;
	checkpages = false;
		

  ngOnInit(): void {
    this.addEventdateForm = this.fb.group({
      event_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      zip_code: [''],
      latitude: [''],
      longitude:[''],
      city: [''],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      sort_description: ['', [Validators.required]],
      long_description: ['', [Validators.required]],
      privacy: [''],
      country: [''],
      status: [''],
    });

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };

    this.getUserEventDates();
    this.checkdates();

  }

  checkdates(){
    this.addEventdateForm.valueChanges.subscribe(val => {
      if(val.end_date){
      if(val.start_date>val.end_date){
        Swal.fire(
          'Error',
         `${Popup.eventCheckDate}`,'error'
        );
        this.addEventdateForm.get('end_date').setValue('');
      }
    }
      
  });
}



  @ViewChild('addEventDateModalContent', { static: false }) addEventDateModalContent: TemplateRef<any>;
  @ViewChild('eventDetails', { static: false }) eventDetails: TemplateRef<any>;

  getlatitudeLongitude(mapData: any) {
    this.addEventdateForm.get('latitude').setValue(mapData.lat);
    this.addEventdateForm.get('longitude').setValue(mapData.lng);
    this.addEventdateForm.get('address').setValue(mapData.address);
      this.addEventdateForm.get('zip_code').setValue(mapData.postalCode);
}

  openAddNewEventDateModal(){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
  };
    this.modal.open(this.addEventDateModalContent, ngbModalOptions);
  }

  closeEventDateModal(){
    this.modal.dismissAll(this.addEventDateModalContent);
  }

  addNewEventDate(){
    this.addEventLoader = true;
    const data = this.addEventdateForm.value;
      this.eventDateService.addEventDates(data).subscribe(res => {
        this.addEventLoader = false;
        Swal.fire(
          'Success',
          `${res.message}`,'success'
        );
        this.addEventdateForm.reset();
        this.getUserEventDates();
        this.closeEventDateModal();
      }, error => {
        Swal.fire(
          'Error',
          `${Popup.error}`,'error'
        );
      });
  }



  getUserEventDates(){
    this.eventDateService.getUserEventDates().pipe(map(res =>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(eventData => {
        eventData.start = eventData.start_date;
        eventData.id = eventData.id;
        eventData.end = eventData.end_date;
        eventData.title = eventData.event_name;
        eventData.source = eventData;
        eventData.classNames = `cl_date_${eventData.id}`;
      })
     return res.data.data; 
    })).subscribe(eventDates => {
      console.log(eventDates)
     this.eventDateList = eventDates.reverse();
     this.calendarOptions.events = this.eventDateList;
    });
  }

  activePage(url:string){
    if(url){
      this.commonService.getPagination(url).pipe(map(res =>{
        this.pagination = res.data;
        this.checkpages = this.pagination.links.length >= 4?true: false;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        res.data.data.map(eventData => {
          eventData.start = eventData.start_date;
          eventData.id = eventData.id;
          eventData.end = eventData.end_date;
          eventData.title = eventData.event_name;
          eventData.source = eventData;
          eventData.classNames = `cl_date_${eventData.id}`;
        })
       return res.data.data; 
      })).subscribe(eventDates => {
        console.log(eventDates);
       this.eventDateList = eventDates.reverse();
       this.calendarOptions.events = this.eventDateList;
      });
    }
  }

  handleDateClick(arg) {
     console.log(arg);
   }

   dateEventClick(info) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
  };
    const eventid =  info.event.id;
    this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
    this.eventDateService.getEventDateById(eventid).subscribe(res => {
      this.actionEventData = res.data;
      // console.log(this.actionEventData);
      this.modal.open(this.eventDetails, ngbModalOptions);
    });
    info.el.style.borderColor = '#696666';
    info.el.style.backgroundColor = '#6b796e';
  }

}

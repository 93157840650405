
           <app-profile-navigation></app-profile-navigation>
           <div class="card  text-light mb-3 mt-2">
            <div class="card-header bg-dark"><h6 style="color: white;">About You</h6></div>
            <div class="card-body bg-light">
             <div class="row">
               <div class="col-md-12">
                    <form [formGroup]="userInfo" (ngSubmit)="onSubmit()"> 
                
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                        <label for="first_name">Profile Name</label>
                        <input type="text" formControlName="userID" class="form-control d-none" />
                        <input type="text" formControlName="first_name" class="form-control" readonly/>
                        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required">First Name is required</div>
                        </div> 
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                   <div class="form-group">
                    <label for="gender">Gender</label>
                    <select formControlName="gender" class="form-control">
                     <option value="{{gender.value}}" *ngFor="let gender of genderList">{{gender.title}}</option>
                    </select>
                    <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                        <div *ngIf="f.gender.errors.required">Gender is required</div>
                    </div> 
                </div>
                  </div>
                  </div>

                <div class="form-group">
                    <label for="sortTagline">Sort Tag line</label>
                    <input type="text" formControlName="sortTagline" class="form-control" />
                    <div  class="small-text">A small teaser text that shows up when people do a search or who's online. It should be at least 3-5 words.</div>
                     <div *ngIf="submitted && f.sortTagline.errors" class="invalid-feedback">
                        <div *ngIf="f.sortTagline.errors.required">Sort Tag Line is required</div>
                    </div>
                </div>

                  <div class="form-group">
                    <label for="lookingFor">What are you looking for</label>
                    <textarea type="text" formControlName="lookingFor" class="form-control" ></textarea>
                    <div  class="small-text">Please don't just answer sex, people will give up on you</div>
                    <div *ngIf="submitted && f.lookingFor.errors" class="invalid-feedback">
                        <div *ngIf="f.lookingFor.errors.required">What are you looking is required</div>
                    </div> 
                </div>

                <div class="form-group">
                  <label for="username">Describe Yourself</label>
                  <textarea type="text" formControlName="describe_yourself" class="form-control describe" ></textarea>
                  <div  class="small-text">Either physically or your personality, again, don't just type sexy</div>
                   <div *ngIf="submitted && f.describe_yourself.errors" class="invalid-feedback">
                      <div *ngIf="f.describe_yourself.errors.required">Describe Yourself is required</div>
                  </div>
              </div>

              <div class="form-group">
                <label for="username">Additional Information</label>
                <textarea type="text" formControlName="additional_info" class="form-control" ></textarea>
                <div  class="small-text">What else would you like to say, do, see, hear about or learn about.</div>
               <div *ngIf="submitted && f.additional_info.errors" class="invalid-feedback">
                    <div *ngIf="f.additional_info.errors.required">Additional Information is required</div>
                </div> 
            </div>

       <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i>  {{message}}</div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <button  [disabled]="loading" class="btn btn-success"><div 
        *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
         <span class="visually-hidden"></span>
       </div> Save</button> 
             </form> 
               </div>
             </div>
              
            </div>
          </div>
      


import { Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, SwingersService} from '@app/_services';
import { Title } from '@angular/platform-browser';
import { SwingerComponent } from "../swinger/swinger.component";

@Component({
  selector: 'app-swingers',
  templateUrl: './swingers.component.html',
  styleUrls: ['./swingers.component.css']
})
export class SwingersComponent implements OnInit {
  @ViewChild(SwingerComponent) child;
  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  pagination: any;
  currentUser:any;
  freeuser = false;

  constructor(private swingersService: SwingersService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private titleService:Title
    ){ 
      this.titleService.setTitle("Members | ChatnHookUP");
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        // if(this.currentUser.plan_id === 1){
        //   this.freeuser = true;
        // }
    });
    }
    
  swingers: any[] = [];



  ngOnInit(): void {
    this.getallSwingers(this.userId);
  }
  pagelarg = true;
  getallSwingers(id: any){
    this.swingersService.getSwingers(id).subscribe(data => {
      this.loading = false;
      this.swingers = data.data.data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const swinger in this.swingers){
      this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? this.swingers[swinger].is_pic: this.defaultProfile;
      }
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    }, error => {
      this.loading = false;
    });
  }


  getPageData(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      this.swingers = null;
      this.loading = false;
      this.swingers = data.data.data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const swinger in this.swingers){
      this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].is_pic}`: this.defaultProfile;
      }
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    }, error => {
      this.loading = false;
    });
         
  }
   
  }
  nodatainsearch = false;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name : filterValue
    }
    this.swingersService.searchswing(body).subscribe(res=>{
      this.nodatainsearch = false;
      this.pagelarg = true
      this.swingers = null;
      this.loading = false;
      this.swingers = res.data.data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      if(this.swingers.length == 0){
        this.nodatainsearch = true;
        this.pagelarg = false
      }
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const swinger in this.swingers){
      this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].is_pic}`: this.defaultProfile;
      }
    })
  }

  ngAfterViewInit() {
    // console.log(this.child)
    this.getallSwingers(this.userId);
  }
  
}

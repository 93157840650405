<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
               
                <li class="nav-item m-view">
                  <a class="btn btn-light " routerLink="/dashboard/members"  routerLinkActive="active"><i class="fas fa-users"></i> Members</a>
                </li>

                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/dashboard/who-viewed"  routerLinkActive="active"><i class="fas fa-eye"></i> Who Viewed Me</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/matches" routerLinkActive="active"><i class="fas fa-people-arrows"></i> Matches</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/members-hot-dates" routerLinkActive="active"><i class="fas fa-fire"></i> Members Hot Dates</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/members-events" routerLinkActive="active"><i class="fas fa-calendar-day"></i> Members Events</a>
                  </li>
                 
                </ul>
          </div>
      </div>
  </div>
  </div>
  
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '@app/_services';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {


  constructor(
    private fb: FormBuilder, 
    private userService: UserService,
    private title:Title) {
      this.title.setTitle('Change Password | ChatnHookUP')
     }

  userChangePass: FormGroup;
  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = false;
  ifSuccess = false;
  message: string;
  error = '';
  hide = true;
  hides = true;

  ngOnInit(): void {
    this.getuserChangePass();
    this.userChangePass = this.fb.group({
      userID: [this.userId],
      name: [''],
      email: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', Validators.required],
    },{ validators: this.checkPasswords });
  }
  notSame = false;
  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirm')?.value;
    console.log(pass, confirmPass.length)
    if(confirmPass.length != 0){
      if(pass !== confirmPass){
        this.notSame = true;
      }else{
        this.notSame = false;
      }
    }
    return pass === confirmPass ? null : { notSame: true }
  }
  
  get f() { return this.userChangePass.controls; }

  onSubmit(){
   this.submitted = true;
   if (this.userChangePass.invalid) {
    return;
   }
   this.loading = true;
   this.userService.userChangePass(this.userChangePass.value).subscribe( res => {
    this.submitted = false;
    this.loading = false;
    this.ifSuccess = true;
    this.message = res.message;
    this.getuserChangePass();
   }, error => {
    this.error = error;
    this.loading = false;
   });
  }

  getuserChangePass(){
    this.userService.getuser().subscribe( res => {
      this.userChangePass.controls.name.setValue(res.name);
      this.userChangePass.controls.email.setValue(res.email);
    }, error => {
      console.log(error);
    });
   }


}

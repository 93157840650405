<div class="firend-navigation mt-2 mb-2">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/{{swingersIDs}}/member/events"  routerLinkActive="active"><i class="fa fa-calendar"></i> Events of {{UserName}}</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/{{swingersIDs}}/member/members-events" routerLinkActive="active"><i class="fa fa-calendar"></i> Members Events</a>
                  </li>
                </ul>
          </div>
      </div>
  </div>
</div>

<div *ngIf="!freeuser" class="row">
  <div class=" col-md-7  text-light mb-3  mb-bg-tp">
      <div class="card-header bg-dark d-flex justify-content-between"><h5>Members Events | <span class="badge badge-warning text-dark" >Total Events: {{totalHotDates}}</span></h5><button class="btn btn-warning btn-sm " style="font-size:14px" (click)="openFilterWrapper();"><i class="fas fa-search"></i> Event dates</button></div>
      <div class="swinger-search-date-wrapper" *ngIf="filterStatus">
        <!-- <p class="mt-2"><i class="fas fa-search"></i> Search event dates</p> -->
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="addEventDateForm" (ngSubmit)="filterEventDates()">
              <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
                <!-- <input type="text" class="col-md-3" formControlName="zip_code" id="zipcode" placeholder="Zip code"> -->
                <select  class="col-md-4" formControlName="radues" id="city">
                  <option value="" disabled>-- Distance --</option>
                  <option value="5">5 mi</option>
                  <option value="10">10 mi</option>
                  <option value="15">15 mi</option>
                  <option value="20">20 mi</option>
                  <option value="25">25 mi</option>
                  <option value="35">35 mi</option>
                  <option value="50">50 mi</option>
                  <option value="100">100 mi</option>
                  <option value="200">200 mi</option>
                  <option value="400">400 mi</option>
                  <option value="500">500 mi</option>
                  </select>
                <input
                class="col-md-4"
                type="text"
                mwlFlatpickr
                formControlName="start_date"
                [enableTime]="false"
                dateFormat="Y-m-d"
                placeholder="Start Date"
                [options]="options"
              />
    
              <input
              class="col-md-4"
              type="text"
              formControlName="end_date"
              mwlFlatpickr
              [enableTime]="false"
              dateFormat="Y-m-d"
              placeholder="End Date"
              [options]="options"
            />
              
          <div class="mb-4">
          <p class="text-right"><button type="submit" class="btn btn-warning btn-sm mt-2" ><i class="fas fa-search"></i> Search</button></p>
          </div>
          </form>
      </div>
    
      </div>
      </div>
      <div class="card-body  over_flow_wrapper_big p-0">
  <!-- end hot date filter -->
      <div *ngIf="searchData">
        <p class="text-center" style="color: #484848;">Friends Not Found.</p>
      </div> 
        <div *ngIf="loading" class="card-text text-center">
          <div class="spinner-border text-light" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div> 
  
       <div class="row padd bg-sky">
        <div class=" mb-2 mt-2 col-md-6 text-center" *ngFor="let hotdate of hotdateList">
          <div class="card" style="text-align: center !important;">
              <div class="col-12 col-md-12">
                  <div class="user_profile_ls">
                    <img routerLink="/{{hotdate?.user_id}}/member/profile" onerror="this.src='../../../assets/images/avtar-image.jpg';" [src]="(hotdate.image)?(hotdate.image):defaultProfile" alt="user-profile" class="img-fluid">
                    <img src="../../../assets//images/verified.png"*ngIf="hotdate.plan_id != 1" class="verifyImage" data-toggle="tooltip" data-placement="top"
                  title="Paid Members">
                  </div> 
              </div>
              <div class="col-12 col-md-12">
                    <div class="card-body text-center">
                      <h5 class="card-title text-center mb-1" style="color: #484848;"><a  routerLink="/{{hotdate?.user_id}}/member/profile" style="color: #484848;">{{hotdate?.username}}</a></h5>
                      <p class="card-text text-center mb-0"><span *ngIf="hotdate?.gender != null" [ngClass]="hotdate?.gender=='f'?'badge-success':''||hotdate?.gender=='m'?'badge-info':''||hotdate?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">
                        <span *ngIf="hotdate?.gender == 'm'">Male</span> 
                        <span *ngIf="hotdate?.gender == 'f'">female</span>
                        <span *ngIf="hotdate?.gender == 'c'">Couple</span>
                      </span></p>
                      <small style="color: #484848;">{{hotdate?.event_name}}</small>
                      <p class="card-text  mb-1" style="color: #484848;">From: <span class="badge bg-success text-light"> {{hotdate?.start_date}}</span> to: <span class="badge bg-danger text-light"> {{hotdate?.end_date}}</span></p>
                      <p *ngIf="hotdate?.sw_distance" class="mb-0" style="color: #484848;">Distance: <span class="badge bg-info text-light">{{hotdate?.sw_distance}}</span></p>
                      <div class="text-center _mt_btn_36">
                        <button class="btn btn-warning btn-sm event-bt-btn" (click)="dateEventClick(hotdate?.username,hotdate?.id)">Get Details</button>
                      </div>
                    </div>
                  </div>
          
            </div>
        </div>
       </div>
  
        
  
        <ng-template #eventDetails let-close="close">
          <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
            <button type="button" class="close" (click)="close()" style="outline: none;">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="d-flex justify-content-center mb-1 z_2 p-2">
              <h4  class="text-light"><i>{{eventData.event_name}}</i></h4>
            </div>
            <div class="d-flex justify-content-between mt-2 z_2 p-2">
              <span  class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light"> {{eventData.start_date}}</span></span>
              <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light"> {{eventData.end_date}}</span></span>
              
  
            </div>
            
          </div>
          <div class="modal-body text-light">
            <p class=" text-light mb-2"><i>Address:</i> {{eventData.address}}</p>
            <p class="text-light"><i>Sort Description:</i> {{eventData.sort_description}}</p>
            <p class=" text-light"> {{eventData.long_description}}</p>
          </div>
        </ng-template>
  
      
      </div>
      <div *ngIf="checkpages" class="row mt-4">
        <div class="col-md-12 text-center">
          <nav aria-label="..." class="text-center">
            <ul class="pagination justify-content-center">
              <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  
    <div class="col-md-5">
      <full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
    </div>
  </div>
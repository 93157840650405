<section class=""
  style="background:#e22b59 url('/assets/images/banner-top.jpg'); background-size: cover; background-position: top center;">
  <div class="container">
    <div class="row ">
      <div class="col-lg-7 col-md-6 col-sm-12">
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12">
        <div class="" style="margin-top: 8em;margin-bottom: 8em; " *ngIf="showUnverified">
          <div class="pdin">
            <div *ngIf="emailVerify">
              <h4 class="text-center text-white mb-0">Please verify your email.</h4>
              <div class="text-center mt-2">
                <h4 class="text-center text-white " style="font-size: 17px;">{{emailAddress}}</h4>
              </div>
            </div><br>

            <div *ngIf="emailVerify">
              <p class="text-center text-white mb-0">If you did not receive email verification link? <br>
                <span><a class="links text-white" (click)="sentagainlink()">Click here to resend.</a></span>
              </p>
            </div><br>
          </div>
        </div>

        <div class="" style="margin-top: 8em;margin-bottom: 8em; " *ngIf="showUnverified && emailStatuss">
          <div class="pdin">
            <div *ngIf="showpaymentPopup" style="padding: 10px;">
              <!-- <h4 *ngIf="planids" class="text-center text-white mb-0">Please make your pending pyment for this Plan
                <span style="color: green;">
                  {{PlaneName}}
                </span></h4> -->
              <h4 *ngIf="freeprofileuser" class="text-center text-white mb-0">
                Your free plan  has been expired. Please upgrade your plan to access your profile.</h4>

              <h4 *ngIf="plansubcancel" class="text-center text-white mb-0">Your subscription had been cancelled.
                If you wish to continue with paid membership. Then, you need to upgrade your plan.
              </h4>
              <div class="text-center mt-2" *ngIf="plansubcancel">
                <button class="btn btn-warning" (click)="gotopayment()">Make Payment</button>
              </div>

              <div class="text-center mt-2" *ngIf="freeprofileuser">
                <button class="btn btn-warning" (click)="gotopayment()">Upgrade Plan</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="defaultNavigation && emailStatus && planstatus " id="user-banner"
  style="background-image: url({{coverImgURL}}); min-height: 180px;">
  <div *ngIf="coverLoader" class="spinner-border text-light spinner-border-sm cover_picture" role="status">
    <span class="visually-hidden"></span>
  </div>
  <div class="container mt-4">
    <div class="row pt-2">
      <div class="col-md-4">
        <button class="btn btn-edit-cover m-view-icon" (click)="coverPage.click()"><i class="fa fa-camera"
            aria-hidden="true"></i> Edit Cover</button>
        <div class="profile_wrapper">
          <div class="user_profile">
            <input #porile type="file" class="d-none" accept='image/*'
              (change)="changeProfile(porile.files, profileId)" />
            <div *ngIf="profileloading" class="spinner-border text-light spinner-border-sm pro_loader" role="status">
              <span class="visually-hidden"></span>
            </div>
            
            <img src="{{defaultProfile}}" (click)="opens(defaultProfile)" class="img-fluid profile-image">
            <img src="../../../assets//images/verified.png" *ngIf="planId != '1'" class="verifyImage" data-toggle="tooltip" data-placement="top"
            title="Verified profile">
            <i class="fa fa-camera cam" aria-hidden="true" (click)="porile.click()"></i>
          </div>
          <div class="m-view-icon">
            <i class="fas fa-camera" (click)="porile.click()"></i>
          </div>
          <div class="user_info_data" >
            <h5 class="mb-1 text-uppercase">{{username}}</h5>
            <span ><a class="badge bg-warning text-dark custom_badge mob mr-1" routerLink="/dashboard/plan-invoce">{{PlaneName}}</a></span>
            <span *ngIf="planId == '1'"><a routerLink="/plan" class="mob badge bg-success text-dark custom_badge ">Upgrade Plan</a></span>
            <!-- <div class="text-left mt-2 mob" *ngIf="!freeplan">
              <button class="btn btn-warning" (click)="upgradp()">Upgrade Plan</button>
            </div> -->
            <!-- <p>Profile</p> -->
          </div>
        </div>
      </div>
      <div class="col-md-8" style="margin-top: 4em;">
        <div class="col-md-12 text-right postss">
          <input #coverPage type="file" class="d-none" accept='image/*'
            (change)="changeCoverPage(coverPage.files, dataId)" />
          <button class="btn btn-edit-cover" (click)="coverPage.click()"><i class="fa fa-camera" aria-hidden="true"></i>
            <span style="font-size: 9px;text-transform: capitalize;"> Edit Cover</span></button>
        </div>
        <app-user-menu *ngIf="defaultNavigation"></app-user-menu>
      </div>
    </div>
  </div>
</section>

<ng-template #images let-close="close">
  <div>
    <button type="button" style="outline: none; color: white;    position: absolute;
    right: 7px;
    opacity: 1;" class="close" (click)="closesignin()">
      <span aria-hidden="true">&times;</span>
  </button>
    <img src="{{defaultProfile}}" class="img-fluid" style="width: 100%;height: auto;object-fit: cover;border-radius: 20px;border:solid #ffc107 5px;">
  </div>
</ng-template>





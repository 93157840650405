
           <app-profile-navigation></app-profile-navigation>
           <div class="card  text-light mb-3 mt-2">
            <div class="card-header bg-dark"><h6 style="color: white;">Preferences</h6></div>
            <div class="card-body bg-light">
             <div class="row">
               <div class="col-md-12">
                  <form [formGroup]="userPreference" (ngSubmit)="onSubmit()"> 
                 <h6>What Sort of Pleasures are You Seeking?</h6> 
                 <hr>
                 <!-- pleasures -->
                 <div class="row mb-2">
                     <div class="col-md-6">
                        <label for="watch">Watch:</label>
                        <select formControlName="watch" class="form-control ">
                            <option value="{{pleasure.value}}" *ngFor="let pleasure of pleasures">{{pleasure.title}}</option>
                        </select>
                       <p class="small-text"><strong>Watch:</strong> Are you interested in tame pleasures such as meeting others at a nude beach, or same room sex, but with your own partner? Things such as these? then check to Watch checkbox.</p>
                       </div>
                       <div class="col-md-6">
                        <label for="soft">Soft:</label>
                        <select formControlName="soft" class="form-control ">
                            <option value="{{pleasure.value}}" *ngFor="let pleasure of pleasures">{{pleasure.title}}</option>
                        </select>
                       <p class="small-text"><strong>Soft:</strong> Are you interested in Moderate pleasures such as touching and oral play with others, and anything else short of intercourse with someone other than your partner? then check to Soft checkbox.</p>
                   
                     </div>
                     <div class="col-md-6">
                        <label for="f_drink">Full:</label>
                        <select formControlName="full" class="form-control ">
                            <option value="{{pleasure.value}}" *ngFor="let pleasure of pleasures">{{pleasure.title}}</option>
                        </select>
                       
                       <p class="small-text"><strong>Full:</strong> Are you interested in Wild pleasures such as having sex with someone other than your partner? then check to Soft checkbox.</p>  
                     </div>
                 </div>
                 <div class="row">
                    <input type="text" formControlName="userID" class="form-control d-none" />
                    <!-- <div class="col-md-4" *ngFor="let interest of intrestData">
                        <div class="form-group" *ngIf='interest.checked; else uch'>
                            <label class="d-block" for="watch"><input type="checkbox"  (change)="onChange(interest.id, $event.target.checked)" checked/> {{interest.name}}</label>
                        </div>
                        <ng-template #uch>
                            <div class="form-group">
                                <label class="d-block" for="watch"><input type="checkbox"  (change)="onChange(interest.id, $event.target.checked)" /> {{interest.name}}</label>
                            </div>
                        </ng-template>
                    </div> -->
                 </div>  
             
                 <hr>
                 <h6>Tell Us a Little About You.</h6>
                 <hr>
                 <p *ngIf="gender === 'f' || gender === 'c'">Female Info:</p>
                 <div class="row" *ngIf="gender === 'f' || gender === 'c'">
                    <div class="col-md-2 col-sm-6 pr-0">
                        <label for="height">Height <span class="small-text">(feet)</span>:</label>
                            <select formControlName="f_height_feet" class="form-control">
                                <option disabled >Choose height</option>
                                <option *ngFor="let feet of heightFeets" value="{{feet}}">{{feet}}</option>
                            </select> 
                        </div>

                        <div class="col-md-2 col-sm-6 pl-0">
                            <label for="height">Height <span class="small-text">(in)</span>:</label>
                                <select formControlName="f_height_in" class="form-control">
                                    <option disabled >Choose height</option>
                                    <option *ngFor="let inch of heightInInchs" value="{{inch}}">{{inch}}</option>
                                </select> 
                            </div>
                   
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="f_weight">Weight <span class="small-text">(Kg)</span>:</label>
                            <input type="text" formControlName="f_weight" class="form-control" />
                             
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="username">Age <span class="small-text">(Yrs)</span>:</label>
                            <select formControlName="f_age" class="form-control">
                            <option *ngFor="let age of ageData" value="{{age}}">{{age}}</option>
                            </select> 
                        </div>
                    </div>
                 
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="f_smoke">Smoke:</label>
                            <select formControlName="f_smoke" class="form-control">
                                <option value="1" Selected>Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="f_drink">Drink:</label>
                            <select formControlName="f_drink" class="form-control">
                            <option value="1" Selected>Yes</option>
                            <option value="2">No</option>
                            </select>
                           
                          
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="f_orientation">Orientation:</label>
                            <select formControlName="f_orientation" class="form-control">
                             <option *ngFor="let orientation of orientations" value="{{orientation}}">{{orientation}}</option>
                            </select>
                           
                        </div>
                    </div>
                 </div> 
            

                 <p *ngIf="gender === 'm' || gender === 'c'">Male Info:</p>
                 
                 <div class="row" *ngIf="gender === 'm' || gender === 'c'">
                    <div class="col-md-2 pr-0">
                        <label for="username">Height <span class="small-text">(feet)</span>:</label>
                            <select formControlName="m_height_feet" class="form-control">
                                <option disabled >Choose height</option>
                                <option *ngFor="let feet of heightFeets" value="{{feet}}">{{feet}}</option>
                            </select> 
                        </div>

                        <div class="col-md-2 pl-0">
                            <label for="username">Height <span class="small-text">(in)</span>:</label>
                                <select formControlName="m_height_in" class="form-control">
                                    <option disabled >Choose height</option>
                                    <option *ngFor="let inch of heightInInchs" value="{{inch}}">{{inch}}</option>
                                </select> 
                            </div>


                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="m_weight">Weight <span class="small-text">(Kg)</span>:</label>
                             <input type="text" formControlName="m_weight" class="form-control" />
                           
                            
                            <!-- <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="m_age">Age:</label>
                            <select formControlName="m_age" class="form-control">
                                <option *ngFor="let age of ageData" value="{{age}}">{{age}}</option>
                            </select>
                        </div>
                    </div>
                 
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="m_smoke">Smoke:</label>
                            <select formControlName="m_smoke" class="form-control">
                            <option value="1" Selected>Yes</option>
                            <option value="2">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="m_drink">Drink:</label>
                            <select formControlName="m_drink" class="form-control">
                            <option value="1" Selected>Yes</option>
                            <option value="2">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="m_orientation">Orientation:</label>
                            <select formControlName="m_orientation" class="form-control">
                            <option *ngFor="let orientation of orientations" value="{{orientation}}">{{orientation}}</option>
                            </select>
                        </div>
                    </div>
                 </div> 
                 <hr> 
                 <h6>Who are you looking for?</h6>
                 <hr>
                 <div class="row">
                     <div class="col-md-12">
                        <p>By choosing "No Interest", the profile is still viewable. However, choosing "Block Them", the profile is no longer visible.</p>
                     </div>
                     <div class="col-md-6 mb-2">
                        <label class="d-block" for="like">Are you interested in meeting Couples?</label>
                        <select formControlName="lookingForCouple" class="form-control ">
                            <option value="{{looking.value}}" *ngFor="let looking of lookingForList">{{looking.title}}</option>
                        </select>
                     </div>
                     <div class="col-md-6 mb-2">
                        <label class="d-block" for="like">Are you interested in meeting Single Ladies?</label>
                        <select formControlName="lookingForFemale" class="form-control ">
                            <option value="{{looking.value}}" *ngFor="let looking of lookingForList">{{looking.title}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label class="d-block" for="like">Are you interested in meeting Single Men?</label>
                      <select formControlName="lookingForMale" class="form-control ">
                        <option value="{{looking.value}}" *ngFor="let looking of lookingForList">{{looking.title}}</option>
                      </select>
                    </div>
                   </div>
            <hr>
            <h6>Which Ages turn you on?</h6>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="youngest_interested_in">Youngest You Are Interested In</label>
                         <select formControlName="youngest_interested_in" class="form-control">
                            <option *ngFor="let age of ageData" value="{{age}}">{{age}}</option>
                         </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="oldest_interested_in">Oldest You Are Interested In</label>
                         <select formControlName="oldest_interested_in" class="form-control">
                            <option *ngFor="let age of ageData" value="{{age}}">{{age}}</option>
                         </select>
                    </div>
                </div>
            </div>

            <hr>
            <h6>Would you like to meet people that Smoke or Drink?</h6>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="drink">Drink</label>
                        <select formControlName="drink" class="form-control">
                            <option value="{{item.value}}" *ngFor="let item of smokeDrink">{{item.title}}</option>
                         </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="smoke">Smoke I do not care</label>
                         <select formControlName="smoke" class="form-control">
                            <option value="{{item.value}}" *ngFor="let item of smokeDrink">{{item.title}}</option>
                         </select>
                    </div>
                </div>
            </div>

            <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i>{{message}}</div>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <button  class="btn btn-success"> Update</button> 
           </form>
               </div>
             </div>  
            </div>
          </div>
          
import { AfterViewInit, Component, OnInit, DoCheck} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { Location } from '@angular/common';
import { MessagesService } from "../../_services/messages.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, DoCheck{
  public href: string = "";
    url: string = "asdf";
    userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(private router: Router,
    private MessagesService:MessagesService,
    private authenticationService: AuthenticationService) { }
  defaultNavigation = true;
  emailStatus= true;
  planStatus=true;
  planInvoce:boolean = true;
  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    if(currentUrl === '/dashboard'){
     this.router.navigate(['/dashboard/members']);
    }

    this.authenticationService.currentUser.subscribe((check:any) => {
      if (check.email_status != 1) {
        this.emailStatus = false;
      }
      // if (check.plan_id == 1 && check.remaining_dayes == 0) {
      //   this.planStatus = false;
      // }
    });
    let body = {
      ststusUser:'online'
    }
    this.MessagesService.userStatus(this.userId, body).then((res)=>{
    }).catch((err)=>{
      console.log(err)
    })
  }


cols = 'col-lg-8 col-md-12 col-sm-12';
messagesnav = true;
  ngDoCheck(){
    const currentUrl: string = this.router.url;
    let userId = currentUrl.split('/')[3];
    if(userId === `profile`) {
      this.defaultNavigation = false;
      }else{
        this.defaultNavigation = true;
      }

      this.href = this.router.url;
      if( this.router.url.substring(11) === 'plan-invoce' ||this.router.url.substring(11) == "user-online" ||  this.router.url.substring(11,18) == "message"){
        this.cols = 'col-lg-12 col-md-12 col-sm-12'
        this.planInvoce = false
      }else{
        this.messagesnav = false
        this.planInvoce = true;
        this.cols = 'col-lg-8 col-md-12 col-sm-12'
      }
  }


}

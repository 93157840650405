<app-dashboard-header></app-dashboard-header>
<div *ngIf="defaultNavigation" >
<app-user-navigation></app-user-navigation>
</div>

<div class="defualt_bg"  *ngIf="emailStatus && planStatus">
    <div *ngIf="defaultNavigation; else fullView" class="container">
       <div class="row">
        <div *ngIf="defaultNavigation" class="col-md-4 m-screen">
          <app-right-navigation-panel *ngIf="planInvoce"></app-right-navigation-panel>
         </div>
         
           <div [class]="cols">
            <ngx-loading-bar [includeSpinner]="false"color="#3f75ff" height="5px"></ngx-loading-bar>
            <router-outlet></router-outlet>
           </div>
           

          
       </div>
    </div>

    <ng-template #fullView>
        <div class="container-fluid">
            <div class="row">
           <div class="col-md-12">
            <ngx-loading-bar [includeSpinner]="false"color="#3f75ff" height="5px"></ngx-loading-bar>
           <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-template>
</div>


<!-- <div class="mt-4" *ngIf="planStatus">
  <app-subscribe-plan></app-subscribe-plan>
</div> -->

import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-top-banner-wrapper',
  templateUrl: './top-banner-wrapper.component.html',
  styleUrls: ['./top-banner-wrapper.component.css']
})
export class TopBannerWrapperComponent implements OnInit {

  constructor() { }
@Input('bannerImage')  bannerImage: string;

  ngOnInit(): void {
    console.log(this.bannerImage);
  }

}
// CommonFuctionService
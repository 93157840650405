<div class="right_navigation_panel">
  <div class="card bg-dark text-light mb-3">
    <div class="card-header">
      <h6 class="fw-bold text-left mb-0">Online Members <a (click)="gotoOnline()" style="float: right; font-size: 14px;margin-top: 5px;"><i class="fas fa-eye"></i> view all </a></h6>
    </div>
    <div class="card-body bg-light over_flow_wrapper">
      <div class="card mb-2 bg-dark p-1" *ngFor="let friend of friendsData | slice:0:5; let i=index">
        <div class="row g-0" *ngIf='friend.user_status == null'>
          <div class="col-3 pr-1">

            <div class="user_profile_ls active mb-2">
              <img [src]="friend?.profile" routerLink="/{{friend?.id}}/member/profile" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                alt="user-profile" class="img-fluid">
                <img src="../../../assets//images/verified.png" *ngIf="friend.plan_id != 1"class="verifyImage" data-toggle="tooltip" data-placement="top"
                title="Verified profile">
            </div>
            
          </div>
          <div class="col-7 pl-1">
            <div class=""style="margin-top: .3em;">
              <h5 class="card-title" style="margin-top: 12px;"><a routerLink="/{{friend?.id}}/member/profile">{{friend.name}} <i class="fas fa-circle" style="color: #0fa013; font-size: 12px;"></i></a></h5>
             
              <span
                [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
                class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
            </div>
          </div>
          <div class="col-2 pl-1">
            <div class="cutom">
              <a  style="color: #efea59;" data-toggle="tooltip" data-placement="top"
                title="Send Message" (click)="openAddNewHotdateModal(friend.id,friend.name, friend?.gender, friend?.profile,friend?.user_status)"><i class="fa fa-comments"></i></a>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="dataNotFound  " class="text-center" style="color: #484848;">There is no members online.</p>
    
      <div class="card-text text-center" *ngIf="loading">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>


    </div>
  </div>



</div>





<ng-template #SentMessageModalContent let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="message" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>
          <div class="form-group">
            <textarea class="form-control" formControlName="messages" placeholder="message...."
              id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
            <button [disabled]="loadings" type="submit" class="btn btn-primary">
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>